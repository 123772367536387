
import { Component, Prop, Vue } from 'vue-property-decorator'
import CUCurrency from '@/components/CUCurrency.vue'
import TripPricingCalculationsSidebar from '@/components/TripPricingCalculationsSidebar.vue'
import sidebar from '@/store/modules/sidebar'
import { TripPricings } from '@/models/TripPricings'
import tstate from '@/store/modules/types'
import { Type } from '@/models/dto/Type'
import { TripPricingSelection } from '@/models/dto'
import {
  isPricingSelectorHighest,
  pricingSelectionToRateTypes,
  rateTypes,
  rateTypesToPricingSelectionKey,
} from '@/utils/pricing'
import quote from '@/store/modules/quote'

@Component({
  components: { CUCurrency },
})
export default class TripPricingBaseFarePicker extends Vue {
  @Prop({ required: true }) readonly tripId!: number
  @Prop({ required: true }) readonly tripPricings!: TripPricings
  @Prop({ type: Boolean, default: false }) readonly readOnly!: boolean
  @Prop({ type: Number, required: false, default: 1 })
  readonly pricingSelectionId!: number

  state = quote

  get pricingSelectionType(): Type {
    const pricingSelectionId = this.pricingSelectionId || 1
    return this.pricingSelectionTypes.find(
      ({ id }) => id === pricingSelectionId
    )
  }

  pricingSelectionTypes = tstate.pricingSelectionTypes
  rateTypes = rateTypes

  get selectionTypeLabel(): string {
    return this.isHighestSelected ? 'Highest' : 'Choose'
  }

  get isHighestSelected(): boolean {
    return isPricingSelectorHighest(this.pricingSelectionType)
  }

  get isChooseSelected(): boolean {
    return !this.isHighestSelected
  }

  get selectionTypeLabels(): string[] {
    return ['Highest', 'Choose']
  }

  get currentRateTypes(): Type[] {
    return pricingSelectionToRateTypes(this.pricingSelectionType)
  }

  handleSelectionChange(selectionType: TripPricingSelection): void {
    const highestType = this.pricingSelectionTypes.find(
      ({ key }) => key === 'highest_daily'
    )
    const chooseType = this.pricingSelectionTypes.find(
      ({ key }) => key === 'choose_override'
    )
    let baseFare = 0
    if (selectionType === 'Highest') {
      baseFare = this.highestPrice
      this.$emit('change', { baseFare, pricingSelectionId: highestType.id })
    } else {
      baseFare = this.chosenPrice
      this.$emit('change', { baseFare, pricingSelectionId: chooseType.id })
    }
  }

  handleRateToggled(type: Type): void {
    if (!this.isChooseSelected) {
      return
    }

    let currentRateTypes = [...this.currentRateTypes]
    const rateExists = !!currentRateTypes.find(({ key }) => key === type.key)
    if (rateExists) {
      currentRateTypes = currentRateTypes.filter(({ key }) => key !== type.key)
    } else {
      currentRateTypes = [...currentRateTypes, type]
    }
    const baseFare = this.getPriceForRateTypes(currentRateTypes)
    const pricingSelectionKey = rateTypesToPricingSelectionKey(currentRateTypes)
    const { id: pricingSelectionId } = this.pricingSelectionTypes.find(
      ({ key }) => key === pricingSelectionKey
    )

    this.$emit('change', { baseFare, pricingSelectionId })
  }

  handleOpenPricingCalculationSidebar(): void {
    sidebar.push({
      title: 'Pricing Calculation',
      component: TripPricingCalculationsSidebar,
      props: {
        tripPricings: this.tripPricings,
      },
      width: 1088,
      wide: true,
    })
  }

  isRateTypeSelected(type: Type): boolean {
    const types = pricingSelectionToRateTypes(this.pricingSelectionType)
    return !!types.find(({ key }) => key === type.key)
  }

  get allRates(): Record<string, number> {
    return {
      daily: this.dailyRate,
      hourly: this.hourlyRate,
      mileage: this.mileageRate,
    }
  }

  get dailyRate(): number {
    return this.tripPricings.pricingDaily?.baseAmount || 0
  }

  get hourlyRate(): number {
    return this.tripPricings.pricingHourly?.baseAmount || 0
  }

  get mileageRate(): number {
    return this.tripPricings.pricingMileage?.baseAmount || 0
  }

  get highestPrice(): number {
    return Math.max(this.dailyRate, this.hourlyRate, this.mileageRate)
  }

  get chosenPrice(): number {
    return this.getPriceForRateTypes(this.currentRateTypes)
  }

  getPriceForRateTypes(rateTypes: Type[]): number {
    let sum = 0
    const rateTypeKeys = rateTypes.map(({ key }) => key)
    if (rateTypeKeys.includes('daily')) {
      sum += this.dailyRate
    }
    if (rateTypeKeys.includes('hourly')) {
      sum += this.hourlyRate
    }
    if (rateTypeKeys.includes('mileage')) {
      sum += this.mileageRate
    }
    return sum
  }

  get baseFare(): number {
    if (this.isHighestSelected) {
      return this.highestPrice
    } else if (this.isChooseSelected) {
      return this.chosenPrice
    }
    return 0
  }
}
