import { apiBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import { TagResult, TagsResult, CreateTagRequest } from '@/models/dto/Tag'

const httpService: HttpService = new HttpService()

export default {
  byQuoteId(id: number): Promise<AxiosResponse<TagsResult>> {
    const host = apiBaseUrl()
    const url = `https://${host}/tags/quote/${id}`
    return httpService.get(url)
  },
  createTag(payload: CreateTagRequest): Promise<AxiosResponse<TagResult>> {
    const host = apiBaseUrl()
    const url = `https://${host}/tags/create`
    return httpService.post(url, payload)
  },
  addQuoteTags(
    tagIds: number[],
    quoteId: number
  ): Promise<AxiosResponse<void>> {
    const host = apiBaseUrl()
    const payload = { entityIds: [quoteId], tagIds }
    const url = `https://${host}/tags/quote`
    return httpService.post(url, payload)
  },
  removeQuoteTag(tagId: number, quoteId: number): Promise<AxiosResponse<void>> {
    const host = apiBaseUrl()
    const payload = { entityIds: [quoteId], tagIds: [tagId] }
    const url = `https://${host}/tags/quote`
    return httpService.delete(url, { data: payload })
  },
}
