
import { Component, Vue } from 'vue-property-decorator'
import CUSimpleTable from '@/components/CUSimpleTable.vue'
import PaymentDetails from '@/components/PaymentDetails.vue'
import SimpleTableAffiliateNameCell from '@/components/SimpleTableAffiliateNameCell.vue'
import {
  AffiliateOffer,
  AffiliateOfferWithContactInfo,
} from '@/models/dto/Affiliate'
import ReservationState from '@/store/modules/reservation'
import { SimpleTableColumn } from '@/models/SimpleTableColumn'
import { currencyFilter, phoneFormatFilter } from '@/utils/string'
import SimpleTableAffiliateActionCell from './SimpleTableAffiliateActionCell.vue'
import affiliateService from '@/services/affiliate'

@Component({
  components: {
    CUSimpleTable,
    PaymentDetails,
  },
})
export default class ReservationFarmout extends Vue {
  state = ReservationState

  formatVehicleInfo(row: AffiliateOffer): string {
    return (
      row.vehicleDetails
        ?.map((v) => `${v.vehicleType} x ${v.vehicleCount}`)
        .join(', ') || 'No details'
    )
  }

  formatPassengerCount(row: AffiliateOffer): string {
    if (row.passengerCount == null) {
      return this.state.reservation.passengerCount.toString()
    }
    return row.passengerCount.toString()
  }

  async onToggleActive(offer: AffiliateOffer): Promise<void> {
    const payload = {
      isActive: !offer.isActive,
      affiliateOfferId: offer.id,
    }

    try {
      await affiliateService.toggleIsActive(payload)
      this.state.toggleAffiliateOffer(payload)
    } catch (error) {
      console.error(error)
    }
  }

  get affiliateOffers(): Partial<AffiliateOfferWithContactInfo>[] {
    return this.state?.reservation?.affiliateOffers
  }

  headers: SimpleTableColumn<AffiliateOfferWithContactInfo>[] = [
    {
      text: 'Active',
      value: 'isActive',
      type: 'toggle',
      width: 30,
      align: 'start',
      onToggle: this.onToggleActive,
    },
    {
      text: 'Affiliate',
      value: 'customer',
      type: 'component',
      component: SimpleTableAffiliateNameCell,
      width: 100,
    },
    {
      text: 'Company',
      value: 'customer/customerAccountName',
      type: 'text',
      width: 100,
    },
    {
      text: 'Email',
      value: 'customer/email',
      type: 'text',
      width: 100,
    },
    {
      text: 'Phone',
      value: 'customer/phone',
      type: 'text',
      width: 100,
      formatter: (row: AffiliateOfferWithContactInfo): string =>
        phoneFormatFilter(row.customer.phone),
    },
    {
      text: 'Vehicles',
      value: 'vehicleCount',
      type: 'text',
      width: 200,
      formatter: this.formatVehicleInfo,
    },
    {
      text: 'Passengers',
      value: 'passengerCount',
      type: 'text',
      width: 100,
      formatter: this.formatPassengerCount,
    },
    {
      text: 'Farmout Amount',
      value: 'amount',
      type: 'text',
      width: 100,
      formatter: (row: AffiliateOfferWithContactInfo): string =>
        currencyFilter(row.amount),
    },
    {
      text: '',
      value: '',
      type: 'component',
      width: 100,
      component: SimpleTableAffiliateActionCell,
    },
  ]
}
