
import { Component, Prop, Vue } from 'vue-property-decorator'
import CUSimpleTable from '@/components/CUSimpleTable.vue'
import sidebar from '@/store/modules/sidebar'
import { Markup, TripMarkup } from '@/models/dto/Markup'
import { Trip, TripEstimation } from '@/models/dto'
import TripPricingMarkupForm from '@/components/TripPricingMarkupForm.vue'
import { isApplicable, toTripMarkup } from '@/utils/markup'
import { getTripBaseFare } from '@/utils/trip'
import { currencyFilter } from '@/utils/string'

@Component({
  components: { CUSimpleTable },
})
export default class TripPricingMarkups extends Vue {
  @Prop({ required: true }) readonly value!: Trip
  @Prop({ required: true }) readonly tripEstimation!: TripEstimation
  @Prop({ required: true }) readonly title!: string
  @Prop({ type: Boolean, default: false }) readonly readOnly!: boolean

  get tripMarkups(): TripMarkup[] {
    return this.value.tripMarkups || []
  }

  tripMarkupName(tripMarkup: TripMarkup): string {
    return tripMarkup?.markup?.name || '--'
  }

  tripMarkupType(tripMarkup: TripMarkup): string {
    return tripMarkup?.markup?.markupType?.label || '--'
  }

  tripMarkupPercentage(tripMarkup: TripMarkup): string {
    return `${tripMarkup?.markup?.markupPercentage}%`
  }

  tripMarkupAmount(tripMarkup: TripMarkup): string {
    const rate = tripMarkup?.markup?.markupPercentage || 100
    const baseFare = getTripBaseFare(this.value)
    const amount = currencyFilter((baseFare * rate) / 100, true)
    return isApplicable(tripMarkup?.markup, this.value) ? amount : '--'
  }

  get sum(): number {
    return getTripBaseFare(this.value)
  }

  handleMarkupAdd(): void {
    sidebar.push({
      title: 'Create New Markup',
      component: TripPricingMarkupForm,
      props: {
        trip: this.value,
        tripEstimation: this.tripEstimation,
      },
      on: {
        submit: this.handleMarkupSubmit,
        cancel: this.handleSubmissionCancel,
      },
    })
  }

  handleMarkupDelete(idx: number): void {
    const tripMarkups: TripMarkup[] = [...this.tripMarkups]
    tripMarkups.splice(idx, 1)
    this.$emit('input', { ...this.value, tripMarkups })
  }

  handleMarkupSubmit(markup: Markup): void {
    const tripMarkups: TripMarkup[] = [
      ...this.tripMarkups,
      { ...toTripMarkup(markup), tripId: this.value.tripId },
    ]
    this.$emit('input', { ...this.value, tripMarkups })
    sidebar.pop()
  }

  handleSubmissionCancel(): void {
    sidebar.pop()
  }
}
