
import { currencyFilter } from '@/utils/string'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class ReservationPostTripChargeOvertime extends Vue {
  @Prop({ required: true }) readonly totalDifferenceMinutes: number
  @Prop({ required: true }) readonly totalDifferenceMiles: number
  @Prop({ required: false }) readonly defaultOverageRateType: string
  @Prop({ required: false }) readonly defaultOverageRateAmount: number

  overtimeRateValue = 0
  perTypeValue = 'hourly_overtime_rate'
  countValue = 1
  overtimeChargeValue = 0

  @Watch('defaultOverageRateType', { immediate: true })
  onDefaultOverageRateTypeChange(overageRateType: string): void {
    const perTypeValue = overageRateType || 'hourly_overtime_rate'
    this.handlePerTypeValueInput(perTypeValue)
  }

  @Watch('defaultOverageRateAmount', { immediate: true })
  onDefaultOverageRateAmountChange(rateAmount: number): void {
    const overageRateAmount = rateAmount || 0
    this.handleOvertimeRateInput(overageRateAmount.toString())
  }

  @Watch('totalDifferenceMinutes')
  @Watch('totalDifferenceMiles')
  onDifferenceUpdate(): void {
    this.handlePerTypeValueInput(this.perTypeValue)
  }

  get overtimeChargeDisplay(): string {
    return currencyFilter(this.overtimeChargeValue)
  }

  get overtimeRateDisplay(): string {
    return currencyFilter(this.overtimeRateValue)
  }

  get totalDifferenceHours(): number {
    return parseFloat((this.totalDifferenceMinutes / 60).toFixed(2))
  }

  get totalDifferenceDays(): number {
    const differenceInDays = this.totalDifferenceHours / 24
    const roundedDownDifferenceInDays = Math.floor(differenceInDays)

    if (roundedDownDifferenceInDays === -1) {
      return 0
    }
    return roundedDownDifferenceInDays
  }

  get chargeButtonLabel(): string {
    return this.overtimeChargeValue >= 0 ? 'Add' : 'Reduce'
  }

  // Setters
  handleOvertimeRateInput(e: string): void {
    let overtimeRateValue = parseFloat(e.replace(/[^0-9.-]/g, ''))
    if (isNaN(overtimeRateValue)) {
      overtimeRateValue = 0
    }
    this.overtimeRateValue = overtimeRateValue

    this.calculateOvertimeChargeValue()
  }

  handleCountValueInput(e: string): void {
    let countValue = parseFloat(e.replace(/[^0-9.-]/g, ''))
    if (isNaN(countValue)) {
      countValue = 0
    }
    this.countValue = countValue

    this.calculateOvertimeChargeValue()
  }

  handlePerTypeValueInput(rateType: string): void {
    this.perTypeValue = rateType
    switch (rateType) {
      case 'hourly_overtime_rate':
        this.countValue = this.totalDifferenceHours
        break
      case 'daily_overage_rate':
        this.countValue = this.totalDifferenceDays
        break
      case 'mileage_overage_rate':
        this.countValue = this.totalDifferenceMiles
        break
      default:
        break
    }

    this.calculateOvertimeChargeValue()
  }

  handleOvertimeChargeInput(e: string): void {
    let overtimeCharge = parseFloat(e.replace(/[^0-9.-]/g, ''))
    if (isNaN(overtimeCharge)) {
      overtimeCharge = 0
    }
    this.overtimeChargeValue = overtimeCharge
    this.countValue = 1
    this.overtimeRateValue = overtimeCharge
  }

  calculateOvertimeChargeValue(): void {
    if (!this.overtimeRateValue || !this.countValue) {
      this.overtimeChargeValue = 0
    }

    const overtimeChargeValue = this.overtimeRateValue * this.countValue
    this.overtimeChargeValue = overtimeChargeValue
  }
}
