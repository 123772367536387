import { Type } from '@/models/dto/Type'

export interface Ticket {
  assigneeLastName: string
  createdAt: Date
  creatorFirstName: string
  creatorLastName: string
  description: string
  managedId: string
  reservationId: number
  reservationPickupDate: Date
  reservationStatus: string
  severity: string
  status: string
  subTickets: SubTicket[]
  ticketId: number
  ticketType: string
  companyId: number
}

export interface TicketDetailEntity {
  assignedToId: number
  cancelReservation: boolean
  comments: string
  companyId: number
  createdAt: Date
  createdById: number
  reservationId: number
  reservationManagedId: number
  sendEmail: string
  subTickets: SubTicket[]
  ticketId: number
  ticketSeverityTypeId: number
  ticketStatusTypeId: number
  ticketTypeId: number
  title: string
}

// eslint-disable-next-line no-redeclare
export class TicketDetailEntity implements TicketDetailEntity {
  constructor(ticketDetailEntity?: Partial<TicketDetailEntity>) {
    Object.assign(this, ticketDetailEntity)
  }
}

export interface SubTicket {
  comments: string
  createdAt: Date
  createdById: number
  subTicketId?: number
}

// eslint-disable-next-line no-redeclare
export class SubTicket implements SubTicket {
  constructor(subTicket?: SubTicket) {
    Object.assign(this, subTicket)
  }
}

export interface TicketSeverityType extends Type {
  active: boolean
  companyId: number
  id: number
}

export interface TicketStatusType extends Type {
  active: boolean
  companyId: number
  id: number
}

export interface TicketType extends Type {
  active: boolean
  companyId: number
  id: number
}

export interface Comment {
  comments: string
  createdAt: string
  createdById: number
  subTicketId: number
}
