
import { Component, Prop, Vue } from 'vue-property-decorator'
import TicketsList from '@/components/TicketsList.vue'
import TicketsListPlaceholder from '@/components/TicketsListPlaceholder.vue'
import sidebar from '@/store/modules/sidebar'
import TicketDetail from '@/components/TicketDetail.vue'
import { Reservation } from '@/models/dto'
import { TableViewParameters } from '@/models/TableView'
import { filter } from '@/utils/filter'
import { EventBus } from '@/utils/eventBus'

@Component({
  components: { TicketsListPlaceholder, TicketsList },
})
export default class ReservationTickets extends Vue {
  @Prop({ required: true }) readonly reservation!: Reservation
  loading = true
  count = 0

  get params(): TableViewParameters {
    const filters = filter()
    const parentFilter = filters.createParent('and')
    filters.add(parentFilter, {
      column: {
        _t_id: '029b6b2e-5f80-40ee-8c34-1f314f4285e6',
        value: 'reservation/managedId',
        filterAsIs: true,
        filterType: 'eq',
      },
      value: this.reservation.managedId,
    })
    return { filters: filters.asQueryParams() }
  }

  handleCreateTicket(): void {
    const reservation = this.reservation
    sidebar.push({
      title: 'Create Ticket',
      component: TicketDetail,
      props: { reservation },
    })
  }

  handleOnCollectionLoad(count: number): void {
    if (this.loading) {
      this.count = count
      this.loading = false
    }
  }

  resetTicketCount(): void {
    this.count = 1
  }

  mounted(): void {
    EventBus.$on('tickets:refresh', this.resetTicketCount)
  }

  beforeDestroy(): void {
    EventBus.$off('tickets:refresh', this.resetTicketCount)
  }
}
