import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { apiBaseUrl } from '@/utils/env'

const httpService: HttpService = new HttpService()

export default {
  upload(fileUpload: FormData): Promise<AxiosResponse> {
    const host = apiBaseUrl()
    const url = `https://${host}/files/upload`
    return httpService.post(url, fileUpload)
  },
  delete(id: number, isQuote: boolean): Promise<AxiosResponse> {
    const host = apiBaseUrl()
    const url = `https://${host}/files/delete/${id}?isQuote=${isQuote}`
    return httpService.delete(url)
  },
}
