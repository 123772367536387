import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { apiBaseUrl } from '@/utils/env'
import { AffiliateOffer } from '@/models/dto/Affiliate'

const httpService: HttpService = new HttpService()

export default {
  byId(id: number): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/reservations/affiliateOffer/${id}`
    return httpService.get(url)
  },
  sendAffiliateOffer(payload: AffiliateOffer): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/reservations/affiliateOffer`
    return httpService.post(url, payload)
  },
  editAffiliateOffer(payload: AffiliateOffer): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/reservations/affiliateOffer`
    return httpService.put(url, payload)
  },
  deleteAffiliateOffer(payload: {
    affiliateOfferId: number
    reservationId: number
  }): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/reservations/affiliateOffer`
    return httpService.delete(url, { data: payload })
  },
  toggleIsActive(payload: {
    affiliateOfferId: number
    isActive: boolean
  }): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/reservations/affiliateOffer/toggleIsActive`
    return httpService.put(url, payload)
  },
}
