
import { Component, Prop, Vue } from 'vue-property-decorator'
import TripDetails from '@/components/TripDetails.vue'
import { Trip, TripEstimation } from '@/models/dto'
import { TripPricings } from '@/models/TripPricings'
import rstate from '@/store/modules/reservation'
import tripClient from '@/services/trip'
import { EventBus } from '@/utils/eventBus'
import { getTripEstimation } from '@/utils/trip'

@Component({
  components: { TripDetails },
})
export default class ReservationReferralTrip extends Vue {
  @Prop({ required: true }) readonly trip!: Trip
  @Prop({ required: true }) readonly tripEstimation!: TripEstimation
  @Prop({ required: true }) readonly tripPricings!: TripPricings

  isTripChanged = false
  loading = false
  tripEstimationDebounce = null

  handleTripChange(trip: Trip): void {
    this.isTripChanged = true
    rstate.updateTrip({ ...trip })

    if (this.tripEstimationDebounce) {
      window.clearTimeout(this.tripEstimationDebounce)
    }
    this.tripEstimationDebounce = window.setTimeout(() => {
      this.handleUpdateTripEstimation()
    }, 250)
  }

  async handleUpdateTripEstimation(): Promise<void> {
    try {
      const tripEstimation = await getTripEstimation(rstate.trip)
      rstate.setTripEstimations(tripEstimation)
    } catch (e) {
      return
      // Intentionally blank, catching the tripEstimations endpoint
      // returning a 500
    }
  }

  async handleSave(): Promise<void> {
    if (!this.isTripChanged) {
      return
    }

    const tripDetails: any = this.$refs['reservation-trip-details']
    if (!tripDetails.validate()) {
      return
    }

    this.loading = true

    try {
      const res = await tripClient.modifyTripByStops({
        tripId: rstate.trip.tripId,
        hardConflictOverride: true,
        payload: rstate.trip,
        skipAssignmentsUpdate: true,
      })
      EventBus.$emit('snackbar:success', 'Reservation successfully saved')
      this.$emit('refresh')
      this.isTripChanged = false
    } catch (e) {
      EventBus.$emit('snackbar:error', e)
    } finally {
      this.loading = false
    }
  }
}
