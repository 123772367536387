
import {
  Reservation,
  ReservationStatusUpdate,
  UserDetail,
  Trip,
} from '@/models/dto'
import ContentWithSidebar from '@/layouts/ContentWithSidebar.vue'
import { Provide, Watch } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import reservation from '@/store/modules/reservation'
import chargeClient from '@/services/charges'
import ReservationSidebarDetail from '@/components/ReservationSidebarDetail.vue'
import ReservationTracking from '@/components/ReservationTracking.vue'
import { Tab } from '@/models/dto/Tab'
import {
  fillReservationInformation,
  getReservationById,
} from '@/utils/reservation'
import { EventBus } from '@/utils/eventBus'
import { Action } from '@/models/dto/Action'
import SendTripInfoSidebar from '@/components/SendTripInfoSidebar.vue'
import {
  ContactTypeKeys,
  ReservationStatus,
  SourceCategory,
} from '@/utils/enum'
import sidebar from '@/store/modules/sidebar'
import { Contact } from '@/models/Contact'
import SendReceiptSidebar from '@/components/SendReceiptSidebar.vue'
import SendDriverInfoSidebar from '@/components/SendDriverInfoSidebar.vue'
import SendTripCancellationSidebar from '@/components/SendTripCancellationSidebar.vue'
import client from '@/services/reservation'
import { saveAs } from 'file-saver'
import SendDriverTripSheetSidebar from '@/components/SendDriverTripSheetSidebar.vue'
import { uniqueBy } from '@/utils/array'
import ReservationPayment from '@/components/ReservationPayment.vue'
import ReservationTickets from '@/components/ReservationTickets.vue'
import dayjs from 'dayjs'
import { DueDateChange } from '@/models/dto/Charge'
import ReservationTrip from '@/components/ReservationTrip.vue'
import ReservationReferralTrip from '@/components/ReservationReferralTrip.vue'
import { EventType } from '@/models/EventType'
import messages from '@/data/messages'
import { parseAxiosError } from '@/utils/error'
import ReservationNotifications from '@/components/ReservationNotifications.vue'
import app from '@/store/modules/app'
import auth from '@/store/modules/auth'
import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'
import { apiBaseUrl } from '@/utils/env'
import SendToAffiliateSidebar from '@/components/SendToAffiliateSidebar.vue'
import { AffiliateOffer } from '@/models/dto/Affiliate'
import HoldUpModal from '@/components/HoldUpModal.vue'
import affiliate from '@/services/affiliate'
import ReservationDriverPay from '@/components/ReservationDriverPay.vue'
import ReservationFarmout from '@/components/ReservationFarmout.vue'
import ReservationPostTrip from '@/components/ReservationPostTrip.vue'
import billingOverride from '@/services/billingOverride'
import tripClient from '@/services/trip'
import { getSelectedDataFromResState } from '@/utils/assignment'
import assignment from '@/store/modules/assignment'

@Component({
  components: {
    ReservationSidebarDetail,
    ContentWithSidebar,
    HoldUpModal,
  },
})
export default class ReservationDetails extends mixins(DateMixin) {
  @Provide() isReservationTrip = true

  state = reservation
  reservationId: number = null
  isSaving = false
  tabIndex = null
  contacts = []
  isDeletePostTripOpen = false

  get title(): string {
    let title = `Reservation ${this.state.reservation.managedId}`
    if (this.state.trip?.routeName) {
      title += ` - ${this.state.trip?.routeName}`
    }
    if (this.state.reservation?.managedReferralId) {
      title += ` (Referral ID: ${this.state.reservation.managedReferralId})`
    }
    return title
  }

  get isReferral(): boolean {
    return reservation.reservation?.sourceCategory === SourceCategory.REFERRAL
  }

  get hasFarmout(): boolean {
    return !!reservation.reservation.affiliateOffers?.filter(
      (offer) => offer.isActive
    )?.length
  }

  get lastUpdated(): string {
    const ts = this.state.reservation?.updatedOn
    return ts
      ? this.formatShortDateShortTime(ts, { showMeridianUpper: true })
      : 'never'
  }

  get drivers(): { email: string; type: string }[] {
    const contacts = this.state.assignments
      .flatMap((assignment) => assignment.driverAssignments)
      .map(({ driver: { email } }) => ({ email, type: 'Driver' }))
    return uniqueBy(contacts, 'email')
  }

  get isCharterUpReferral(): boolean {
    return this.state.reservation.sourceCategory === SourceCategory.REFERRAL
  }

  get hasPostTrip(): boolean {
    return !!this.state.billingOverrideView?.vehicles?.length
  }

  get isMissingStopTimes(): boolean {
    const stops = reservation.reservation.stops
    return !(
      !!stops[0].pickupDatetime && !!stops[stops.length - 1].dropoffDatetime
    )
  }

  @Watch('$route.params.id', { immediate: true })
  onReservationChange(): void {
    this.loadReservation()
  }

  async loadReservation(): Promise<void> {
    const id = this.$route.params?.id
    this.reservationId = Number.parseInt(id, 10)
    if (isNaN(this.reservationId)) {
      this.$router.push({
        name: 'not-found',
      })
      return
    }

    assignment.setLoading(true)
    this.loadPaymentTransactions(this.reservationId)
    const partialRes = await getReservationById(this.reservationId)
    this.loadContacts(partialRes)
    this.loadPostTrip(partialRes?.tripId)

    const reservation = await fillReservationInformation(
      partialRes,
      this.reservationId
    )

    this.state.setReservationInformation(reservation)
    this.state.setPartialReservationInvoiceDetails(
      reservation.reservationInvoiceDetails
    )
    this.state.loadPricingInfo()

    // init assignment vuex to preload assignment data
    await getSelectedDataFromResState()
    assignment.setLoading(false)
  }

  displayPaymentTab(): void {
    this.loadPaymentTransactions(this.reservationId)
    const paymentTabIdx = this.tabs.findIndex((tab) => tab.id === 'payments')
    if (paymentTabIdx > -1) {
      this.tabIndex = paymentTabIdx
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.$router.push({ hash: 'payments' }).catch(() => {})
      const tabs = this.$refs.tabs as any
      if (tabs) {
        tabs.setTabsFromHash()
      }
    }
  }

  displayPostTripTab(): void {
    const postTripTabIdx = this.tabs.findIndex((tab) => tab.id === 'post-trip')
    if (postTripTabIdx > -1) {
      this.tabIndex = postTripTabIdx
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.$router.push({ hash: 'post-trip' }).catch(() => {})
      const tabs = this.$refs.tabs as any
      if (tabs) {
        tabs.setTabsFromHash()
      }
    }
  }

  async loadPostTrip(tripId: number): Promise<void> {
    try {
      const res = await billingOverride.view(tripId)
      if (res.status === 200) {
        this.state.setBillingOverrideView(res.data)
      }
    } catch (e) {
      console.error('No billing override view exists for this reservation')
    }
  }

  loadContacts(reservation: Reservation): void {
    const bookingContactEmail = reservation.customerEmail
    const billingContactEmail = reservation.billingCustomerEmail

    this.contacts = [
      {
        email: bookingContactEmail,
        type: ContactTypeKeys.BOOKING,
      },
    ]
    if (billingContactEmail) {
      this.contacts.push({
        email: billingContactEmail,
        type: ContactTypeKeys.BILLING,
      })
    }
  }

  async loadPaymentTransactions(reservationId: number): Promise<void> {
    const { data: transactions } = await client.getTransactionsForReservation(
      reservationId
    )
    reservation.setTransactions(transactions)
  }

  async saveAndLoadReservation(): Promise<void> {
    if (this.state.tripHasBeenModified) {
      await this.saveTrip()
    } else {
      await this.loadReservation()
    }
  }

  updateReservation(res: Reservation): void {
    this.state.setReservation(res)
  }

  async handleCustomerAccountChange(customerAccountId: number): Promise<void> {
    await client.updateReservationCustomerAccount(
      this.state.reservation?.reservationId,
      customerAccountId
    )
    this.saveAndLoadReservation()
  }

  async handleEventTypeIdChange(eventType: EventType): Promise<void> {
    this.state.updateReservation({ eventTypeId: eventType.id })
    await client.updateReservationEventTypeId(
      this.state.reservation?.reservationId,
      eventType
    )
    this.saveAndLoadReservation()
  }

  async handleChangeDueDate(date: string): Promise<void> {
    const payload: DueDateChange = {
      reservationIds: [this.state.reservation?.reservationId],
      dueDate: dayjs(date).toISOString(),
    }
    await chargeClient.changeChargeDueDate(payload)
    this.saveAndLoadReservation()
  }

  handleEditAffiliate(): void {
    this.$router.push('#farmout')
  }

  handleChangeAffiliate(index: number): void {
    sidebar.popAllAndPush({
      component: SendToAffiliateSidebar,
      props: {
        title: 'Change Affiliate',
        editContactOnly: true,
        index,
      },
      persist: true,
      on: {
        cancel: this.handleSubmissionCancel,
        submit: (offerData: AffiliateOffer) =>
          this.handleEditAffiliateOffer(
            offerData,
            'Affiliate changed successfully'
          ),
      },
    })
  }

  async handleRemoveAffiliate(index: number): Promise<void> {
    try {
      await affiliate.deleteAffiliateOffer({
        affiliateOfferId:
          this.state.reservation.affiliateOffers[index].affiliateOfferId,
        reservationId: this.state.reservation.reservationId,
      })
      this.saveAndLoadReservation()
      EventBus.$emit('snackbar:success', 'Affiliate removed successfully!')
    } catch (e: any) {
      EventBus.$emit('snackbar:error', parseAxiosError(e))
    }
  }

  async handleStatusChange(reservationStatus: string): Promise<void> {
    if (reservationStatus === 'cancelled') {
      this.handleSendTripCancellation()
    } else {
      this.state.updateReservation({ reservationStatus })
      const statusUpdate: ReservationStatusUpdate = {
        reservationIds: [this.state.reservation?.reservationId],
        reservationStatus,
      }
      await client.updateReservationStatus(statusUpdate)
      this.saveAndLoadReservation()
    }
  }

  async handleReferredByChange(): Promise<void> {
    const update: Partial<Reservation> = {
      reservationId: this.state.reservation?.reservationId,
      referredBy: this.state.reservation?.referredBy,
    }
    await client.updateReservationReferredBy(update)
    this.saveAndLoadReservation()
  }

  async handleManagedIdChange(managedId: string): Promise<void> {
    const update: Partial<Reservation> = {
      reservationId: this.state.reservation?.reservationId,
      managedId,
    }
    try {
      await client.updateReservationManagedId(update)
      this.state.updateReservation({ managedId })
      this.saveAndLoadReservation()
    } catch (e: any) {
      EventBus.$emit('snackbar:error', parseAxiosError(e))
      console.log(e)
    }
  }

  async handleSendTripInfo(): Promise<void> {
    if (this.state.tripHasBeenModified) {
      await this.saveTrip()
    }

    sidebar.push({
      title: 'Send Trip Info',
      component: SendTripInfoSidebar,
      props: { contacts: this.contacts },
      on: {
        cancel: this.handleSubmissionCancel,
        notify: this.handleSendTripInfoEmails,
        preview: this.handleSendTripInfoPreviewEmail,
      },
    })
  }

  async handleSendReceipt(): Promise<void> {
    if (this.state.tripHasBeenModified) {
      await this.saveTrip()
    }

    sidebar.push({
      title: 'Send Receipt',
      component: SendReceiptSidebar,
      props: { contacts: this.contacts },
      on: {
        cancel: this.handleSubmissionCancel,
        notify: this.handleSendReceiptEmails,
        preview: this.handleSendReceiptPreviewEmail,
      },
    })
  }

  async handleSendDriverInfo(): Promise<void> {
    if (this.state.tripHasBeenModified) {
      await this.saveTrip()
    }

    sidebar.push({
      title: 'Send Driver Info',
      component: SendDriverInfoSidebar,
      props: {
        contacts: this.contacts,
        driversFullyAssigned:
          this.state.reservation.assignedDriverPercentage === 100,
      },
      on: {
        cancel: this.handleSubmissionCancel,
        notify: this.handleSendDriverInfoEmails,
        preview: this.handleSendDriverInfoPreviewEmail,
      },
    })
  }

  async handleSendDriverTripSheet(): Promise<void> {
    if (this.state.tripHasBeenModified) {
      await this.saveTrip()
    }
    sidebar.push({
      title: 'Send Driver Trip Sheet',
      component: SendDriverTripSheetSidebar,
      props: { drivers: this.drivers },
      on: {
        cancel: this.handleSubmissionCancel,
        notify: this.handleSendDriverTripSheetEmails,
        preview: this.handleSendDriverTripSheetPreviewEmail,
        download: this.handleDownloadDriverTripSheet,
      },
    })
  }

  async handleDuplicateReservation(): Promise<void> {
    try {
      const res = await client.duplicateReservation(this.reservationId)
      if (res.status === 200) {
        const route = this.$router.resolve({
          name: 'reservations.detail',
          params: { id: res.data },
        })
        window.open(route.href)
      }
    } catch (e) {
      console.error(e)
      EventBus.$emit('snackbar:error', 'Error duplicating reservation')
    }
  }

  handleSendTripCancellation(): void {
    sidebar.push({
      title: 'Cancel Trip',
      component: SendTripCancellationSidebar,
      props: { contacts: this.contacts },
      on: {
        cancel: this.handleSubmissionCancel,
        notify: this.handleSendTripCancellationEmails,
        preview: this.handleSendTripCancellationPreviewEmail,
        submit: this.handleTripCancellation,
      },
    })
  }

  handleSendToAffiliate(): void {
    sidebar.popAllAndPush({
      component: SendToAffiliateSidebar,
      persist: true,
      on: {
        cancel: this.handleSubmissionCancel,
        submit: (offerData: AffiliateOffer) =>
          this.handleSendAffiliateOffer(offerData),
      },
    })
  }

  async handleSendAffiliateOffer(offerData: AffiliateOffer): Promise<void> {
    try {
      if (offerData.profit === null) {
        offerData.profit = 0
      }

      await affiliate.sendAffiliateOffer(offerData)
      const message = offerData.sendConfirmationToAffiliate
        ? 'Sent to affiliate successfully!'
        : 'Offer saved successfully!'
      EventBus.$emit('snackbar:success', message)

      await this.saveAndLoadReservation()
      this.$router.push('#farmout')
    } catch (e: any) {
      EventBus.$emit('snackbar:error', parseAxiosError(e))
    } finally {
      sidebar.pop()
    }
  }

  async handleEditAffiliateOffer(
    offerData: AffiliateOffer,
    message: string
  ): Promise<void> {
    try {
      await affiliate.editAffiliateOffer(offerData)
      this.saveAndLoadReservation()
      EventBus.$emit('snackbar:success', message)
    } catch (e: any) {
      EventBus.$emit('snackbar:error', parseAxiosError(e))
    } finally {
      sidebar.pop()
    }
  }

  handleSubmissionCancel(): void {
    sidebar.pop()
  }

  async handleSendTripInfoEmails(contacts: Contact[]): Promise<void> {
    const contactEmails = contacts.map((c) => c.email)
    try {
      await client.resendConfirmation(
        this.reservationId,
        contactEmails,
        auth.getUserTimeZone
      )
      EventBus.$emit('snackbar:success', messages.success.emailSent)
      sidebar.pop()
    } catch (e: any) {
      EventBus.$emit('snackbar:error', parseAxiosError(e))
    }
  }

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  handleSendTripInfoPreviewEmail(user: UserDetail): void {
    // TODO: Send preview
  }

  async handleSendReceiptEmails(contacts: Contact[]): Promise<void> {
    const contactEmails = contacts.map((c) => c.email)
    try {
      await client.resendReceipt(
        this.reservationId,
        contactEmails,
        auth.getUserTimeZone
      )
      EventBus.$emit('snackbar:success', messages.success.emailSent)
      sidebar.pop()
    } catch (e: any) {
      EventBus.$emit('snackbar:error', parseAxiosError(e))
    }
  }

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  handleSendReceiptPreviewEmail(user: UserDetail): void {
    // TODO: Send preview
  }

  async handleSendDriverInfoEmails(contacts: Contact[]): Promise<void> {
    const contactEmails = contacts.map((c) => c.email)
    try {
      await client.sendDriverInfo(this.reservationId, contactEmails)
      EventBus.$emit('snackbar:success', messages.success.emailSent)
      sidebar.pop()
    } catch (e: any) {
      EventBus.$emit('snackbar:error', parseAxiosError(e))
    }
  }

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  handleSendDriverInfoPreviewEmail(user: UserDetail): void {
    // TODO: Send preview
  }

  openContract(): void {
    const url = `https://${apiBaseUrl('pdf')}/pdf/contract-quote-${
      reservation.reservation.quoteHash
    }.pdf`
    window.open(url)
  }

  async handleSendDriverTripSheetEmails(contacts: Contact[]): Promise<void> {
    const contactEmails = contacts.map((c) => c.email)
    try {
      await client.sendDriverTripSheet(
        this.reservationId,
        contactEmails,
        auth.getUserTimeZone
      )
      EventBus.$emit('snackbar:success', messages.success.emailSent)
      sidebar.pop()
    } catch (e: any) {
      EventBus.$emit('snackbar:error', parseAxiosError(e))
    }
  }

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  handleSendDriverTripSheetPreviewEmail(user: UserDetail): void {
    // TODO: Send preview
  }

  handleDownloadDriverTripSheet(): void {
    const hash = this.state.reservation?.hash
    const reservationId = this.state.reservation?.managedId
    if (hash && reservationId) {
      const name = `${reservationId}_Reservation_Itinerary.pdf`
      client.downloadItinerary(hash).then((res) => saveAs(res.data, name))
    }
  }

  async handleSendTripCancellationEmails(
    contacts: Contact[],
    note: string
  ): Promise<void> {
    const tos = contacts.map((c) => c.email)
    try {
      await client.sendCancellationEmail(
        this.reservationId,
        tos,
        note,
        auth.getUserTimeZone
      )
      EventBus.$emit('snackbar:success', 'Email sent successfully!')
    } catch {
      EventBus.$emit('snackbar:error', 'Error sending email.')
    }
  }

  async handleEditTab(val: { name: string }): Promise<void> {
    const trip: Partial<Trip> = { routeName: val.name }
    await tripClient.editTripName(this.trip.tripId, trip)
    this.state.updateTrip(trip)
  }

  async handleUpdateReservationEnableTrackingLink(
    enableTrackingLink: boolean
  ): Promise<void> {
    try {
      const reservationUpdate: Partial<Reservation> = {
        reservationId: this.state.reservation.reservationId,
        enableTrackingLink,
      }
      await client.updateTrackingLinkStatus(reservationUpdate)
      await this.loadReservation()
    } catch (e) {
      EventBus.$emit('snackbar:error', 'Error updating tracking link status.')
      console.log(e)
    }
  }

  async handleSendTripCancellationPreviewEmail(
    user: UserDetail,
    note: string
  ): Promise<void> {
    try {
      await client.sendCancellationEmail(this.reservationId, [user.email], note)
      EventBus.$emit('snackbar:success', 'Email sent successfully!')
    } catch {
      EventBus.$emit('snackbar:error', 'Error sending email.')
    }
  }

  async handleTripCancellation(): Promise<void> {
    const payload: ReservationStatusUpdate = {
      reservationIds: [this.state.reservation?.reservationId],
      reservationStatus: ReservationStatus.Cancelled,
    }
    try {
      await client.updateReservationStatus(payload)
      await this.loadReservation()
      EventBus.$emit('snackbar:success', 'Trip cancelled successfully!')
    } catch {
      EventBus.$emit('snackbar:error', 'Error cancelling trip.')
    }
    sidebar.pop()
  }

  async handleCreatePostTrip(): Promise<void> {
    const res = await billingOverride.create(this.state.reservation.tripId)
    if (res.status === 200) {
      await this.loadPostTrip(this.state.reservation.tripId)
      this.displayPostTripTab()
    }
  }

  handleDeletePostTripConfirmation(): void {
    this.isDeletePostTripOpen = true
  }

  async handleDeletePostTrip(): Promise<void> {
    const res = await billingOverride.delete(this.state.reservation.tripId)
    if (res.status === 200) {
      this.state.clearBillingOverrideView()
      EventBus.$emit('snackbar:success', 'Post Trip was deleted successfully')
    } else {
      EventBus.$emit('snackbar:error', 'Error deleting Post Trip')
    }
  }

  get trip(): Trip {
    return this.state.trip
  }

  private mainTabTitleHelper = (section?: string): string => {
    if (!section) {
      return this.state.reservation?.managedId
        ? `Reservation ${this.state.reservation.managedId}`
        : 'Reservation'
    }

    return this.state.reservation?.managedId
      ? `Reservation ${this.state.reservation.managedId} - ${section}`
      : `Reservation - ${section}`
  }

  get actions(): Action[] {
    const actions: Action[] = this.isReferral
      ? [
          {
            label: 'Send Driver Trip Sheet',
            id: 'send-driver-trip-sheet',
            event: 'reservation:send-driver-trip-sheet',
            icon: 'pdf',
          },
        ]
      : [
          {
            label: 'Send Trip Info',
            id: 'send-trip-info',
            event: 'reservation:send-trip-info',
            icon: 'send',
          },
          {
            label: 'Send Receipt',
            id: 'send-receipt',
            event: 'reservation:send-receipt',
            icon: 'receipt',
          },
          {
            label: 'Send Driver Info',
            id: 'send-driver-info',
            event: 'reservation:send-driver-info',
            icon: 'driver',
          },
          {
            label: 'Send Driver Trip Sheet',
            id: 'send-driver-trip-sheet',
            event: 'reservation:send-driver-trip-sheet',
            icon: 'pdf',
          },
          ...(this.isFarmoutActive
            ? [
                {
                  label: 'Send to Affiliate',
                  id: 'send-to-affiliate',
                  event: 'reservation:send-to-affiliate',
                  icon: 'arrow-right',
                  iconLabel: 'Send',
                },
              ]
            : []),
          {
            label: 'Cancel Trip',
            id: 'send-trip-cancellation',
            event: 'reservation:send-trip-cancellation',
            icon: 'close',
            iconLabel: 'Cancel',
          },
        ]
    if (!this.isReferral && this.showDownloadContractAction) {
      actions.splice(4, 0, {
        label: 'Download Contract',
        id: 'download-contract',
        event: 'reservation:download-contract',
        icon: 'pdf',
        disabled: this.downloadContractDisabled,
      })
    }

    if (app.isPostTripEnabled && !this.hasPostTrip) {
      // If Cancel Trip is an action, slot Post Trip immediately before it
      let cancelTripIndex = actions.findIndex(
        (action) => action.id === 'send-trip-cancellation'
      )
      cancelTripIndex =
        cancelTripIndex === -1 ? actions.length : cancelTripIndex

      actions.splice(cancelTripIndex, 0, {
        label: 'Create Post Trip',
        id: 'create-post-trip',
        event: 'reservation:create-post-trip',
        icon: 'post_trip',
        iconLabel: 'Post Trip',
        disabled: !this.state.assignments.length,
        tooltip: !this.state.assignments.length
          ? 'Assign vehicles to enable'
          : null,
      })
    } else if (app.isPostTripEnabled && this.hasPostTrip) {
      // If Cancel Trip is an action, slot Post Trip immediately before it
      let cancelTripIndex = actions.findIndex(
        (action) => action.id === 'send-trip-cancellation'
      )
      cancelTripIndex =
        cancelTripIndex === -1 ? actions.length : cancelTripIndex

      actions.splice(cancelTripIndex, 0, {
        label: 'Delete Post Trip',
        id: 'delete-post-trip',
        event: 'reservation:delete-post-trip',
        icon: 'delete',
        iconLabel: 'Delete Post Trip',
      })
    }

    if (app.isDuplicateReservationEnabled) {
      // If Cancel Trip is an action, slot Duplicate Reservation immediately before it (overriding post trip)
      let cancelTripIndex = actions.findIndex(
        (action) => action.id === 'send-trip-cancellation'
      )
      cancelTripIndex =
        cancelTripIndex === -1 ? actions.length : cancelTripIndex

      actions.splice(cancelTripIndex, 0, {
        label: 'Duplicate Reservation',
        id: 'duplicate-reservation',
        event: 'reservation:duplicate-reservation',
        icon: 'copy',
        iconLabel: 'Duplicate',
      })
    }
    return actions
  }

  get isFarmoutActive(): boolean {
    return (
      app.getSystemParameters.find(({ name }) => name === 'isFarmoutEnabled')
        ?.value === 'true'
    )
  }

  get isDuplicateReservationEnabled(): boolean {
    return app.isDuplicateReservationEnabled
  }

  get tabs(): Tab[] {
    const tabs: Tab[] = [
      {
        label: 'Tracking',
        component: ReservationTracking,
        hash: 'tracking',
        id: 'reservation-tracking',
        eager: true,
        title: this.mainTabTitleHelper('Tracking'),
        props: {
          assignments: this.state.assignments,
          tracking: this.state.tracking,
          isSelected: this.tabIndex === 0,
          tripEstimation: this.state.estimations,
        },
        on: {
          'update:enable-tracking-link':
            this.handleUpdateReservationEnableTrackingLink,
        },
      },
      {
        label: this.trip?.routeName || `Trip`,
        component: this.isReferral ? ReservationReferralTrip : ReservationTrip,
        editable: true,
        hash: this.isReferral ? 'referral-trip-details' : null,
        title: this.mainTabTitleHelper('Details'),
        subtabHashes: !this.isReferral
          ? ['trip-details', 'trip-pricing']
          : null,
        ref: 'reservation-details-trip',
        id: 'reservation-details-trip',
        props: {
          trip: this.state.trip,
          tripEstimation: this.state.estimations,
          tripPricings: this.state.getTripPricings,
        },
        on: {
          refresh: async () => {
            await this.loadReservation()
            this.state.updateReservation({ updatedOn: dayjs().toISOString() })
          },
        },
      },
      {
        label: 'Payments',
        id: 'payments',
        hash: 'payments',
        title: this.mainTabTitleHelper('Payments'),
        component: ReservationPayment,
      },
    ]
    if (this.state.reservation?.affiliateOffers?.length > 0) {
      tabs.push({
        label: 'Farmout',
        id: 'farmout',
        hash: 'farmout',
        title: this.mainTabTitleHelper('Farmout'),
        component: ReservationFarmout,
        on: {
          'affiliate-offer:refresh': async () => {
            await this.loadReservation()
          },
        },
      })
    }

    if (this.hasPostTrip) {
      tabs.push({
        label: 'Post Trip',
        id: 'post-trip',
        hash: 'post-trip',
        title: this.mainTabTitleHelper('Post Trip'),
        component: ReservationPostTrip,
        on: {
          'post-trip:charge-created': this.displayPaymentTab,
        },
      })
    }

    tabs.push({
      label: 'Driver Pay',
      id: 'driver-pay',
      hash: 'driver-pay',
      title: this.mainTabTitleHelper('Driver Pay'),
      component: ReservationDriverPay,
    })

    if (
      !this.isReferral &&
      auth.getRoles.findIndex(
        (role) => role.roleName === ACCESS_SETTINGS_ROLES.OPERATIONS
      ) !== -1
    ) {
      tabs.push({
        label: 'Tickets',
        component: ReservationTickets,
        id: 'reservation-tickets',
        hash: 'tickets',
        title: this.mainTabTitleHelper('Tickets'),
        props: { reservation: this.state.reservation },
      })
    }

    if (!this.isReferral) {
      tabs.push({
        label: 'Notifications',
        id: 'notifications',
        hash: 'notifications',
        title: this.mainTabTitleHelper('Notifications'),
        component: ReservationNotifications,
      })
    }

    return tabs
  }

  get showDownloadContractAction(): boolean {
    return (
      this.state?.reservation?.quoteHasCheckoutDocument ||
      auth?.getCompany?.defaultRequireSignatureUponCheckout
    )
  }

  get downloadContractDisabled(): boolean {
    return (
      !this.state?.reservation?.quoteHasCheckoutDocument &&
      auth?.getCompany?.defaultRequireSignatureUponCheckout
    )
  }

  async saveTrip(): Promise<void> {
    const ref = (this.$refs['tabs'] as any)?.$refs?.[
      this.isReferral ? 'reservation-details-trip' : 'reservation-trip-details'
    ]?.[0]
    if (ref) {
      await ref.handleSave()
    }
  }

  mounted(): void {
    EventBus.$on('refresh-reservation', this.saveAndLoadReservation)
  }

  beforeDestroy(): void {
    EventBus.$off('refresh-reservation', this.saveAndLoadReservation)
  }

  destroyed(): void {
    this.state.clear()
  }
}
