
import { Vue, Component, Prop } from 'vue-property-decorator'
import CUExpansionPanel from './CUExpansionPanel.vue'
import CUFileUpload from './CUFileUpload.vue'
import { File as CustomFile } from '@/models/dto/File'
import fileService from '@/services/files'
import { EventBus } from '@/utils/eventBus'
import { baseUrl } from '@/utils/env'

@Component({
  components: { CUExpansionPanel, CUFileUpload },
})
export default class FileUploadQuoteAndRes extends Vue {
  @Prop({ required: false }) readonly reservationId: number
  @Prop({ required: false }) readonly quoteId: number
  @Prop({ required: false }) files: CustomFile[]

  filesToUpload: File[] = []
  loading = false
  showAddFileButton = false

  get existingFiles(): CustomFile[] {
    return this.files
  }

  get currentFiles(): File[] {
    return this.filesToUpload
  }

  get showFileToggle(): boolean {
    return this.files?.length < 5 && !this.showAddFileButton
  }

  async handleFileUpload(file: File): Promise<void> {
    try {
      if (!this.reservationId && !this.quoteId) {
        EventBus.$emit(
          'snackbar:error',
          'Please save the quote before uploading files.'
        )
        return
      }

      this.loading = true
      this.filesToUpload = [...this.filesToUpload, file]

      const payload = new FormData()
      payload.append('file', file)
      payload.append('isQuote', (!this.reservationId).toString())
      payload.append('id', (this.reservationId || this.quoteId).toString())

      const fileResponse = await fileService.upload(payload)
      this.$emit('files:update', [...this.files, fileResponse.data])

      EventBus.$emit('snackbar:success', 'File uploaded')
    } catch (error) {
      EventBus.$emit('snackbar:error', 'Error uploading file')
    } finally {
      this.loading = false
      this.filesToUpload = this.filesToUpload.filter(
        (fileToUpload) => fileToUpload.name !== file.name
      )
    }
  }

  async handleFileDelete(id: number): Promise<void> {
    try {
      await fileService.delete(id, !this.reservationId)
      this.$emit(
        'files:update',
        this.files.filter((file) => file.id !== id)
      )

      EventBus.$emit('snackbar:success', 'File deleted')
    } catch (error) {
      console.log(error)
      EventBus.$emit('snackbar:error', 'Error deleting file')
    }
  }

  handleOpenFile(file: CustomFile): void {
    const host = baseUrl()
    window.open(`https://${host}${file.url}`, '_blank')
  }
}
