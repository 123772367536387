
import { Component, Prop, Vue } from 'vue-property-decorator'

import AutocompleteAddress from './AutocompleteAddress.vue'
import { Stop, Address, AddressWithSavedNotes } from '@/models/dto'
import { CompanyStop, CompanyStopTableViews } from '@/models/CompanyStop'
import companyStopsStore from '@/store/modules/companyStops'
import { GenericStateStatus } from '@/models/GenericStateStatus'

@Component({
  components: { AutocompleteAddress },
})
export default class StopCardAutocompleteAddress extends Vue {
  @Prop({ required: true, type: Stop })
  readonly stop!: Stop
  @Prop({ required: false, type: Boolean })
  readonly readOnly!: boolean

  companyStops: CompanyStop[] = []

  get address(): Address {
    return { ...this.stop.address, addressName: this.addressName }
  }

  get addressName(): string {
    return this.stop.address?.addressName || this.stop.address?.name || ''
  }

  get savedCompanyStops(): GenericStateStatus<CompanyStopTableViews> {
    return companyStopsStore.state
  }

  handleAddressChange(address: AddressWithSavedNotes): void {
    this.$emit('handle-address-change', address)
    if (address?.savedNotes) {
      // next tick to ensure that the parent component has the updated address
      this.$nextTick(() => {
        this.$emit('handle-notes-change', address.savedNotes)
      })
    }
  }
}
