
import reservation from '@/store/modules/reservation'
import { Prop } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { currencyFilter, pluralize } from '@/utils/string'
import dayjs from 'dayjs'

@Component({})
export default class ReservationDriverPayStats extends mixins(DateMixin) {
  @Prop({ type: Boolean, default: false }) loading: boolean

  currencyFilter = currencyFilter
  pluralize = pluralize
  state = reservation

  get drivers(): number {
    return (
      this.state.assignments?.flatMap((a) => a.driverAssignments)?.length || 0
    )
  }

  get plannedHours(): string {
    if (!this.pickup || !this.dropoff) {
      return '--'
    }

    const hours = dayjs(this.dropoff).diff(this.pickup, 'hour', true)
    return Math.abs(hours).toFixed(2)
  }

  get plannedDays(): number {
    return (
      Math.abs(
        dayjs(dayjs(this.dropoff).startOf('day')).diff(
          dayjs(this.pickup).startOf('day'),
          'day'
        )
      ) + 1
    )
  }

  get totalPayment(): number {
    const invoices = this.state.invoices || []
    const total = invoices.reduce((acc, invoice) => {
      return acc + invoice.totalCharges
    }, 0)
    return total
  }

  get pickup(): string {
    let start = null
    let tz = null
    if (!this.state.trip) {
      return ''
    }

    const trip = this.state.trip

    if (trip.garageTimes?.preTripArrivalTime) {
      start = trip.garageTimes.preTripArrivalTime
      tz = trip.garageTimes.garage?.address?.timeZone
    } else if (trip.garageTimes?.departureTime) {
      start = trip.garageTimes.departureTime
      tz = trip.garageTimes.garage?.address?.timeZone
    } else {
      start = this.state.trip?.stops?.[0]?.pickupDatetime
      tz = this.state.trip?.stops?.[0]?.address?.timeZone
    }

    if (!start || !tz) {
      return ''
    }
    return start
  }

  get formattedPickup(): string {
    let tz = null
    if (!this.state.trip) {
      return ''
    }

    const trip = this.state.trip
    if (trip.garageTimes?.preTripArrivalTime) {
      tz = trip.garageTimes.garage?.address?.timeZone
    } else if (trip.garageTimes?.departureTime) {
      tz = trip.garageTimes.garage?.address?.timeZone
    } else {
      tz = this.state.trip?.stops?.[0]?.address?.timeZone
    }

    if (!this.pickup || !tz) {
      return ''
    }

    return this.formatLongDateShortTime(this.pickup, {
      tz,
      showMeridianUpper: true,
      showTimezone: true,
    })
  }

  get dropoff(): string {
    let end = null
    const stops = this.state.trip?.stops

    if (!this.state.trip) {
      return ''
    }

    const finalStop = stops[stops.length - 1]

    if (this.state.trip.garageTimes?.returnTime) {
      end = this.state.trip.garageTimes.returnTime
    } else {
      end = finalStop?.dropoffDatetime
    }

    return end || ''
  }

  get formattedDropoff(): string {
    let tz = null
    if (!this.state.trip) {
      return ''
    }

    const stops = this.state.trip?.stops
    const finalStop = stops[stops.length - 1]
    if (this.state.trip?.garageTimes?.returnTime) {
      tz = this.state.trip?.garageTimes.returnGarage?.address?.timeZone
    } else {
      tz = finalStop?.address?.timeZone
    }

    if (!this.dropoff || !tz) {
      return ''
    }
    return this.formatLongDateShortTime(this.dropoff, {
      tz,
      showMeridianUpper: true,
      showTimezone: true,
    })
  }
}
