
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { SimpleTableColumn } from '@/models/SimpleTableColumn'
import { currencyFilter, numberWithCommas } from '@/utils/string'
import {
  VehicleDailyRate,
  VehicleHourlyRate,
  VehicleMileageRate,
} from '@/models/VehicleRate'
import {
  vehicleDailyRates,
  vehicleHourlyRates,
  vehicleMileageRates,
} from '@/utils/pricing'
import CUSimpleTable from '@/components/CUSimpleTable.vue'
import { TripPricings } from '@/models/TripPricings'
import vclient from '@/services/vehicle'
@Component({
  components: { CUSimpleTable },
})
export default class TripPricingCalculationsSidebar extends Vue {
  @Prop({ required: true }) readonly tripPricings!: TripPricings
  loading = false
  dailyRates: VehicleDailyRate[] = []
  hourlyRates: VehicleHourlyRate[] = []
  mileageRates: VehicleMileageRate[] = []

  @Watch('tripPricings', { deep: true, immediate: true })
  async onPricingsChange(tripPricings: TripPricings): Promise<void> {
    // pricingVehicleIdMap is a map of the pricingVehicle index
    // to the vehicleId associated with it (if any).
    // Get all the vehicle ids, and fetch their names, then
    // create a map of vehicleId : vehicleName
    const { pricingVehicleIdMap } = tripPricings.pricingHourly
    const vehicleIds = Object.values(pricingVehicleIdMap)
    const vehicleIdNameMap = {}

    const addToVehicleNameMap = async (vehicleId) => {
      const { data } = await vclient.byId(vehicleId)
      vehicleIdNameMap[vehicleId] = data.vehicleName
    }

    const promises = vehicleIds.map((id) => addToVehicleNameMap(id))
    await Promise.all(promises)

    // Pass this map of vehicleId to vehicleName into our
    // pricing parsing functions
    this.hourlyRates = vehicleHourlyRates(
      tripPricings.pricingHourly,
      vehicleIdNameMap
    )
    this.dailyRates = vehicleDailyRates(
      tripPricings.pricingDaily,
      vehicleIdNameMap
    )
    this.mileageRates = vehicleMileageRates(
      tripPricings.pricingMileage,
      vehicleIdNameMap
    )
  }

  vehicleFormatter<T extends { vehicle: string; quantity: number }>(
    row: T
  ): string {
    return row.quantity > 1 ? `${row.vehicle} (${row.quantity})` : row.vehicle
  }

  mileageRateColumns: SimpleTableColumn<VehicleMileageRate>[] = [
    {
      text: 'Vehicle',
      value: 'vehicle',
      type: 'text',
      width: '14%',
      formatter: this.vehicleFormatter,
    },
    {
      text: 'Dead Miles',
      value: 'deadMiles',
      type: 'number',
      width: '12%',
      formatter: (row: VehicleMileageRate): string =>
        currencyFilter(row.deadMiles),
    },
    {
      text: 'Quoted (mi)',
      value: 'deadMilesQuoted',
      type: 'number',
      width: '12%',
      formatter: (row: VehicleMileageRate): string =>
        numberWithCommas(row.deadMilesQuoted),
    },
    {
      text: 'Live Miles',
      value: 'liveMiles',
      type: 'number',
      width: '12%',
      formatter: (row: VehicleMileageRate): string =>
        currencyFilter(row.liveMiles),
    },
    {
      text: 'Quoted (mi)',
      value: 'liveMilesQuoted',
      type: 'number',
      width: '12%',
      formatter: (row: VehicleMileageRate): string =>
        numberWithCommas(row.liveMilesQuoted),
    },
    {
      text: 'Markup',
      value: 'markup',
      type: 'number',
      width: '10%',
      formatter: (row: VehicleMileageRate): string => `${row.markup}%`,
    },
    {
      text: 'Markup Name',
      value: 'name',
      type: 'text',
      width: '18%',
    },
    {
      text: 'Base Fare',
      value: 'baseFare',
      type: 'number',
      width: '10%',
      class: 'font-bold text-black',
      cellClass: 'font-bold',
      formatter: (row: VehicleMileageRate): string =>
        currencyFilter(row.baseFare),
    },
  ]

  hourlyRateColumns: SimpleTableColumn<VehicleHourlyRate>[] = [
    {
      text: 'Vehicle',
      value: 'vehicle',
      type: 'text',
      width: '26%',
      formatter: this.vehicleFormatter,
    },
    {
      text: 'Rate',
      value: 'rate',
      type: 'number',
      width: '12%',
      formatter: (row: VehicleHourlyRate): string => currencyFilter(row.rate),
    },
    {
      text: 'Minimum (hr)',
      value: 'minimum',
      type: 'number',
      width: '12%',
      formatter: (row: VehicleHourlyRate): string => row.minimum.toFixed(2),
    },
    {
      text: 'Quoted (hr)',
      value: 'quoted',
      type: 'number',
      width: '12%',
      formatter: (row: VehicleHourlyRate): string => row.quoted.toFixed(2),
    },
    {
      text: 'Markup',
      value: 'markup',
      type: 'number',
      width: '10%',
      formatter: (row: VehicleDailyRate): string => `${row.markup}%`,
    },
    {
      text: 'Markup Name',
      value: 'name',
      type: 'text',
      width: '18%',
    },
    {
      text: 'Base Fare',
      value: 'baseFare',
      type: 'number',
      width: '10%',
      class: 'font-bold text-black',
      cellClass: 'font-bold',
      formatter: (row: VehicleDailyRate): string =>
        currencyFilter(row.baseFare),
    },
  ]

  dailyRateColumns: SimpleTableColumn<VehicleDailyRate>[] = [
    {
      text: 'Vehicle',
      value: 'vehicle',
      type: 'text',
      width: '38%',
      formatter: this.vehicleFormatter,
    },
    {
      text: 'Rate',
      value: 'rate',
      type: 'number',
      width: '12%',
      formatter: (row: VehicleDailyRate): string => currencyFilter(row.rate),
    },
    {
      text: 'Quoted (day)',
      value: 'quoted',
      type: 'number',
      width: '12%',
      formatter: (row: VehicleDailyRate): string => row.quoted.toFixed(2),
    },
    {
      text: 'Markup',
      value: 'markup',
      type: 'number',
      width: '10%',
      formatter: (row: VehicleDailyRate): string => `${row.markup}%`,
    },
    {
      text: 'Markup Name',
      value: 'name',
      type: 'text',
      width: '18%',
    },
    {
      text: 'Base Fare',
      value: 'baseFare',
      type: 'number',
      width: '10%',
      class: 'font-bold text-black',
      cellClass: 'font-bold',
      formatter: (row: VehicleDailyRate): string =>
        currencyFilter(row.baseFare),
    },
  ]
}
