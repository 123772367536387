
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class CULabelField extends Vue {
  @Prop({ required: false, default: null }) readonly value!: string
  @Prop({ required: false, default: true }) readonly dense!: boolean
  text = ''

  handleClear(): void {
    this.text = ''
    this.$emit('blur')
  }

  handleConfirm(): void {
    this.$emit('input', this.text)
    this.handleClear()
  }

  mounted(): void {
    this.text = this.value
  }
}
