
import reservation from '@/store/modules/reservation'
import { Prop } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { currencyFilter } from '@/utils/string'
import dayjs from 'dayjs'

@Component({})
export default class ReservationDriverPayFooter extends mixins(DateMixin) {
  @Prop({ required: true }) invoice: any
  @Prop({ required: false, default: null }) paystub: any
  @Prop({ required: true }) total: number
  @Prop({ required: true }) isReviewed: boolean
  @Prop({ required: true }) isPaid: boolean
  @Prop({ required: true }) isPending: boolean
  @Prop({ required: true }) isSaving: boolean
  @Prop({ required: true }) isDirty: boolean
  @Prop({ required: true }) isOriginal: boolean

  currencyFilter = currencyFilter
  state = reservation

  get driverName(): string {
    return this.invoice.driver?.name || ''
  }

  get paidOn(): string {
    if (!this.paystub.paidDate) {
      return '--'
    }
    return this.formatLongDate(this.paystubDetails.paidDate)
  }

  get paystubDetails(): any {
    if (!this.paystub) {
      return {}
    }
    return {
      name: this.paystub.label,
      paidDate: this.paystub.paidDate,
      total: this.paystub.total,
    }
  }
}
