import { apiBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { ApiResult } from '@/models/dto'
import { Ticket, TicketDetailEntity } from '@/models/dto/Ticket'

const httpService: HttpService = new HttpService()

export default {
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<Ticket>>> {
    const { sorts = null, filters = null, pageSize = 10, page = 1 } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)
    const host = apiBaseUrl()

    return httpService.get(`https://${host}/tables/tickets?${query}`)
  },
  byId(ticketId: number): Promise<AxiosResponse<TicketDetailEntity>> {
    return httpService.get(`https://${apiBaseUrl()}/v2/tickets/${ticketId}`)
  },
  create(ticket: TicketDetailEntity): Promise<AxiosResponse<number>> {
    return httpService.post(`https://${apiBaseUrl()}/v2/tickets`, ticket)
  },
  update(ticket: TicketDetailEntity): Promise<AxiosResponse<void>> {
    return httpService.put(
      `https://${apiBaseUrl()}/v2/tickets/${ticket.ticketId}`,
      ticket
    )
  },
  partialUpdate(
    ticket: Partial<TicketDetailEntity>
  ): Promise<AxiosResponse<void>> {
    return httpService.patch(
      `https://${apiBaseUrl()}/v2/tickets/${ticket.ticketId}`,
      ticket
    )
  },
  delete(ticketId: number): Promise<AxiosResponse<ApiResult>> {
    return httpService.delete(`https://${apiBaseUrl()}/v2/tickets/${ticketId}`)
  },
}
