
import { Vue, Component, Prop } from 'vue-property-decorator'
import sidebar from '@/store/modules/sidebar'
import CUSidebarContent from '@/layouts/CUSidebarContent.vue'
import CUSidebarContentWithFooterButtons from '@/components/CUSidebarContentWithFooterButtons.vue'
import customerClient from '@/services/customer'
import { Customer } from '@/models/dto/Customer'
import auth from '@/store/modules/auth'
import quickbooksService from '@/services/quickbooks'
import rstate from '@/store/modules/reservation'
import CUUserCard from './CUUserCard.vue'
import CUQuickbooksCustomerSync from './CUQuickbooksCustomerSync.vue'
import { QuickbooksCustomerAssignRequest } from '@/models/dto/Quickbooks'
import { EventBus } from '@/utils/eventBus'
import QuickbooksReservationDetailPanel from './QuickbooksReservationDetailPanel.vue'
import HoldUpModal from './HoldUpModal.vue'
import { SourceCategory } from '@/utils/enum'
import app from '@/store/modules/app'

@Component({
  components: {
    CUUserCard,
    CUQuickbooksCustomerSync,
    CUSidebarContent,
    CUSidebarContentWithFooterButtons,
    HoldUpModal,
  },
})
export default class SyncToQuickbooksCustomerSidebar extends Vue {
  @Prop({ required: false, default: false }) readonly changeCustomer!: boolean
  @Prop({ required: false, default: false }) readonly userId!: number

  user: Customer = null
  quickbooksCustomerId: string = null
  quickbooksContacts = []
  createNewQuickbooksCustomer = false
  auth = auth
  submitting = false
  loading = false
  originalQuickbooksCustomerId = null
  showCustomerCreationOverrideModal = false
  quickBooksError = false

  get confirmQBCustomerCreationText(): string {
    let displayName
    if (this.user?.customerAccountName) {
      displayName = this.user.customerAccountName
    } else if (this.user?.firstName && this.user?.lastName) {
      displayName = `${this.user?.firstName} ${this.user?.lastName}`
    } else if (this.user?.firstName) {
      displayName = `${this.user?.firstName}`
    } else if (this.userId === Number(app.charterupReferralUserId)) {
      displayName = 'CharterUp'
    }
    return `Your QuickBooks account already has a customer with this name. A new customer with name <strong>${displayName} (#)</strong> will be created instead and synced to this Busify contact.`
  }

  get isReferral(): boolean {
    return rstate?.reservation?.sourceCategory === SourceCategory.REFERRAL
  }

  cancel(): void {
    sidebar.pop()
  }

  async submit(): Promise<void> {
    if (!this.quickbooksCustomerId && !this.createNewQuickbooksCustomer) {
      this.quickBooksError = true
      return
    }
    this.submitting = true
    if (this.createNewQuickbooksCustomer) {
      try {
        const response =
          await quickbooksService.createQuickbooksCustomerWithBusifyContactId(
            auth.getCompanyId,
            this.userId
          )
        EventBus.$emit('snackbar:success', 'Customer sync successful')
        this.quickbooksCustomerId =
          response?.data?.customerSync?.quickbooksCustomerId || null
        this.createNewQuickbooksCustomer = false
        this.fetchQuickBooksContacts()
      } catch (e: any) {
        if (
          e.response.data.status === 'BAD_REQUEST' &&
          (e.response.data.message.includes('Duplicate Name') ||
            e.response.data.message.includes('already exists'))
        ) {
          this.showCustomerCreationOverrideModal = true
          this.submitting = false
          return
        }
        console.log(e)
        EventBus.$emit(
          'snackbar:error',
          'Unable to create new QuickBooks Customer'
        )
      }
    } else if (
      this.quickbooksCustomerId &&
      this.quickbooksCustomerId !== this.originalQuickbooksCustomerId
    ) {
      try {
        const request: QuickbooksCustomerAssignRequest = {
          busifyId: this.userId,
          qboId: this.quickbooksCustomerId,
        }
        await quickbooksService.assignQuickbooksCustomer(
          auth.getCompanyId,
          request
        )
        EventBus.$emit('snackbar:success', 'Customer sync successful')
      } catch (e) {
        console.log(e)
        EventBus.$emit(
          'snackbar:error',
          'Unable to connect contact to Quickbooks Customer'
        )
        this.submitting = false
        return
      }
    }
    if (!this.quickbooksCustomerId) {
      this.submitting = false
      return
    }
    if (!this.changeCustomer) {
      await this.createInvoice()
    } else {
      await this.updateInvoiceCustomer()
    }
    rstate.fetchReservationInvoiceDetails()
    sidebar.popAllAndPush({
      component: QuickbooksReservationDetailPanel,
      width: 764,
      wide: true,
      persist: true,
    })
    this.submitting = false
  }

  async overrideCustomerCreation(): Promise<void> {
    this.submitting = true
    try {
      console.log('1')
      const response =
        await quickbooksService.createQuickbooksCustomerWithBusifyContactId(
          auth.getCompanyId,
          this.userId,
          true
        )
      this.quickbooksCustomerId =
        response?.data?.customerSync?.quickbooksCustomerId || null
      this.createNewQuickbooksCustomer = false
      this.fetchQuickBooksContacts()
    } catch (e) {
      console.log(e)
      EventBus.$emit(
        'snackbar:error',
        'Unable to create new Quickbooks Customer'
      )
    }
    if (!this.changeCustomer) {
      await this.createInvoice()
    } else {
      await this.updateInvoiceCustomer()
    }
    rstate.fetchReservationInvoiceDetails()
    sidebar.popAllAndPush({
      component: QuickbooksReservationDetailPanel,
      width: 764,
      wide: true,
      persist: true,
    })
    this.submitting = false
  }

  async updateInvoiceCustomer(): Promise<void> {
    try {
      await quickbooksService.updateQuickbooksCustomerForInvoice(
        auth.getCompanyId,
        rstate.reservationInvoiceDetails.invoiceId
      )
      EventBus.$emit('snackbar:success', 'Customer update successful')
    } catch (e) {
      console.log(e)
      EventBus.$emit(
        'snackbar:error',
        'Unable to connect Invoice to New Quickbooks Customer'
      )
    }
  }

  async createInvoice(): Promise<void> {
    try {
      await quickbooksService.createInvoiceFromReservations(
        auth.getCompanyId,
        rstate.reservation.reservationId
      )
      EventBus.$emit('snackbar:success', 'Invoice creation successful')
    } catch (e) {
      console.log(e)
      EventBus.$emit(
        'snackbar:error',
        'Unable to create QuickBooks Invoice from Reservation'
      )
    }
  }

  handleQuickbooksSync({
    sync,
    contactId,
    createNewCustomer = false,
  }: {
    sync: boolean
    contactId: string
    createNewCustomer?: boolean
  }): void {
    this.quickBooksError = false
    if (sync) {
      if (createNewCustomer) {
        this.createNewQuickbooksCustomer = true
        this.quickbooksCustomerId = null
        return
      }
      this.createNewQuickbooksCustomer = false
      this.quickbooksCustomerId = contactId
      return
    }
    this.createNewQuickbooksCustomer = false
    this.quickbooksCustomerId = null
  }

  async fetchQuickBooksContacts(): Promise<void> {
    try {
      const response = await quickbooksService.getCompanyQuickbooksCustomers(
        auth.getCompanyId
      )
      const customers = response.data.customers
      this.quickbooksContacts = [
        ...customers.map((contact) => {
          return {
            text: `${contact.name} (ID: ${contact.id})`,
            value: contact.id,
          }
        }),
      ]
    } catch (e) {
      console.log(e)
    }
  }

  async created(): Promise<void> {
    const userId = rstate?.reservation?.customerId
    if (userId) {
      try {
        let customerPromise
        if (!this.isReferral) {
          customerPromise = customerClient.byId(userId)
        }

        let quickbooksPromise = null
        if (auth.isQuickbooksIntegrationEnabled) {
          quickbooksPromise = quickbooksService.getContactQuickbooksDetail(
            auth.getCompanyId,
            userId
          )
        }

        const quickbooksContactsPromise = this.fetchQuickBooksContacts()

        const [customerResult, quickbooksResult, quickbooksContactsResult] =
          await Promise.allSettled([
            customerPromise,
            quickbooksPromise,
            quickbooksContactsPromise,
          ])

        if (customerResult.status === 'fulfilled') {
          this.user = customerResult.value?.data?.customer
        } else {
          console.log('Error with customer fetch:', customerResult.reason)
        }

        if (quickbooksResult.status === 'fulfilled') {
          this.quickbooksCustomerId =
            quickbooksResult.value?.data?.customerSync?.quickbooksCustomerId
          this.originalQuickbooksCustomerId = this.quickbooksCustomerId
        } else {
          console.log('Error with Quickbooks fetch:', quickbooksResult.reason)
        }

        if (quickbooksContactsResult.status === 'fulfilled') {
          // handle quickbooksContactsResult.value if needed
        } else {
          console.log(
            'Error with Quickbooks contacts fetch:',
            quickbooksContactsResult.reason
          )
        }
      } catch (e) {
        console.log('Unexpected error:', e)
      }
    }
    this.loading = false
  }
}
