
import { Component, Vue } from 'vue-property-decorator'
import reservation from '@/store/modules/reservation'
import { groupBy } from '@/utils/reducers'
import { ReservationNotification } from '@/models/dto'
import { ContactType, ReservationNotificationType } from '@/utils/enum'
import client from '@/services/reservation'
import { NotificationsSetting } from '@/models/NotificationsSetting'
import { formatFullName } from '@/utils/string'

@Component
export default class ReservationNotifications extends Vue {
  state = reservation
  isLoading = false

  // Driver On The Way Email
  get driverOnTheWay(): ReservationNotification[] {
    return this.notifications[ReservationNotificationType.DriverOnTheWay] || []
  }

  handleUpdateDriverOnTheWay(value: boolean, idx: number): void {
    this.notifications[ReservationNotificationType.DriverOnTheWay][
      idx
    ].isActive = value
    this.handleUpdate()
  }

  get driverInfo(): ReservationNotification[] {
    return this.notifications[ReservationNotificationType.DriverInfo] || []
  }

  handleUpdateDriverInfo(value: boolean, idx: number): void {
    this.notifications[ReservationNotificationType.DriverInfo][idx].isActive =
      value
    this.handleUpdate()
  }

  get notifications(): any {
    if (!this.state?.reservation?.notifications) {
      return {}
    }
    return this.state.reservation.notifications
      .filter((notif) => this.doesResContactExistForNotificationType(notif))
      .reduce(groupBy('reservationNotificationType'), {})
  }

  doesResContactExistForNotificationType(
    notif: ReservationNotification
  ): boolean {
    const isBooking = notif.contactType === ContactType.Booking
    const isBillingAndContactExists =
      notif.contactType === ContactType.Billing &&
      !!this.state.reservation.billingCustomerId
    const isTripAndContactExists =
      notif.contactType === ContactType.Trip && !!this.state.trip.tripContactId
    return isBooking || isBillingAndContactExists || isTripAndContactExists
  }

  get operatorNotifications(): NotificationsSetting {
    return (
      this.state?.reservation?.company?.notificationsSetting || {
        defaultSendDriverInfoEmail: true,
        defaultSendDriverOnTheWayEmail: true,
      }
    )
  }

  get isOnTheWayDisabled(): boolean {
    return (
      this.state?.reservation?.reservationStatus?.toLowerCase() === 'started' ||
      this.operatorNotifications.defaultSendDriverOnTheWayEmail === false
    )
  }

  get isDriverInfoDisabled(): boolean {
    if (this.state?.reservation?.startDate === undefined) {
      return false
    }

    const start = new Date(this.state.reservation.startDate)
    const now = new Date()

    const dayBeforeStartAt4PM = new Date(start)
    dayBeforeStartAt4PM.setHours(16, 0, 0, 0)
    dayBeforeStartAt4PM.setDate(start.getDate() - 1)

    if (now >= dayBeforeStartAt4PM) {
      return true
    }

    return this.operatorNotifications.defaultSendDriverInfoEmail === false
  }

  getContactString(type: number): string {
    switch (type) {
      case ContactType.Booking:
        return `${this.state.reservation.customerName} (Booking)`
      case ContactType.Billing:
        return `${this.state.reservation.billingCustomerName} (Billing)`
      case ContactType.Trip:
        return `${formatFullName(this.state.trip.tripContact)} (Trip)`
    }
  }

  async handleUpdate(): Promise<void> {
    try {
      this.isLoading = true
      await client.updateReservationNotifications(
        this.state.reservation.reservationId,
        [
          ...this.notifications[ReservationNotificationType.DriverInfo],
          ...this.notifications[ReservationNotificationType.DriverOnTheWay],
        ]
      )
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }
}
