
import { PaymentTransaction } from '@/models/dto/PaymentTransaction'
import auth from '@/store/modules/auth'
import dayjs from 'dayjs'
import { Prop } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'

@Component({})
export default class SimpleTableCreatedCell extends mixins(DateMixin) {
  @Prop({ required: true, default: null }) item: PaymentTransaction

  // TODO: surface on backend
  get createdByInitials(): string {
    return 'CB'
  }

  get createdOnDateLabel(): string {
    return this.formatShortDateLongTime(this.item.createdOn, {
      tz: auth.getUserTimeZone,
      showMeridianUpper: true,
    })
  }
}
