
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator'
import AutocompleteAddress from '@/components/AutocompleteAddress.vue'
import { Address, Stop, StopErrors } from '@/models/dto'
import {
  timeLabel,
  spotTimeOffsetList,
  formatSecondsToDuration,
} from '@/utils/time'
import dayjs from 'dayjs'
import CULabelField from '@/components/CULabelField.vue'
import ErrorLabel from '@/components/ErrorLabel.vue'
import {
  StopEstimation,
  TripEstimation,
  TripStopTypeKey,
} from '@/models/dto/Trip'
import sidebar from '@/store/modules/sidebar'
import StopNotesSidebar from './StopNotesSidebar.vue'
import { Note } from '@/models/dto/Note'
import auth from '@/store/modules/auth'
import StopCardAutocompleteAddress from './StopCardAutocompleteAddress.vue'
import { getMinDate, getMaxDate } from '@/utils/getDates'
import quote from '@/store/modules/quote'
import reservation from '@/store/modules/reservation'
import { ref } from 'vue'

@Component({
  components: {
    ErrorLabel,
    CULabelField,
    AutocompleteAddress,
    StopCardAutocompleteAddress,
  },
})
export default class TripDetailsStopCard extends Vue {
  @Prop({ required: true }) readonly value!: Stop
  @Prop({ required: false, default: false }) readonly permanent!: boolean
  @Prop({ required: false, default: false })
  readonly canCalculateArrival!: boolean
  @Prop({ required: false, type: Boolean })
  readonly isFirstStop!: boolean
  @Prop({ required: false, type: Boolean })
  readonly isLastStop!: boolean
  @Prop({ required: false, type: Boolean })
  readonly readOnly!: boolean
  @Prop({ required: false, type: Boolean })
  readonly isReferral!: boolean
  @Prop({ default: false, type: Boolean })
  readonly error!: boolean
  @Prop({ default: false, type: Boolean })
  readonly isDragActive!: boolean
  @Prop({ default: undefined, type: String })
  readonly defaultDate!: string
  @Prop({ default: null, required: false })
  readonly defaultSpotTimeOffset: number
  @Prop({ required: false }) readonly tripEstimation!: TripEstimation

  @Prop({ default: () => ({}), type: Object })
  readonly errorObject: StopErrors

  @Inject({ default: false }) isReservationTrip: boolean

  mouseOver = false
  editNote = false
  editSpotTime = false
  editStopLabel = false
  timeLabel = timeLabel
  spotTimeOffsetList = spotTimeOffsetList
  spotTimeOffset = null
  stopType: TripStopTypeKey = null
  disableAddSpotTime = true
  localDefaultSpotTimeOffset = null
  formatSecondsToDuration = formatSecondsToDuration

  @Watch('value.spotTime', { deep: true })
  handleUpdateSpotTime(): void {
    if (this.value.spotTime?.spotTime) {
      this.spotTimeOffset = dayjs(this.pickupDatetime).diff(
        this.value.spotTime.spotTime,
        'minutes'
      )
    } else {
      this.spotTimeOffset = null
      this.editSpotTime = false
    }
  }

  @Watch('value.addressId')
  onAddressChange(): void {
    this.loadStopType()
  }

  get timeZone(): string {
    return this.value?.address?.timeZone || auth.getUserTimeZone
  }

  get topRowStopTypeSelectValue(): string {
    if (this.stopType === 'pickup_only') {
      return 'Pickup'
    }
    return 'Dropoff'
  }

  get shouldDisableTopRowStopTypeSelect(): boolean {
    if (this.isFirstStop || this.isLastStop) {
      return true
    }
    return false
  }

  get isPickup(): boolean {
    return !!this.value.pickupDatetime || this.value.orderIndex === 0
  }

  get firstStopLabel(): string {
    if (this.value?.orderIndex === 0) {
      return this.lg ? 'Garage' : 'G'
    } else {
      return this.lg
        ? `Stop ${this.value?.orderIndex}`
        : `S ${this.value?.orderIndex}`
    }
  }

  get spotTime(): string {
    const spotTime = this.value.spotTime?.spotTime
    return !spotTime ? '' : dayjs(spotTime).tz(this.timeZone).format('HH:mm')
  }

  get timeFromPreviousStop(): StopEstimation {
    const index = this.value.orderIndex
    const timeFromPrevious =
      index === 0
        ? this.tripEstimation?.garageEstimations?.[index]
        : this.tripEstimation?.stopEstimations?.[index - 1]

    return timeFromPrevious
  }

  getTotalEstimationTime(index: number): number {
    const { tripEstimation, isReservationTrip } = this

    const trip = isReservationTrip
      ? reservation.trip
      : quote.trips?.find(({ tripId }) => tripId === tripEstimation.tripId)

    let seconds = 0

    for (let i = 0; i <= index; i++) {
      const previousStop =
        i === 0
          ? trip?.garageTimes?.departureTime
          : getMaxDate(
              trip?.stops[i - 1].spotTime?.spotTime,
              trip?.stops[i - 1].pickupDatetime,
              trip?.stops[i - 1].dropoffDatetime
            )
      const currentStop = getMinDate(
        trip?.stops[i].spotTime?.spotTime,
        trip?.stops[i].pickupDatetime,
        trip?.stops[i].dropoffDatetime
      )

      seconds +=
        previousStop && currentStop
          ? dayjs(currentStop).diff(previousStop)
          : null
    }

    return seconds / 1000
  }

  get showFirstStopEstimation(): boolean {
    return !!(
      this.tripEstimation?.garageEstimations?.[0]?.timeFromPrevious ||
      this.value.orderIndex !== 0
    )
  }

  get addressName(): string {
    return this.value.address?.addressName || this.value.address?.name || ''
  }

  get address(): Address {
    return { ...this.value.address, addressName: this.addressName }
  }

  get title(): string {
    return this.address?.title || ''
  }

  get isPickupOnly(): boolean {
    return this.stopType === 'pickup_only'
  }

  get isDropoffOnly(): boolean {
    return this.stopType === 'dropoff_only'
  }

  get isDropoffAndPickup(): boolean {
    return this.stopType === 'dropoff_and_pickup'
  }

  get pickupDatetime(): string {
    return this.value.pickupDatetime || ''
  }

  get dropoffDatetime(): string {
    return this.value.dropoffDatetime || ''
  }

  get pickupDate(): string {
    const ts = this.value?.pickupDate
    return ts ? dayjs(ts).format('YYYY-MM-DD') : ''
  }

  get dropoffDate(): string {
    const ts = this.value?.dropoffDate
    return ts ? dayjs(ts).format('YYYY-MM-DD') : ''
  }

  get pickupTime(): string {
    const ts = this.value?.pickupTime
    return ts ? dayjs(ts, 'HH:mm').format('HH:mm') : ''
  }

  get dropoffTime(): string {
    const ts = this.value?.dropoffTime
    return ts ? dayjs(ts, 'HH:mm').format('HH:mm') : ''
  }

  get notes(): string {
    const referralNotes = this.value.stopNotes?.map((note) => note.html)
    return this.value.notes || referralNotes?.join(' ')
  }

  get defaultPickerDate(): string {
    if (this.pickupDate || this.dropoffDate) {
      return this.pickupDate || this.dropoffDate
    }

    return this.defaultDate
  }
  get isAddressError(): boolean {
    return this.errorObject.address
  }

  get isPickupDateError(): boolean {
    if (!this.errorObject.pickup) {
      return false
    }
    const { missingDate, dateOutOfOrder } = this.errorObject.pickup
    return missingDate || dateOutOfOrder
  }

  get isPickupTimeError(): boolean {
    if (!this.errorObject.pickup) {
      return false
    }
    const { missingTime, dateOutOfOrder, timeOutOfOrder } =
      this.errorObject.pickup
    return missingTime || dateOutOfOrder || timeOutOfOrder
  }

  get isDropoffDateError(): boolean {
    if (!this.errorObject.dropoff) {
      return false
    }
    const { missingDate, dateOutOfOrder } = this.errorObject.dropoff
    return missingDate || dateOutOfOrder
  }

  get isDropoffTimeError(): boolean {
    if (!this.errorObject.dropoff) {
      return false
    }
    const { missingTime, dateOutOfOrder, timeOutOfOrder } =
      this.errorObject.dropoff
    return missingTime || dateOutOfOrder || timeOutOfOrder
  }

  get pickupDateErrorMessages(): string[] {
    if (!this.errorObject?.pickup) {
      return []
    }
    const { dateOutOfOrder, missingDate } = this.errorObject.pickup

    if (missingDate) {
      return ['Date required']
    }
    if (dateOutOfOrder) {
      return ['Out of order']
    }

    return []
  }

  get pickupTimeErrorMessages(): string[] {
    if (!this.errorObject?.pickup) {
      return []
    }

    const { missingTime, dateOutOfOrder, timeOutOfOrder } =
      this.errorObject.pickup

    if (missingTime) {
      return ['Time required']
    }

    if (timeOutOfOrder && !dateOutOfOrder) {
      return ['Out of order']
    }

    return []
  }

  get dropoffDateErrorMesssages(): string[] {
    if (!this.errorObject?.dropoff) {
      return []
    }
    const { dateOutOfOrder, missingDate } = this.errorObject.dropoff

    if (missingDate) {
      return ['Date required']
    }

    if (dateOutOfOrder) {
      return ['Out of order']
    }

    return []
  }

  get dropoffTimeErrorMessages(): string[] {
    if (!this.errorObject?.dropoff) {
      return []
    }

    const { missingTime, dateOutOfOrder, timeOutOfOrder } =
      this.errorObject.dropoff

    if (missingTime) {
      return ['Time required']
    }

    if (timeOutOfOrder && !dateOutOfOrder) {
      return ['Out of order']
    }

    return []
  }

  get isSpotTimeError(): boolean {
    return (
      this.errorObject?.spotTime?.isBeforeDropoff ||
      this.errorObject?.spotTime?.isBeforePrevStop
    )
  }

  get spotTimeErrorMessages(): string[] {
    if (this.errorObject?.spotTime?.isBeforeDropoff) {
      return ['Out of order']
    }
    if (this.errorObject?.spotTime?.isBeforePrevStop) {
      return ['Out of order']
    }
    return []
  }

  get hideStopCardMargin(): boolean {
    return this.readOnly && !this.isReferral
  }

  get timeFromPrevious(): string {
    const { tripEstimation, value, isReservationTrip } = this
    const { orderIndex } = value || {}

    const trip = isReservationTrip
      ? reservation.trip
      : quote.trips?.find(({ tripId }) => tripId === tripEstimation.tripId)

    const currentStop = getMinDate(
      value.spotTime?.spotTime,
      value.pickupDatetime,
      value.dropoffDatetime
    )
    const previousStop =
      orderIndex === 0
        ? trip?.garageTimes?.departureTime
        : getMaxDate(
            trip?.stops[orderIndex - 1].spotTime?.spotTime,
            trip?.stops[orderIndex - 1].pickupDatetime,
            trip?.stops[orderIndex - 1].dropoffDatetime
          )

    const seconds =
      previousStop && currentStop ? dayjs(currentStop).diff(previousStop) : null

    return formatSecondsToDuration(seconds / 1000)
  }

  addSpotTime(): void {
    this.editSpotTime = true
    if (this.localDefaultSpotTimeOffset) {
      this.spotTimeOffset = this.localDefaultSpotTimeOffset
      this.handleSpotTimeOffsetChange(this.localDefaultSpotTimeOffset)
    }
  }

  calculateArrivalTime(stopType: TripStopTypeKey): void {
    if (!this.spotTimeOffset) {
      this.spotTimeOffset = 50000
    }
    this.spotTimeOffset = 50000
    this.$emit(
      'calculate-arrival-time',
      this.value,
      stopType,
      this.localDefaultSpotTimeOffset
    )
    this.$emit('stop-card:reset-errors')
  }

  handleSavedStopNotesChange(notes: string): void {
    const updatedNote = new Note()
    updatedNote.html = notes
    updatedNote.note = notes
    this.$emit('input-silent', { ...this.value, stopNotes: [updatedNote] })
  }

  handleAddNotes(): void {
    const note =
      this.value?.stopNotes?.[0] || new Note({ note: this.value?.notes })
    sidebar.push({
      component: StopNotesSidebar,
      props: {
        id: this.value.stopId,
        note,
      },
      on: {
        submit: (note: Note) =>
          this.$emit('input-silent', { ...this.value, stopNotes: [note] }),
      },
    })
  }

  handleCardMouseEnter(): void {
    if (this.readOnly) {
      return
    }
    this.mouseOver = true
    if (this.notes) {
      this.editNote = true
    }
    if (this.value.spotTime) {
      this.editSpotTime = true
    }
  }

  handleCardMouseLeave(): void {
    this.mouseOver = false
    this.editNote = false
  }

  // Whenever we update the address, set the pickupDate/dropoffDate/spotTime
  // to be in the new timeZone of that address, adjusted for local time
  // If there is no new timeZone (e.g. we're clearing the address), then
  // use the timeZone set for the current user

  // E.g. we have Los Angeles as the address a stop, and a pickup time of 3PM PST
  // The user's timezone is EST. When we clear the address, set the pickup time
  // to be 3PM EST. If we add a new address of Chicago, set the pickup time to be
  // 3PM CST.
  handleAddressChange(address: Address): void {
    const oldTimeZone = this.value.address?.timeZone || auth.getUserTimeZone
    const newTimeZone = address?.timeZone || auth.getUserTimeZone

    let updatedStop = { ...this.value, address }
    if (this.value.pickupDatetime) {
      const pickupCalendar = dayjs(this.value.pickupDatetime)
        .tz(oldTimeZone)
        .tz(newTimeZone, true)

      updatedStop = {
        ...updatedStop,
        pickupDatetime: pickupCalendar.toISOString(),
        pickupDate: pickupCalendar.format('YYYY-MM-DD'),
        pickupTime: pickupCalendar.format('HH:mm'),
      }
    }

    if (this.value.dropoffDatetime) {
      const dropoffCalendar = dayjs(this.value.dropoffDatetime)
        .tz(oldTimeZone)
        .tz(newTimeZone, true)

      updatedStop = {
        ...updatedStop,
        dropoffDatetime: dropoffCalendar.toISOString(),
        dropoffDate: dropoffCalendar.format('YYYY-MM-DD'),
        dropoffTime: dropoffCalendar.format('HH:mm'),
      }
    }

    if (this.value.spotTime?.spotTime) {
      const spotTime = dayjs(this.value.spotTime.spotTime)
        .tz(oldTimeZone)
        .tz(newTimeZone, true)
        .toISOString()

      updatedStop = {
        ...updatedStop,
        spotTime: { spotTime },
      }
    }

    this.$emit('input', updatedStop)
  }

  handleStopTitleChange(title: string): void {
    const address = { ...this.value.address, title }
    this.$emit('input', { ...this.value, address })
  }

  // Only getting called on middle stops that are of type
  // either dropoff_only or pickup_only; otherwise, the inputs
  // are disabled
  handleTopRowStopTypeChange(stopType: string): void {
    if (this.stopType === 'dropoff_and_pickup') {
      this.stopType = 'pickup_only'
      this.$emit('input', {
        ...this.value,
        dropoffDatetime: null,
        dropoffDate: null,
        dropoffTime: null,
      })
    } else if (stopType === 'Pickup') {
      this.stopType = 'pickup_only'
      this.$emit('input', {
        ...this.value,
        pickupDatetime: this.value.dropoffDatetime,
        pickupDate: this.value.dropoffDate,
        pickupTime: this.value.dropoffTime,
        dropoffDatetime: null,
        dropoffDate: null,
        dropoffTime: null,
      })
    } else if (stopType === 'Dropoff') {
      this.stopType = 'dropoff_only'
      this.editSpotTime = false
      this.spotTimeOffset = null
      this.$emit('input', {
        ...this.value,
        pickupDatetime: null,
        pickupDate: null,
        pickupTime: null,
        spotTime: null,
        dropoffDatetime: this.value.pickupDatetime,
        dropoffDate: this.value.pickupDate,
        dropoffTime: this.value.pickupTime,
      })
    }
  }

  handlePickupDateChange(dateString: string): void {
    if (!dateString) {
      const updatedStop = {
        ...this.value,
        pickupDatetime: '',
        pickupDate: '',
        pickupTime: '',
        spotTime: null,
      }
      this.$emit('input', updatedStop)
      this.$emit('stop-card:reset-errors')
      this.spotTimeOffset = null
      this.editSpotTime = false
      return
    }
    const { dateTimeISO, date } = this.dateToStopDateFormat(
      dateString,
      this.pickupTime
    )
    let updatedStop = {
      ...this.value,
      pickupDatetime: this.value.pickupTime ? dateTimeISO : '',
      pickupDate: date,
    }
    if (this.value.pickupTime && this.spotTimeOffset) {
      const dateClock = dayjs(dateTimeISO)
      const spotTime = dateClock.subtract(this.spotTimeOffset, 'minutes')
      updatedStop = {
        ...updatedStop,
        spotTime: { spotTime: spotTime.toISOString() },
      }
    }

    this.$emit('stop-card:reset-errors')
    this.$emit('input', updatedStop)
  }

  handleDropoffDateChange(dateString: string): void {
    if (!dateString) {
      this.$emit('stop-card:reset-errors')
      this.$emit('input', {
        ...this.value,
        dropoffDatetime: '',
        dropoffDate: '',
        dropoffTime: '',
      })
      return
    }

    const { dateTimeISO, date } = this.dateToStopDateFormat(
      dateString,
      this.dropoffTime
    )
    this.$emit('stop-card:reset-errors')
    this.$emit('input', {
      ...this.value,
      dropoffDatetime: this.value.dropoffTime ? dateTimeISO : '',
      dropoffDate: date,
    })
  }

  handlePickupTimeChange(timeString: string): void {
    if (!timeString) {
      return
    }

    const { time, dateTimeISO } = this.dateToStopTimeFormat(
      timeString,
      this.pickupDate
    )

    let updatedStop = {
      ...this.value,
      pickupDatetime: this.pickupDate ? dateTimeISO : '',
      pickupTime: time,
    }
    if (!this.spotTimeOffset) {
      this.spotTimeOffset = this.localDefaultSpotTimeOffset
    }
    if (this.value.pickupDate && this.spotTimeOffset) {
      const dateClock = dayjs(dateTimeISO)
      const spotTime = dateClock.subtract(this.spotTimeOffset, 'minutes')
      updatedStop = {
        ...updatedStop,
        spotTime: { spotTime: spotTime.toISOString() },
      }
    }

    this.$emit('stop-card:reset-errors')
    this.$emit('input', updatedStop)
  }

  handleDropoffTimeChange(timeString: string): void {
    if (!timeString) {
      return
    }

    const { time, dateTimeISO } = this.dateToStopTimeFormat(
      timeString,
      this.dropoffDate
    )

    this.$emit('stop-card:reset-errors')
    this.$emit('input', {
      ...this.value,
      dropoffDatetime: this.dropoffDate ? dateTimeISO : '',
      dropoffTime: time,
    })
  }

  getTotalDistanceFromPrevious(index: number): number {
    let totalDistance =
      this.tripEstimation?.garageEstimations?.[0]?.distanceFromPrevious || 0

    for (let i = 0; i <= index; i++) {
      const stopEstimation = this.tripEstimation?.stopEstimations[i]
      totalDistance += stopEstimation?.distanceFromPrevious
    }

    return Math.round(totalDistance)
  }

  dateToStopDateFormat(
    dateString: string,
    existingTime: string
  ): {
    dateTimeISO: string
    date: string
    time: string
  } {
    if (!dateString) {
      return
    }
    const dateCalendar = dayjs(dateString, 'YYYY-MM-DD')
    let timeClock = dayjs().tz(this.timeZone, true)
    if (existingTime) {
      timeClock = dayjs(existingTime, 'HH:mm')
    }

    const dateTime = dayjs()
      .set('year', dateCalendar.year())
      .set('month', dateCalendar.month())
      .set('date', dateCalendar.date())
      .set('hour', timeClock.hour())
      .set('minute', timeClock.minute())
      .tz(this.timeZone, true)

    // const parsedDatetime = dayjs(dateTime).tz(dayjs.tz.guess(), true)
    const date = dateTime.format('YYYY-MM-DD')
    const time = dateTime.format('HH:mm')
    const dateTimeISO = dateTime.toISOString()

    return { date, time, dateTimeISO }
  }

  dateToStopTimeFormat(
    timeString: string,
    existingDate: string
  ): {
    dateTimeISO: string
    date: string
    time: string
  } {
    const tz = this.timeZone
    const timeClock = dayjs(timeString, 'HH:mm')
    const ts = dayjs(existingDate || dayjs())
      .set('hour', timeClock.hour())
      .set('minute', timeClock.minute())
      .tz(tz, true) // All calculations to newPickupTime will be relative to tz

    const date = ts.format('YYYY-MM-DD')
    const time = ts.format('HH:mm')
    const dateTimeISO = ts.toISOString()
    return { date, time, dateTimeISO }
  }

  handleAddPickup(): void {
    this.stopType = 'dropoff_and_pickup'
  }

  handleRemovePickup(): void {
    this.stopType = 'dropoff_only'
    this.editSpotTime = false
    this.$emit('input', {
      ...this.value,
      pickupDatetime: null,
      pickupDate: null,
      pickupTime: null,
      spotTime: null,
    })
  }

  handleSpotTimeOffsetChange(time: number): void {
    if (!this.value.pickupTime || !this.value.pickupDate) {
      return
    }
    if (!time) {
      this.$emit('input', {
        ...this.value,
        spotTime: { ...this.value.spotTime, spotTime: null },
      })
    } else {
      const spotTime = dayjs(this.pickupDatetime).subtract(time, 'minutes')
      this.$emit('input', {
        ...this.value,
        spotTime: { spotTime: spotTime.toISOString() },
      })
    }

    this.$emit('stop-card:reset-errors')
  }

  handleSpotTimeChange(timeString: string): void {
    this.$emit('stop-card:reset-errors')
    if (!timeString) {
      this.$emit('input', {
        ...this.value,
        spotTime: { ...this.value.spotTime, spotTime: null },
      })
    } else {
      // Update the spottime to the timeString parameter
      let spotTime = dayjs(this.pickupDatetime).tz(this.timeZone)
      const spotTimeClock = dayjs(timeString, 'HH:mm')

      spotTime = spotTime
        .set('hour', spotTimeClock.hour())
        .set('minute', spotTimeClock.minute())

      // Set the pickupDate to spotTime + spotTimeOffset
      const pickupCalendar = spotTime.add(this.spotTimeOffset, 'minutes')
      const date = pickupCalendar.format('YYYY-MM-DD')
      const time = pickupCalendar.format('HH:mm')
      const dateTimeISO = pickupCalendar.toISOString()

      this.$emit('input', {
        ...this.value,
        spotTime: { spotTime: spotTime.toISOString() },
        pickupDatetime: dateTimeISO,
        pickupDate: date,
        pickupTime: time,
      })
    }
  }

  handleRemoveSpotTime(): void {
    this.editSpotTime = false
    this.spotTimeOffset = null
    this.localDefaultSpotTimeOffset = null
    this.$emit('input', { ...this.value, spotTime: null })
  }

  handleDeleteStop(): void {
    this.$emit('delete', this.value)
  }

  convertDropoffToPickup(): void {
    if (this.value.pickupDatetime) {
      // if a pickupDatetime is set we do not need to convert
      return
    }

    this.$emit('input', {
      ...this.value,
      pickupDatetime: this.value.dropoffDatetime,
      pickupDate: this.value.dropoffDate,
      pickupTime: this.value.dropoffTime,
      dropoffDatetime: null,
      dropoffDate: null,
      dropoffTime: null,
    })
  }

  loadStopType(): void {
    if (this.value.orderIndex === 0) {
      this.stopType = 'pickup_only'
      this.convertDropoffToPickup()
    } else if (this.value.pickupDatetime && this.value.dropoffDatetime) {
      this.stopType = 'dropoff_and_pickup'
    } else if (this.value.pickupDatetime) {
      this.stopType = 'pickup_only'
    } else if (this.value.dropoffDatetime) {
      this.stopType = 'dropoff_only'
    } else {
      // Case of a new stop--if it's the first stop in the quote,
      // it should be pickup only, otherwise we set it to dropoff
      // by default
      this.stopType =
        this.value.orderIndex === 0 ? 'pickup_only' : 'dropoff_only'
    }
  }

  get totalEstimationTime(): string {
    return this.formatSecondsToDuration(
      this.getTotalEstimationTime(this.value.orderIndex)
    )
  }

  get lg(): boolean {
    return this.$vuetify.breakpoint.width >= 1500
  }

  mounted() {
    if (this.value.spotTime?.spotTime) {
      this.spotTimeOffset = dayjs(this.pickupDatetime).diff(
        this.value.spotTime.spotTime,
        'minutes'
      )
    }
    this.localDefaultSpotTimeOffset = this.defaultSpotTimeOffset
    this.loadStopType()
  }
}
