
import { Component, Prop, Vue } from 'vue-property-decorator'
import TripPricingBaseFarePicker from '@/components/TripPricingBaseFarePicker.vue'
import TripPricingCharges from '@/components/TripPricingCharges.vue'
import TripPricingTotalFooter from '@/components/TripPricingTotalFooter.vue'
import { Trip, TripChargeType, TripEstimation } from '@/models/dto'
import {
  LineItemSectionTypes,
  PricingSelectionId,
  TripChargeTypes,
} from '@/utils/enum'
import { TripPricings } from '@/models/TripPricings'
import {
  getTripBaseFare,
  getTripTotalBaseFare,
  getTripSubtotal,
  getTripTotal,
  setTripCharge,
} from '@/utils/trip'
import {
  LineItemCharge,
  LineItemSectionType,
} from '@/models/dto/LineItemCharge'
import CUCurrency from '@/components/CUCurrency.vue'
import { currencyFilter } from '@/utils/string'
import TripPricingSummary from '@/components/TripPricingSummary.vue'
import { distinctBy } from '@/utils/reducers'
import tclient from '@/services/type'
import { BaseFarePickerEvent } from '@/models/dto/Event'
import quote from '@/store/modules/quote'
import { pricingSelectionTypes } from '@/utils/pricing'
@Component({
  components: {
    TripPricingSummary,
    CUCurrency,
    TripPricingCharges,
    TripPricingTotalFooter,
    TripPricingBaseFarePicker,
  },
})
export default class TripPricing extends Vue {
  @Prop({ required: true }) readonly trip!: Trip
  @Prop({ required: false, default: false }) readonly isReservation!: boolean
  @Prop({ required: false }) readonly tripIdx!: number
  @Prop({ required: true }) readonly tripEstimation!: TripEstimation
  @Prop({ required: true }) readonly tripPricings!: TripPricings
  @Prop({ type: Boolean, default: false }) readonly readOnly!: boolean

  chargeTypes: TripChargeType[] = []
  sectionTypes: Record<string, LineItemSectionType> = {}
  originalBaseFare = this.tripBaseFare
  calculatedBaseFare = null
  currencyFilter = currencyFilter
  sections = LineItemSectionTypes
  state = quote
  baseFarePickerCache: { pricingSelectionId: number; baseFare: number }[] = []

  additionalChargeFilter(c: LineItemCharge): boolean {
    return c.lineItemSectionType?.key === LineItemSectionTypes.BASE_FARE
  }

  get tripName(): string {
    return this.trip.routeName || this.tripIdx
      ? `Trip ${1 + this.tripIdx}`
      : 'Trip'
  }

  get tripBaseFare(): number {
    return getTripBaseFare(this.trip)
  }

  get tripTotalBaseFare(): number {
    return getTripTotalBaseFare(this.trip)
  }

  get tripSubtotal(): number {
    return getTripSubtotal(this.trip)
  }

  get tripTotal(): number {
    return getTripTotal(this.trip)
  }

  get isConverted(): boolean {
    return !!this.state?.quote?.isConverted
  }

  get dailyRate(): number {
    return this.tripPricings.pricingDaily?.baseAmount || 0
  }

  get hourlyRate(): number {
    return this.tripPricings.pricingHourly?.baseAmount || 0
  }

  get mileageRate(): number {
    return this.tripPricings.pricingMileage?.baseAmount || 0
  }

  get highestPrice(): number {
    return Math.max(this.dailyRate, this.hourlyRate, this.mileageRate)
  }

  getBaseFareForPricingSelectionId(pricingSelectionId: number): number {
    const { key: pricingSelectionKey } = pricingSelectionTypes.find(
      ({ id }) => id === pricingSelectionId
    )
    if (pricingSelectionKey === 'choose_override') {
      return 0
    }

    if (pricingSelectionKey.includes('highest')) {
      return Math.max(this.hourlyRate, this.mileageRate, this.highestPrice)
    }

    let sum = 0
    if (pricingSelectionKey.includes('hourly')) {
      sum += this.hourlyRate
    }
    if (pricingSelectionKey.includes('mileage')) {
      sum += this.mileageRate
    }
    if (pricingSelectionKey.includes('daily')) {
      sum += this.dailyRate
    }
    return sum
  }

  // Find the base fare charge, update it to use the new
  // base fare amount, and finally set all line item charges + Trip Markups to active
  handleUpdateBaseFarePicker({
    baseFare,
    pricingSelectionId,
    baseFarePricingTypes,
    skipCaching,
  }: BaseFarePickerEvent): void {
    if (!skipCaching) {
      this.baseFarePickerCache.push({
        pricingSelectionId: this.trip.pricingSelectionId,
        baseFare: this.tripBaseFare,
      })
    }

    this.calculatedBaseFare = baseFare
    const type = TripChargeTypes.BASE_FARE
    const chargeType = this.chargeTypes.find((c) => c.key === type)

    if (!chargeType) {
      return
    }

    const trip = setTripCharge(this.trip, chargeType, baseFare)
    const lineItemCharges = this.trip.lineItemCharges?.map((c) => ({
      ...c,
      isActive: true,
    }))

    const updatedTrip = Object.assign(
      {},
      trip,
      { lineItemCharges },
      pricingSelectionId && { pricingSelectionId },
      baseFarePricingTypes && { baseFarePricingTypes }
    )

    this.handleUpdateTrip(updatedTrip)
  }

  // Solves the case of exiting the input after deleting a $ or ,
  // but not the numeric value itself
  // When leaving the input, make sure the input's value is the formatted
  // currency base fare, since the computed won't update unless the base fare
  // is changed
  handleTotalBaseFareBlur(): void {
    const textField: any = this.$refs['total-base-fare-input']['$children'][0]
    if (textField) {
      textField._data.lazyValue = this.formattedTotalBaseFare
    }
  }

  handleOverrideBaseFare(override: string, skipCaching = false): void {
    const type = TripChargeTypes.BASE_FARE
    const chargeType = this.chargeTypes.find((c) => c.key === type)
    let baseFare = parseFloat(override.replace(/[^\d.-]/g, ''))
    if (isNaN(baseFare) || baseFare < 0) {
      baseFare = 0
    }

    if (!skipCaching) {
      this.baseFarePickerCache.push({
        pricingSelectionId: this.trip.pricingSelectionId,
        baseFare: this.tripBaseFare,
      })
    }

    if (chargeType) {
      const trip = setTripCharge(this.trip, chargeType, baseFare)
      const lineItemCharges = this.trip.lineItemCharges.map((c) =>
        this.additionalChargeFilter(c) ? { ...c, isActive: false } : c
      )

      this.handleUpdateTrip({
        ...trip,
        lineItemCharges,
        pricingSelectionId: PricingSelectionId.ChooseOverride,
      })
    }
  }

  get formattedTotalBaseFare(): string {
    return currencyFilter(getTripTotalBaseFare(this.trip))
  }

  setCalculatedBaseFare(baseFare: number): void {
    this.calculatedBaseFare = baseFare
  }

  handleResetBaseFare(): void {
    if (!this.baseFarePickerCache.length) {
      return
    }

    const { pricingSelectionId, baseFare } = this.baseFarePickerCache.pop()

    if (pricingSelectionId === PricingSelectionId.ChooseOverride) {
      this.handleOverrideBaseFare(baseFare.toString(), true)
    } else {
      const updatedBaseFare =
        this.getBaseFareForPricingSelectionId(pricingSelectionId)

      this.handleUpdateBaseFarePicker({
        pricingSelectionId,
        baseFare: updatedBaseFare,
        skipCaching: true,
      })
    }
  }

  handleUpdateTrip(trip: Trip): void {
    const tripIdx = this.tripIdx
    const total = getTripTotal(trip)
    this.$emit('input', { trip: { ...trip, total }, tripIdx })
  }

  async mounted(): Promise<void> {
    tclient
      .tripChargeTypes()
      .then((res) => res.data)
      .then((chargeTypes) => (this.chargeTypes = chargeTypes))

    tclient
      .lineItemSectionTypes()
      .then((res) => res.data)
      .then((sectionTypes) => sectionTypes.reduce(distinctBy('key'), {}))
      .then((sectionTypes) => (this.sectionTypes = sectionTypes))
  }
}
