
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import { TripEstimation } from '@/models/dto'
import { metersToMiles } from '@/utils/distance'
import { msToTimeLabel } from '@/utils/time'
import reservation from '@/store/modules/reservation'
import quote from '@/store/modules/quote'
import dayjs from 'dayjs'

@Component({})
export default class TripEstimationsFooter extends Vue {
  @Prop({ required: false }) readonly tripEstimation!: TripEstimation
  @Inject({ default: false }) isReservationTrip: boolean

  msToTimeLabel = msToTimeLabel

  get totalDistance(): string {
    const liveDistance = this.tripEstimation?.distance || 0
    const deadDistance = this.tripEstimation?.deadDistance || 0
    const meters = liveDistance + deadDistance
    const miles = metersToMiles(meters)
    const label = miles.toFixed(2)
    return miles === 0 ? '' : `${label} mi.`
  }

  get estimatedTime(): string {
    if (!this.tripEstimation?.tripId && this.tripEstimation?.tripId !== 0) {
      return null
    }

    const trip = this.isReservationTrip
      ? reservation.trip
      : quote.trips?.find(({ tripId }) => tripId === this.tripEstimation.tripId)

    const stops = trip?.stops?.filter((s) => !!s.address?.city)
    const start =
      trip?.garageTimes?.departureTime ||
      stops?.[0]?.pickupDatetime ||
      stops?.[0]?.dropoffDatetime
    const end =
      trip?.garageTimes?.returnTime ||
      stops?.[stops.length - 1]?.dropoffDatetime ||
      stops?.[stops.length - 1]?.pickupDatetime
    return start && end ? msToTimeLabel(dayjs(end).diff(start)) : null
  }
}
