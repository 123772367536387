
import { Component, Prop, Vue } from 'vue-property-decorator'
import TripDetails from '@/components/TripDetails.vue'
import TripPricing from '@/components/TripPricing.vue'
import { Tab } from '@/models/dto/Tab'
import { Trip, TripEstimation } from '@/models/dto'
import { TripPricings } from '@/models/TripPricings'
import rstate from '@/store/modules/reservation'
import { getTripEstimation } from '@/utils/trip'

@Component({})
export default class ReservationTrip extends Vue {
  @Prop({ required: true }) readonly trip!: Trip
  @Prop({ required: true }) readonly tripEstimation!: TripEstimation
  @Prop({ required: true }) readonly tripPricings!: TripPricings

  form: Trip = new Trip()
  tabIndex = 0
  tripEstimationDebounce = null
  state = rstate

  private mainTabTitleHelper = (section?: string): string => {
    if (!section) {
      return this.state.reservation?.managedId
        ? `Reservation ${this.state.reservation.managedId} - ${
            this.trip.routeName || 'Trip'
          }`
        : `Reservation ${this.trip.routeName || 'Trip'}`
    }

    return this.state.reservation?.managedId
      ? `Reservation ${this.state.reservation.managedId} - ${
          this.trip.routeName || 'Trip'
        } ${section}`
      : `Reservation - ${this.trip.routeName || 'Trip'} ${section}`
  }

  get tabs(): Tab[] {
    return [
      {
        label: 'Details',
        component: TripDetails,
        ref: 'reservation-trip-details',
        hash: 'trip-details',
        title: this.mainTabTitleHelper('Details'),
        props: {
          trip: this.trip,
          tripEstimation: this.tripEstimation,
        },
        on: {
          input: (trip: Trip) => this.handleTripChange(trip),
          'input-silent': (trip: Trip) => this.handleTripChange(trip, true),
          refresh: () => this.$emit('refresh'),
        },
      },
      {
        label: 'Pricing',
        component: TripPricing,
        hash: 'trip-pricing',
        title: this.mainTabTitleHelper('Pricing'),
        props: {
          trip: this.trip,
          isReservation: true,
          tripEstimation: this.tripEstimation,
          tripPricings: this.tripPricings,
          readOnly: true,
        },
      },
    ]
  }

  handleTripChange(trip: Trip, silent = false): void {
    if (!silent) {
      rstate.updateTripHasBeenModified(true)
    }
    rstate.updateTrip({ ...trip })

    if (this.tripEstimationDebounce) {
      window.clearTimeout(this.tripEstimationDebounce)
    }
    this.tripEstimationDebounce = window.setTimeout(() => {
      this.handleUpdateTripEstimation()
    }, 250)
  }

  async handleUpdateTripEstimation(): Promise<void> {
    try {
      const tripEstimation = await getTripEstimation(rstate.trip)
      rstate.setTripEstimations(tripEstimation)
    } catch (e) {
      return
      // Intentionally blank, catching the tripEstimations endpoint
      // returning a 500
    }
  }

  handleTabChanged(event: number[]): void {
    this.tabIndex = event[0]
  }

  mounted(): void {
    if (this.trip) {
      this.form = { ...this.trip }
    }
  }
}
