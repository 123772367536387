
import { Transaction } from '@/models/dto/Transaction'
import { Prop } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { currencyFilter } from '@/utils/string'
import sidebar from '@/store/modules/sidebar'
import transaction from '@/services/transaction'
import { EventBus } from '@/utils/eventBus'

@Component({})
export default class PaymentDetails extends mixins(DateMixin) {
  @Prop({ required: true, default: null }) transaction: Transaction

  newNote = ''
  loading = false

  currencyFilter = currencyFilter

  get transactionType(): string {
    if (this.transaction.description.includes('Refund')) {
      return 'Refund'
    } else if (this.transaction.charge) {
      return 'Charge'
    } else if (this.transaction.payment) {
      return 'Payment'
    }
    return '--'
  }

  getReferenceId(transaction: Transaction): string {
    if (transaction.referenceId) {
      return transaction.referenceId.length < 16
        ? transaction.referenceId
        : `${transaction.referenceId.substring(0, 16)}...`
    }
    return '--'
  }

  async submit(): Promise<void> {
    this.loading = true
    const payload = { ...this.transaction, notes: this.newNote || '' }
    try {
      await transaction.updateTransactionNote(payload)
      EventBus.$emit('snackbar:success', 'Note saved successfully!')
      EventBus.$emit('refresh-reservation')
    } catch (err) {
      EventBus.$emit('snackbar:error', 'Error saving note')
    } finally {
      this.loading = false
      sidebar.pop()
    }
  }

  cancel(): void {
    sidebar.pop()
  }

  mounted(): void {
    this.newNote = this.transaction.notes
  }
}
