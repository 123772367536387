
import { Reservation } from '@/models/dto'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action } from '@/models/dto/Action'
import dayjs from 'dayjs'
import sidebar from '@/store/modules/sidebar'
import Placeholder from '@/views/Placeholder.vue'
import { LatLngToMiles, metersToMiles } from '@/utils/distance'
import reservation from '@/store/modules/reservation'
import { TrackingResponse } from '@/models/dto/Tracking'
import { currencyFilter } from '@/utils/string'
import { getRateTypeString } from '@/utils/rate'

@Component({})
export default class ReservationTrackingSummary extends Vue {
  @Prop({ required: true }) reservation: Reservation
  @Prop({ required: true }) tracking: TrackingResponse
  @Prop({ required: true }) vehicles: {
    label: string
    id: number
    event: string
  }[]

  summaryVehicleIdx = 0

  @Watch('summaryVehicles', { immediate: true })
  setSummaryVehicle(): void {
    this.summaryVehicleIdx = 0
  }

  get overageRate(): string {
    const rate = reservation.trip.rates?.[0] || null
    if (!rate) {
      return null
    }
    return `${currencyFilter(rate.amount)}/${getRateTypeString(rate)}`
  }

  get planned(): { distance: string; hours: string; days: string } {
    if (!reservation.estimations) {
      return { distance: '0.0', hours: '0.0', days: '0' }
    }
    const estimatedHours = dayjs(reservation.estimations.estimatedEndDate).diff(
      reservation.estimations.startDate,
      'hour',
      true
    )
    return {
      distance: metersToMiles(
        reservation.estimations.distance + reservation.estimations.deadDistance
      ).toFixed(1),
      hours: estimatedHours.toFixed(1),
      days: Math.ceil(estimatedHours / 24).toFixed(0),
    }
  }

  get actual(): { distance: string; hours: string; days: string }[] {
    const actualDistance = []
    const actualHours = this.reservation.journeys.map((journey, journeyIdx) => {
      if (journey?.vehicle?.vehicleId) {
        const tracking = this.tracking?.trackingJourneyDataList.find(
          (list) => list.vehicleId === journey.vehicle.vehicleId
        )
        if (tracking?.gpsData) {
          const skip = Math.ceil(tracking.gpsData.length / 1000)
          let skippedGpsPointsSum = 0
          for (let i = skip; i < tracking.gpsData.length; i += skip) {
            const a = tracking.gpsData[i]
            const b = tracking.gpsData[i - skip]
            skippedGpsPointsSum +=
              a.lat !== b.lat || a.lng !== b.lng
                ? LatLngToMiles(
                    Number(a.lat),
                    Number(a.lng),
                    Number(b.lat),
                    Number(b.lng)
                  )
                : 0
          }
          if (
            tracking.gpsData.length > 1 &&
            (tracking.gpsData.length - 1) % skip !== 0
          ) {
            const a = tracking.gpsData[tracking.gpsData.length - 1]
            const b = tracking.gpsData[tracking.gpsData.length - 2]
            skippedGpsPointsSum += LatLngToMiles(
              Number(a.lat),
              Number(a.lng),
              Number(b.lat),
              Number(b.lng)
            )
          }
          actualDistance.push(skippedGpsPointsSum)
        }
      }

      if (journey?.finishDatetime) {
        return dayjs(journey?.finishDatetime).diff(
          journey?.startDatetime,
          'hour',
          true
        )
      } else if (
        journey?.startDatetime &&
        this.tracking?.trackingJourneyDataList?.[journeyIdx]
      ) {
        const receivedDate =
          this.tracking.trackingJourneyDataList[journeyIdx].receivedDate
        return dayjs(receivedDate).diff(journey?.startDatetime, 'hour', true)
      }
    })

    if (actualHours?.length === 1) {
      return [
        {
          distance: actualDistance?.[0] ? actualDistance[0].toFixed(1) : null,
          hours: actualHours?.[0]?.toFixed(1) || null,
          days: actualHours?.[0]
            ? Math.ceil(actualHours[0] / 24).toFixed(0)
            : null,
        },
      ]
    } else {
      const hours = actualHours?.length
        ? actualHours.reduce((sum, h) => sum + (h || 0), 0) /
          actualHours.filter((h) => !!h).length
        : null
      return [
        {
          distance: actualDistance.length
            ? (
                actualDistance.reduce((sum, d) => sum + d, 0) /
                actualDistance.length
              ).toFixed(1)
            : null,
          hours: hours ? hours.toFixed(1) : null,
          days: hours ? Math.ceil(hours / 24).toFixed(0) : null,
        },
        ...actualHours.map((actual, idx) => {
          return {
            distance:
              actualDistance.length && actualDistance[idx]
                ? actualDistance[idx].toFixed(1)
                : null,
            hours: actual ? actual.toFixed(1) : null,
            days: actual ? Math.ceil(actual / 24).toFixed(0) : null,
          }
        }),
      ]
    }
  }

  get difference(): { distance: string; hours: string; days: string } {
    const {
      distance: actualDistance,
      hours: actualHours,
      days: actualDays,
    } = this.actual[this.summaryVehicleIdx]
    const {
      distance: plannedDistance,
      hours: plannedHours,
      days: plannedDays,
    } = this.planned
    return {
      distance: actualDistance
        ? (Number(actualDistance) - Number(plannedDistance)).toFixed(1)
        : '--',
      hours: actualHours
        ? (Number(actualHours) - Number(plannedHours)).toFixed(1)
        : '--',
      days: actualDays
        ? (Number(actualDays) - Number(plannedDays)).toFixed(0)
        : '--',
    }
  }

  get totalHours(): number {
    return dayjs(
      this.reservation.stops[this.reservation.stops.length - 1].dropoffDatetime
    ).diff(this.reservation.stops[0].pickupDatetime, 'hours', true)
  }

  get totalDays(): number {
    return Math.ceil(
      dayjs(
        this.reservation.stops[this.reservation.stops.length - 1]
          .dropoffDatetime
      ).diff(this.reservation.stops[0].pickupDatetime, 'days', true)
    )
  }

  get summaryVehicles(): { label: string; id: number; event: string }[] {
    if (this.vehicles?.length === 1) {
      return [{ label: 'Actual', id: -1, event: 'input' }]
    }
    return [{ label: 'Actual Avg', id: -1, event: 'input' }, ...this.vehicles]
  }

  handleChangeSummaryVehicle(val: Action): void {
    this.summaryVehicleIdx = this.summaryVehicles.findIndex(
      (v) => v.id === val.id
    )
  }

  handleAddOverageCharge(): void {
    sidebar.push({
      component: Placeholder,
      title: 'Add Overage Charge',
    })
  }
}
