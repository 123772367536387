
import { Component, Prop, Vue } from 'vue-property-decorator'
import RecipientList from '@/components/RecipientList.vue'
import CUCheckbox from '@/components/CUCheckbox.vue'
import reservation from '@/store/modules/reservation'
import CustomerContactSearch from './CustomerContactSearch.vue'
import { Customer } from '@/models/dto/Customer'
import SendToAffiliateForm from './SendToAffiliateForm.vue'
import { AffiliateOffer } from '@/models/dto/Affiliate'
import HoldUpModal from '@/components/HoldUpModal.vue'
import customerAccountClient from '@/services/customerAccount'
import customer from '@/services/customer'
import affiliate from '@/services/affiliate'
import { EventBus } from '@/utils/eventBus'
import CUContactCard from '@/components/CUContactCard.vue'

@Component({
  components: {
    CUCheckbox,
    RecipientList,
    CustomerContactSearch,
    SendToAffiliateForm,
    HoldUpModal,
    CUContactCard,
  },
})
export default class SendToAffiliateSidebar extends Vue {
  @Prop({ required: false, default: 'Send to Affiliate' }) title: string
  @Prop({ type: Boolean, default: false }) editContactOnly: string
  @Prop({ type: Number, default: null }) affiliateOfferId: number
  @Prop({ type: Number, default: null }) affiliateCustomerId: number
  @Prop({ type: Boolean, default: false }) isEdit: boolean

  state = reservation

  loading = false
  submitting = false
  affiliateCustomer: Customer = null
  data = {
    id: null,
    amount: null,
    profit: null,
    note: {
      note: null,
      html: null,
    },
    emails: null,
    includeTripContactInfo: false,
    sendConfirmationToAffiliate: true,
    vehicleDetails: [],
    passengerCount: null,
  }
  isSubmitModalOpen = false
  isCancelModalOpen = false

  editContactActions = [{ label: 'Change Contact', event: 'contact:change' }]

  get formData(): any {
    return this.data
  }

  handleVehicleUpdates(vehicles: any[]): void {
    this.data.vehicleDetails = vehicles.map((vehicle) => {
      return {
        vehicleCount: vehicle.count,
        vehicleType: vehicle.item,
        id: vehicle.id,
      }
    })
  }

  async handleChangeContact(customer: Customer): Promise<void> {
    let customerAccount = null
    if (customer.customerAccountId) {
      const res = await customerAccountClient.byId(customer.customerAccountId)
      customerAccount = res.data
    }
    this.affiliateCustomer = { ...customer, customerAccount }
    this.data.emails = [
      customer.email,
      ...(customerAccount ? [customerAccount.email] : []),
    ].join(',')
  }

  handleReplaceContact(): void {
    this.affiliateCustomer = null
    this.data.emails = null
  }

  cancel(): void {
    this.$emit('cancel')
  }

  close(): void {
    if (this.affiliateCustomer && !this.isEdit) {
      this.isCancelModalOpen = true
    } else {
      this.cancel()
    }
  }

  handleSendAffiliateOffer(): void {
    if (this.data.sendConfirmationToAffiliate) {
      this.isSubmitModalOpen = true
    } else {
      this.submit()
    }
  }

  submit(): Promise<void> {
    if (!this.editContactOnly) {
      const form: any = this.$refs.form
      if (!form.validate()) {
        return
      }
    }
    this.submitting = true
    const offer: AffiliateOffer = {
      id: this.affiliateOfferId,
      isActive: true,
      affiliateOfferId: this.affiliateOfferId,
      reservationId: this.state.reservation.reservationId,
      customerId: this.affiliateCustomer.customerId,
      customerAccountId: this.affiliateCustomer.customerAccountId,
      ...this.data,
    }
    this.$emit('submit', offer)
  }

  async mounted(): Promise<void> {
    EventBus.$on('contact-sidebar:update', (params) =>
      this.handleChangeContact(params.contact)
    )

    if (!this.affiliateOfferId) {
      return
    }

    try {
      this.loading = true
      const [res, { data: offer }] = await Promise.all([
        customer.byId(this.affiliateCustomerId),
        affiliate.byId(this.affiliateOfferId),
      ])

      this.affiliateCustomer = !this.editContactOnly ? res.data.customer : null
      this.data = {
        id: this.affiliateOfferId,
        amount: offer.affiliateOffer?.amount,
        profit: offer.affiliateOffer?.profit,
        note: offer.affiliateOffer?.note,
        emails: offer.affiliateOffer?.emails,
        includeTripContactInfo: offer.affiliateOffer?.includeTripContactInfo,
        sendConfirmationToAffiliate: !this.editContactOnly,
        vehicleDetails: offer.affiliateOffer?.vehicleDetails,
        passengerCount: offer.affiliateOffer?.passengerCount,
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.loading = false
    }
  }

  beforeDestroy(): void {
    EventBus.$off('contact-sidebar:update', (params) =>
      this.handleChangeContact(params.contact)
    )
  }
}
