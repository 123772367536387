
import { Vue, Prop, Component } from 'vue-property-decorator'
import { PaymentTransaction } from '@/models/dto/PaymentTransaction'
import transaction from '@/services/transaction'
import payments from '@/services/payments'
import refund from '@/services/refund'
import { EventBus } from '@/utils/eventBus'
import HoldUpModal from '@/components/HoldUpModal.vue'
@Component({
  components: {
    HoldUpModal,
  },
})
export default class CUDataTableVoidPaymentLineItem extends Vue {
  @Prop({ required: true }) readonly item!: PaymentTransaction
  isVoidLineItemOpen = false

  actions = [
    {
      label: 'Void',
      event: (): void => this.handleVoidLineItemConfirmation(),
    },
  ]

  handleVoidLineItemConfirmation(): void {
    this.isVoidLineItemOpen = true
  }

  async handleVoidLineItem(): Promise<void> {
    try {
      let res
      if (this.item.transactionId) {
        res = await transaction.void(this.item.transactionId)
      } else if (this.item.paymentId) {
        res = await payments.void(this.item.paymentId)
      } else if (this.item.refundId) {
        res = await refund.void(this.item.refundId)
      } else {
        return
      }
      if (res.status === 200) {
        this.handleSubmitSuccess()
      }
    } catch (e: any) {
      console.error(e)
      EventBus.$emit('snackbar:error', e.response.data.message)
    }
  }

  handleSubmitSuccess(): void {
    EventBus.$emit('snackbar:success', `Voided successfully!`)
    EventBus.$emit('refresh-reservation')
  }
}
