
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Customer } from '@/models/dto/Customer'
import client from '@/services/customer'
import { formatFullName } from '@/utils/string'
import { filter } from '@/utils/filter'

@Component({})
export default class AutocompleteCustomer extends Vue {
  @Prop({ required: false }) readonly value: Customer | number
  @Prop({ type: Boolean, default: false }) readonly idOnly!: boolean
  @Prop({ type: Boolean, default: false }) readonly hideIcon!: boolean
  customers: Customer[] = []
  debounce: ReturnType<typeof setTimeout> | null = null
  formatFullName = formatFullName

  filter(customer: Customer, queryText = ''): boolean {
    const query = queryText.toLowerCase()
    const matchingName = formatFullName(customer)
      ?.toLowerCase()
      ?.includes(query)
    const matchingEmail = customer.email?.toLowerCase()?.includes(query)
    const matchingPhone = customer.phone?.toLowerCase()?.includes(query)
    return matchingName || matchingEmail || matchingPhone
  }

  fetch(query = ''): void {
    if (this.debounce) {
      clearTimeout(this.debounce)
    }
    this.debounce = setTimeout(() => {
      const filters = filter()
      const parentFilter = filters.createParent('or')
      filters.add(parentFilter, {
        value: query,
        column: {
          _t_id: 'C2D10FEF-6812-419A-B393-BAC3E4558914',
          value: 'firstName',
          filterType: 'contains',
        },
      })
      filters.add(parentFilter, {
        value: query,
        column: {
          _t_id: 'D929EBED-F529-4D45-9E2D-05C8C4D47374',
          value: 'lastName',
          filterType: 'contains',
        },
      })
      filters.add(parentFilter, {
        value: query,
        column: {
          _t_id: '1535C7F4-C7A0-42BD-9DDE-485E1E6A8D43',
          value: 'email',
          filterType: 'contains',
        },
      })
      filters.add(parentFilter, {
        value: query,
        column: {
          _t_id: '38D163FC-5062-4B19-898F-E528B189CAEE',
          value: 'phone',
          filterType: 'contains',
        },
      })
      client
        .tableView({ filters: filters.asQueryParams() })
        .then((res) => res.data.resultList)
        .then((customers) => (this.customers = customers))
    }, 250)
  }

  customerName(customer: Customer): string {
    const name = formatFullName(customer)
    const company = customer.company?.name
    return name && company ? `${name}; ${company}` : name
  }

  customerEmail(customer: Customer): string {
    return customer.email || ''
  }

  customerPhone(customer: Customer): string {
    return customer.phone || ''
  }

  get placeholderText(): string {
    return this.$attrs.placeholder || 'Search for name, email, or phone number'
  }

  get labelText(): string {
    return this.$attrs.label || 'Search Contact'
  }
}
