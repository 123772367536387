
import reservation from '@/store/modules/reservation'
import { Vue, Component } from 'vue-property-decorator'
import ReservationDriverPayBody from './ReservationDriverPayBody.vue'
import ReservationDriverPayHeader from './ReservationDriverPayHeader.vue'
import { Invoice } from '@/models/Invoice'
import { DriverAssignment, VehicleAssignment } from '@/models/dto'
import { Driver } from '@/models/dto/Driver'

@Component({
  components: {
    ReservationDriverPayHeader,
    ReservationDriverPayBody,
  },
})
export default class ReservationDriverPayDriverDetail extends Vue {
  state = reservation

  driverData: any[] = []

  // Match the invoices on the reservation to the driver assignments, guarantee order matches left sidebar
  get invoiceAssignments(): {
    invoice: Invoice
    assignment: VehicleAssignment
    driver: Driver
  }[] {
    const assignments = this.state.assignments || []
    const invoices = this.state.invoices || []
    const invoiceAssignments = []

    const flattenedAssignments: {
      vehicle: VehicleAssignment
      driver: DriverAssignment
    }[] = assignments.reduce((arr, a) => {
      arr.push(...a.driverAssignments.map((d) => ({ vehicle: a, driver: d })))
      return arr
    }, [])

    for (const assignment of flattenedAssignments) {
      const matchingInvoices = invoices.filter(
        (i) => i.driver.userId === assignment.driver.userId
      )

      invoiceAssignments.push(
        matchingInvoices
          .map((invoice) => ({
            invoice,
            assignment: assignment.vehicle,
            driver: assignment.driver.driver,
          }))
          .sort((a, b) =>
            a.invoice.duplicateIndex > b.invoice.duplicateIndex ? 1 : -1
          )
      )
    }

    return invoiceAssignments
  }

  get driverAssignments(): any[] {
    return this.state.assignments?.flatMap((a) => a.driverAssignments) || []
  }

  handleDriverUpdate(data: any, idx: number): void {
    this.driverData[idx] = data
  }

  handleDuplicate(idx: number): void {
    this.driverData = [
      ...this.driverData.slice(0, idx + 1),
      { ...this.driverData[idx], status: 'draft', id: null },
      ...this.driverData.slice(idx + 1),
    ]
  }

  handleDelete(idx: number): void {
    this.driverData.splice(idx, 1)
  }
}
