
import {
  SpotTime,
  Stop,
  Trip,
  TripEstimation,
  VehicleAssignment,
} from '@/models/dto'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import StopWithNotes from '@/components/StopWithNotes.vue'
import { Action } from '@/models/dto/Action'
import ReservationTrackingMap from '@/components/ReservationTrackingMap.vue'
import ReservationTrackingRow from '@/components/ReservationTrackingRow.vue'
import ReservationTrackingSummary from '@/components/ReservationTrackingSummary.vue'
import auth from '@/store/modules/auth'
import { Journey, JourneyStop, JourneyTime } from '@/models/dto/Journey'
import { TrackingResponse } from '@/models/dto/Tracking'
import { TrackingStatus, SourceCategory } from '@/utils/enum'
import state from '@/store/modules/reservation'
import { Garage } from '@/models/dto/Garage'
import { EventBus } from '@/utils/eventBus'
import { getTrackingLink } from '@/utils/reservation'

@Component({
  components: {
    StopWithNotes,
    ReservationTrackingMap,
    ReservationTrackingRow,
    ReservationTrackingSummary,
  },
})
export default class ReservationTracking extends Vue {
  @Prop({ required: true }) assignments: VehicleAssignment[]
  @Prop({ required: true }) tracking: TrackingResponse
  @Prop({ required: false, default: false }) isSelected: boolean
  @Prop({ required: false }) readonly tripEstimation!: TripEstimation

  auth = auth
  reservation = state.reservation
  isReferral: boolean =
    this.reservation.sourceCategory === SourceCategory.REFERRAL

  vehicle: Action = null

  @Watch('vehicles', { immediate: true })
  setInitialVehicle(): void {
    this.vehicle = this.vehicles?.length ? this.vehicles[0] : null
  }

  get trip(): Trip {
    return state.trip
  }

  get vehicles(): { label: string; id: number; event: string }[] {
    const assigned = []
    const unassigned = state.reservation.journeys?.reduce((arr, j) => {
      const existingIndex = arr.findIndex((el) => el.id === j.vehicle.vehicleId)
      const newVehicle = {
        label: j.vehicle.vehicleName
          ? j.vehicle.vehicleName
          : `Vehicle ${j.vehicle.vehicleId}`,
        id: j.vehicle.vehicleId,
        event: 'input',
      }
      if (existingIndex !== -1) {
        arr.splice(existingIndex, 1)
      }
      if (j.finished && !j.finishDatetime) {
        arr.push(newVehicle)
      } else {
        assigned.push(newVehicle)
      }
      return arr
    }, [])
    return [...assigned, ...unassigned]
  }

  get showReservationTrackingLinkToggle(): boolean {
    return auth.company?.defaultEnableTrackingLink || false
  }

  get journeys(): any {
    return (
      state.reservation.journeys
        ?.filter((j) => j?.vehicle?.vehicleId)
        .reduce((obj, journey) => {
          obj[journey.vehicle.vehicleId] = journey
          return obj
        }, {}) || {}
    )
  }

  get isJourneyActive(): boolean {
    if (!this.vehicle) {
      return false
    }
    return (
      this.journeys[this.vehicle.id]?.started ||
      this.journeys[this.vehicle.id]?.commutingDriverId ||
      false
    )
  }

  get journeyStart(): JourneyTime {
    if (!this.vehicle) {
      return null
    }
    return {
      time: this.journeys[this.vehicle.id]?.startDatetime,
      odometer: this.journeys[this.vehicle.id]?.odometryReadingAtStart,
    }
  }

  get journeyFinish(): JourneyTime {
    if (!this.vehicle) {
      return null
    }
    return {
      time: this.journeys[this.vehicle.id]?.finishDatetime,
      odometer: this.journeys[this.vehicle.id]?.odometryReadingAtFinish,
    }
  }

  get driverCommuteData(): Partial<Journey> {
    if (!this.vehicle) {
      return {}
    }
    return {
      commutingDriverId: this.journeys[this.vehicle.id]?.commutingDriverId,
      driverCommuteStart: this.journeys[this.vehicle.id]?.driverCommuteStart,
      driverCommuteDurationInMinutes:
        this.journeys[this.vehicle.id]?.driverCommuteDurationInMinutes,
    }
  }

  get trackingStatus(): string[] {
    if (!this.vehicle || !this.journeys[this.vehicle.id]) {
      return []
    }
    const status = [
      this.journeys[this.vehicle.id]?.startDatetime
        ? TrackingStatus.Completed
        : null,
    ]
    const sortedStops = this.journeys[this.vehicle.id].journeyStops.sort(
      (a, b) => (a.stop.orderIndex > b.stop.orderIndex ? 1 : -1)
    )
    for (const [i, stop] of sortedStops.entries()) {
      if (stop.completed) {
        status.push(TrackingStatus.Completed)
      } else if (stop.reached) {
        status.push(TrackingStatus.Reached)
      } else if (status[i] === TrackingStatus.Completed) {
        status.push(TrackingStatus.EnRoute)
      } else {
        status.push(TrackingStatus.Upcoming)
      }
    }
    if (this.journeys[this.vehicle.id].finishDatetime) {
      status.push(TrackingStatus.Completed)
    } else if (status[status.length - 1] === TrackingStatus.Completed) {
      status.push(TrackingStatus.EnRoute)
    } else {
      status.push(TrackingStatus.Upcoming)
    }
    return status
  }

  get departureGarage(): {
    garage: Garage
    time: string
    arrivalTime: string
    timeZone: string
    notes: string
  } {
    return {
      garage: state.trip.garageTimes?.garage || null,
      time: state.trip.garageTimes?.departureTime || null,
      arrivalTime: state.trip.garageTimes?.preTripArrivalTime || null,
      timeZone:
        state.trip.garageTimes?.garage?.address?.timeZone ||
        state.trip.stops[0]?.address?.timeZone ||
        auth.getUserTimeZone,
      notes:
        state.trip.garageTimes?.htmlGarageTimeNotes ||
        state.trip.garageTimes?.garageTimeNotes ||
        null,
    }
  }

  get arrivalGarage(): {
    garage: Garage
    time: string
    timeZone: string
    notes: string
  } {
    return {
      garage: state.trip.garageTimes?.returnGarage || null,
      time: state.trip.garageTimes?.returnTime || null,
      timeZone:
        state.trip.garageTimes?.returnGarage?.address?.timeZone ||
        state.trip.stops[(state.trip.stops.length || 1) - 1]?.address
          ?.timeZone ||
        auth.getUserTimeZone,
      notes:
        state.trip.garageTimes?.htmlReturnGarageTimeNotes ||
        state.trip.garageTimes?.returnGarageTimeNotes ||
        null,
    }
  }

  get addresses(): string[] {
    return [
      state.reservation.garageTimes?.garage?.address.name,
      state.reservation.garageTimes?.returnGarage?.address.name,
    ].concat(
      ...state.trip.stops.map((stop) => {
        return stop?.address?.name || ''
      })
    )
  }

  getSpotTime(stop: Stop): SpotTime {
    return state.reservation.spotTimes.find((spotTime) => {
      return spotTime.stopId === stop.stopId
    })
  }

  handleCopyTrackingLink(): void {
    navigator.clipboard.writeText(getTrackingLink(state.reservation))
    EventBus.$emit('snackbar:success', 'Link copied to clipboard!')
  }

  getJourneyStop(id: number): JourneyStop {
    if (!this.vehicle) {
      return null
    }
    return this.journeys[this.vehicle.id]?.journeyStops?.find(
      (s) => s.stop.stopId === id
    )
  }

  handleChangeVehicle(val: Action): void {
    if (val) {
      this.vehicle = val
    }
  }
}
