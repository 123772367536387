
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import client from '@/services/ticket'
import rclient from '@/services/reservation'
import type from '@/services/type'
import user from '@/services/user'
import auth from '@/store/modules/auth'
import {
  TicketDetailEntity,
  TicketType,
  TicketSeverityType,
  TicketStatusType,
  SubTicket,
} from '@/models/dto/Ticket'
import {
  Comment,
  convertCommentToSubTicket,
  convertSubTicketToComment,
} from '@/models/Comment'
import { validationRules, validateRules } from '@/utils/rules'
import { EventBus } from '@/utils/eventBus'
import sidebar from '@/store/modules/sidebar'
import { Reservation, UserDetail } from '@/models/dto'
import CUCommentArea from '@/components/CUCommentArea.vue'
import CUAutocomplete from '@/components/CUAutocomplete.vue'
import { formatFullName } from '@/utils/string'
import AutocompleteReservation from '@/components/AutocompleteReservation.vue'
import { UserGroupIds, userGroups } from '@/data/userGroups'

@Component({
  components: { AutocompleteReservation, CUCommentArea, CUAutocomplete },
})
export default class TicketDetail extends Vue {
  @Prop({ required: false, default: null })
  readonly activeTicketDetailId!: number
  @Prop({ required: false }) readonly reservation: Reservation

  sidebar = sidebar
  auth = auth
  rules = validationRules
  formatFullName = formatFullName

  ticketSeverityTypes: TicketSeverityType[] = []
  ticketStatusTypes: TicketStatusType[] = []
  ticketTypes: TicketType[] = []
  assigneeOptions: UserDetail[] = []
  ticket = new TicketDetailEntity()
  comments: Comment[] = []
  reservationIds: number[] = []
  selectedReservation: Reservation = null

  search = ''
  loading = true
  submitting = false

  setReservationIdOptions(): void {
    if (this.isModeEdit) {
      this.reservationIds = [this.ticket.reservationId]
    }
  }

  get closedString(): string {
    if (!this.isClosed) {
      return ''
    }
    // TODO backend changes
    const name = 'Lara Croft'
    const timeClosed = '6/17/22 5:35 PM EDT'
    return `This ticket was closed by ${name} on ${timeClosed}`
  }

  get isClosed(): boolean {
    const closedStatus = this.ticketStatusTypes.find((status) => {
      return status.key === 'closed'
    })
    return this.ticket.ticketStatusTypeId === closedStatus.id
  }

  get isModeAdd(): boolean {
    return !this.activeTicketDetailId
  }

  get isModeEdit(): boolean {
    return !!this.activeTicketDetailId
  }

  get isReservationIdReadOnly(): boolean {
    return this.isModeEdit || !!this.reservation
  }

  async submit(): Promise<void> {
    if (!(await validateRules(this))) {
      return
    }

    this.submitting = true

    const ticketRequest: TicketDetailEntity = this.ticket

    let request
    if (this.isModeAdd) {
      ticketRequest.companyId = this.auth.user.companyId
      ticketRequest.createdById = this.auth.userId
      ticketRequest.createdAt = new Date()
      const createdType = this.ticketStatusTypes.find((status) => {
        return status.key === 'created'
      })
      ticketRequest.ticketStatusTypeId = createdType.id
      ticketRequest.subTickets = this.comments.map((comment) => {
        return convertCommentToSubTicket(comment)
      })
      request = client.create(ticketRequest)
    } else if (this.isModeEdit) {
      ticketRequest.subTickets = []
      request = client.partialUpdate(ticketRequest)
    }
    await request
      .then(
        () => EventBus.$emit('tickets:refresh'),
        (err) => EventBus.$emit('tickets:error', err)
      )
      .finally(() => {
        this.submitting = false
        this.close()
      })
  }

  async getTicketDetail(): Promise<void> {
    if (this.activeTicketDetailId) {
      const response = await client.byId(this.activeTicketDetailId)
      this.ticket = response.data
      const res = await rclient.byId(this.ticket?.reservationId)
      this.selectedReservation = res.data || null
    } else if (this.reservation) {
      const reservationId = this.reservation?.reservationId
      this.ticket = { ...this.ticket, reservationId }
      this.selectedReservation = this.reservation
    }
  }

  async getTicketTypes(): Promise<void> {
    const response = await type.ticket()
    this.ticketTypes = response.data
  }

  async getTicketSeverityTypes(): Promise<void> {
    const response = await type.ticketSeverity()
    this.ticketSeverityTypes = response.data
  }

  async getTicketStatusTypes(): Promise<void> {
    const response = await type.ticketStatus()
    this.ticketStatusTypes = response.data
  }

  async getTicketAssigneeOptions(): Promise<void> {
    const response = await user.tableView({ pageSize: -1 })
    const driverGroup = userGroups.find((group) => {
      return group.groupId === UserGroupIds['DRIVER']
    })
    this.assigneeOptions = response.data.resultList.filter((assignee) => {
      return assignee.groupName !== driverGroup.label
    })
  }

  submitComment(comment: string): void {
    const newComment: SubTicket = {
      comments: comment,
      createdAt: new Date(),
      createdById: auth.userId,
    }
    if (this.isModeAdd) {
      this.comments.push(
        convertSubTicketToComment(newComment, formatFullName(auth.user))
      )
      return
    }
    const partialTicket: Partial<TicketDetailEntity> = {
      ticketId: this.ticket.ticketId,
      subTickets: [newComment],
    }
    client.partialUpdate(partialTicket).then(() => {
      this.comments.push(
        convertSubTicketToComment(newComment, formatFullName(auth.user))
      )
    })
  }

  getCommentAuthorUserDetails(): void {
    if (!this.activeTicketDetailId) {
      return
    }

    for (const subTicket of this.ticket.subTickets) {
      this.comments.push(convertSubTicketToComment(subTicket, ''))
    }

    const commentUserIds = new Set(this.comments.map((s) => s.createdById))
    for (const userId of commentUserIds) {
      user.byId(userId).then((result) => {
        for (const comment of this.comments) {
          if (comment.createdById === userId) {
            comment.fullName = formatFullName(result.data.user)
          }
        }
      })
    }
  }

  handleReservationChange(reservation: Reservation): void {
    const reservationId = reservation?.reservationId
    this.ticket = { ...this.ticket, reservationId }
    this.selectedReservation = reservation
  }

  get isTicketCloseable(): boolean {
    const closedStatus = this.ticketStatusTypes.find((status) => {
      return status.key === 'closed'
    })
    return (
      this.isModeEdit && this.ticket.ticketStatusTypeId !== closedStatus?.id
    )
  }

  closeTicket(): void {
    if (this.isModeAdd) {
      this.close()
      return
    }
    const closedStatus = this.ticketStatusTypes.find((status) => {
      return status.key === 'closed'
    })
    this.ticket.ticketStatusTypeId = closedStatus.id
    this.submit()
  }

  close(): void {
    sidebar.pop()
  }

  clear(): void {
    this.ticket = new TicketDetailEntity()
    this.comments = []
    this.submitting = false
    this.$refs.form['reset']()
  }

  @Watch('activeTicketDetailId')
  async onActiveTicketDetailIdChange(): Promise<void> {
    this.clear()
    await this.getTicketDetail()
    this.getCommentAuthorUserDetails()
  }

  async mounted(): Promise<void> {
    await this.getTicketDetail()
    await Promise.all([
      this.getTicketTypes(),
      this.getTicketSeverityTypes(),
      this.getTicketStatusTypes(),
      this.getTicketAssigneeOptions(),
    ])
    this.getCommentAuthorUserDetails()
    this.setReservationIdOptions()
    this.loading = false
  }
}
