
import { Vue, Component, Prop } from 'vue-property-decorator'
import state from '@/store/modules/reservation'
import CUCurrency from '@/components/CUCurrency.vue'
import tstate from '@/store/modules/types'
import auth from '@/store/modules/auth'
import ReservationPostTripChargeOvertime from '@/components/ReservationPostTripChargeOvertime.vue'
import ReservationPostTripChargeQuoted from '@/components/ReservationPostTripChargeQuoted.vue'
import HoldUpModal from '@/components/HoldUpModal.vue'
import { currencyFilter } from '@/utils/string'
import {
  ReservationAddChargeRequest,
  ReservationChargeRequest,
  ReservationReduceChargeRequest,
} from '@/models/dto/Reservation'
import rclient from '@/services/reservation'
import { EventBus } from '@/utils/eventBus'
import { formatMilesToLabel, formatMinutesToTimeLabel } from '@/utils/posttrip'
import { AxiosError } from 'axios'
import sidebar from '@/store/modules/sidebar'
import ReservationChargesSidebar from './ReservationChargesSidebar.vue'

@Component({
  components: {
    CUCurrency,
    ReservationPostTripChargeOvertime,
    ReservationPostTripChargeQuoted,
    HoldUpModal,
  },
})
export default class ReservationPostTripCharge extends Vue {
  @Prop({ required: true }) hourlyRate!: number
  @Prop({ required: true }) mileageRate!: number
  @Prop({ required: true }) dailyRate!: number

  pricingSelectionId = 1
  pricingSelectionTypes = tstate.pricingSelectionTypes
  selectedRateTypeKeys = []
  chargeMethod = 'Overtime'
  pendingCharge = null
  isConfirmChargeModalDisplayed = false
  isConfirmChargeModalLoading = false

  get totalBillableMileDifference(): number {
    const billingOverrides = state.billingOverrideView?.vehicles
    if (!billingOverrides) {
      return 0
    }

    const totalMileDifference = billingOverrides.reduce(
      (acc, curr) => acc + (curr.billableMileDifference || 0),
      0
    )

    return totalMileDifference
  }

  get totalBillableMinuteDifference(): number {
    const billingOverrides = state.billingOverrideView?.vehicles
    if (!billingOverrides) {
      return 0
    }

    const totalMinuteDifference = billingOverrides.reduce(
      (acc, curr) => acc + curr.billableMinuteDifference,
      0
    )

    return totalMinuteDifference
  }

  get differenceLabel(): string {
    if (
      !this.totalBillableMileDifference &&
      !this.totalBillableMinuteDifference
    ) {
      return '--'
    }

    const totalMinutes = this.totalBillableMinuteDifference
    const totalMiles = this.totalBillableMileDifference
    const timeLabel = totalMinutes
      ? formatMinutesToTimeLabel(totalMinutes, false)
      : ''

    const mileageLabel = totalMiles ? formatMilesToLabel(totalMiles, false) : ''
    return [timeLabel, mileageLabel].filter((label) => !!label).join(' | ')
  }

  get isOvertimeSelected(): boolean {
    return this.chargeMethod === 'Overtime'
  }

  get reservationPricingSelectionTypeId(): number {
    return state.billingOverrideView.pricingSelection?.id as number
  }

  get reservationOverageRateTypeKey(): string {
    return state.billingOverrideView?.overageRateType?.key
  }

  get reservationOverageRateAmount(): number {
    return state.billingOverrideView?.overageRate
  }

  get reservationBaseFareCharge(): number {
    return state.baseFareCharge
  }

  get confirmChargeText(): string {
    const charge = currencyFilter(Math.abs(this.pendingCharge))
    if (this.pendingCharge > 0) {
      return `Are you sure you want to add <b>${charge}</b> to the Base Fare?`
    } else {
      return `Are you sure you want to reduce the Base Fare by <b>${charge}</b>?`
    }
  }

  displayConfirmChargeModal(charge: number): void {
    this.pendingCharge = charge
    this.isConfirmChargeModalDisplayed = true
  }

  displayChargeSidebar(charge: number): void {
    const tripChargeType = tstate.tripChargeTypes.find(
      (c) => c.key === 'overtime'
    )
    charge = parseFloat(charge.toFixed(2))
    sidebar.push({
      component: ReservationChargesSidebar,
      title: charge < 0 ? 'Reduce Charge' : 'Add Charge',
      props: {
        reduceCharge: charge < 0,
        prefilledAmount: Math.abs(charge),
        prefilledChargeType: tripChargeType.id,
      },
      on: {
        'charges-sidebar:submit-success': () => {
          this.$emit('post-trip:charge-created')
        },
      },
    })
  }

  async confirmCharge(): Promise<void> {
    this.isConfirmChargeModalLoading = true
    const chargeIsNegative = this.pendingCharge < 0
    const chargeTypeKey = this.isOvertimeSelected
      ? 'overtime'
      : 'base_fare_adjustment'
    const tripChargeType = tstate.tripChargeTypes.find(
      (c) => c.key === chargeTypeKey
    )
    const payload: ReservationChargeRequest = {
      chargeType: tripChargeType?.id,
      companyId: auth.getCompanyId,
      reservationId: state.reservation.reservationId,
      customerNotes: {
        note: '',
        html: '',
      },
      notes: {
        note: '',
        html: '',
      },
      sendEmail: false,
      userId: auth.getUserId,
      confirmationEmails: [],
    }

    try {
      let res
      if (!chargeIsNegative) {
        const addChargePayload: ReservationAddChargeRequest = {
          ...payload,
          amount: String(Math.abs(this.pendingCharge)),
        }
        res = await rclient.addReservationCharge(addChargePayload)
      } else {
        const reduceChargePayload: ReservationReduceChargeRequest = {
          ...payload,
          reductionAmount: String(Math.abs(this.pendingCharge)),
        }
        res = await rclient.reduceReservationCharge(reduceChargePayload)
      }
      if (res.status === 200) {
        this.handleChargeCreated()
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        console.error(e.message)
      } else if (e && (e as AxiosError).response) {
        const axiosError = e as AxiosError
        console.error(axiosError.response.data.message)
        EventBus.$emit('snackbar:error', axiosError.response.data.message)
      } else {
        console.error('An unknown error occurred')
      }
    } finally {
      this.isConfirmChargeModalLoading = false
    }
  }

  handleChargeCreated(): void {
    this.pendingCharge = null
    this.isConfirmChargeModalDisplayed = false
    EventBus.$emit('snackbar:success', 'Charge added successfully!')
    this.$emit('post-trip:charge-created')
  }

  mounted(): void {
    // Set whether the toggle is overtime or quoted
    const postTripIsQuoted = !!auth.getCompany.defaultPostTripIsQuotedRate
    if (postTripIsQuoted) {
      this.chargeMethod = 'Quoted'
    }
  }
}
