
import {
  AffiliateOffer,
  AffiliateOfferWithContactInfo,
} from '@/models/dto/Affiliate'
import { Vue, Component, Prop } from 'vue-property-decorator'
import affiliateService from '@/services/affiliate'
import { EventBus } from '@/utils/eventBus'
import sidebar from '@/store/modules/sidebar'
import { parseAxiosError } from '@/utils/error'
import SendToAffiliateSidebar from './SendToAffiliateSidebar.vue'

@Component({})
export default class SimpleTableAffiliateActionCell extends Vue {
  @Prop({ required: true, default: null }) item: AffiliateOfferWithContactInfo

  handleEdit(): void {
    sidebar.popAllAndPush({
      component: SendToAffiliateSidebar,
      props: {
        title: 'Edit Offer',
        affiliateOfferId: this.item.id,
        affiliateCustomerId: this.item.customerId,
        isEdit: true,
      },
      persist: true,
      on: {
        cancel: sidebar.pop,
        submit: this.handleEditAffiliateOffer,
      },
    })
  }

  async handleEditAffiliateOffer(offerData: AffiliateOffer): Promise<void> {
    try {
      if (offerData.profit === null) {
        offerData.profit = 0
      }

      offerData.vehicleDetails = offerData.vehicleDetails.map((vehicle) => {
        vehicle.affiliateOfferId = offerData.affiliateOfferId
        vehicle.id = null
        return vehicle
      })

      await affiliateService.editAffiliateOffer(offerData)
      const message = offerData.sendConfirmationToAffiliate
        ? 'Sent to affiliate successfully'
        : 'Offer saved successfully'
      EventBus.$emit('snackbar:success', message)
      EventBus.$emit('refresh-reservation')
    } catch (e: any) {
      EventBus.$emit('snackbar:error', parseAxiosError(e))
    } finally {
      sidebar.pop()
    }
  }

  handleDuplicate(): void {
    sidebar.popAllAndPush({
      component: SendToAffiliateSidebar,
      persist: true,
      props: {
        title: 'Duplicate Offer',
        affiliateOfferId: this.item.id,
        affiliateCustomerId: this.item.customerId,
      },
      on: {
        cancel: sidebar.pop,
        submit: (offerData: AffiliateOffer) =>
          this.handleDuplicateAffiliateOffer(offerData),
      },
    })
  }

  async handleDelete(): Promise<void> {
    try {
      await affiliateService.deleteAffiliateOffer({
        affiliateOfferId: this.item.id,
        reservationId: this.item.reservationId,
      })
      EventBus.$emit('snackbar:success', 'Affiliate offer deleted')
      EventBus.$emit('refresh-reservation')
    } catch (e) {
      console.log(e)
      EventBus.$emit('snackbar:error', 'Failed to delete affiliate offer')
    }
  }

  async handleDuplicateAffiliateOffer(
    offerData: AffiliateOffer
  ): Promise<void> {
    offerData.affiliateOfferId = null
    offerData.id = null
    offerData.vehicleDetails.map((vehicle) => {
      vehicle.id = null
      vehicle.affiliateOfferId = null

      return vehicle
    })

    if (offerData.profit === null) {
      offerData.profit = 0
    }

    try {
      await affiliateService.sendAffiliateOffer(offerData)
      const message = offerData.sendConfirmationToAffiliate
        ? 'Sent to affiliate successfully'
        : 'Offer saved successfully'
      EventBus.$emit('snackbar:success', message)
      EventBus.$emit('refresh-reservation')
    } catch (e: any) {
      EventBus.$emit('snackbar:error', parseAxiosError(e))
    } finally {
      sidebar.pop()
    }
  }
}
