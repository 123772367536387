var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex align-center h-64"},[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('CUTextField',{staticClass:"max-w-152 w-152",attrs:{"value":_vm.overtimeRateDisplay,"placeholder":"Overtime Rate","hide-details":"","label":_vm.overtimeRateValue !== null ? 'Overtime Rate' : null},on:{"change":_vm.handleOvertimeRateInput}}),_c('CUSelect',{staticClass:"max-w-128 w-128 margin-l-4",attrs:{"value":_vm.perTypeValue,"label":"Per","hide-details":"","items":[
        { text: 'Hour', value: 'hourly_overtime_rate' },
        { text: 'Day', value: 'daily_overage_rate' },
        { text: 'Mile', value: 'mileage_overage_rate' },
      ]},on:{"input":_vm.handlePerTypeValueInput}}),_c('CUTextField',{staticClass:"max-w-80 w-80 margin-l-4",attrs:{"value":_vm.countValue,"placeholder":"Count","hide-details":"","label":_vm.countValue !== null ? 'Count' : null},on:{"change":_vm.handleCountValueInput}})],1),_c('div',{staticClass:"h-60 border-solid border-l-1 border-r-0 border-y-0 border-bg-gray-3 margin-x-8"}),_c('div',{staticClass:"d-flex align-center"},[_c('CUTextField',{staticClass:"max-w-128 w-128",attrs:{"value":_vm.overtimeChargeDisplay,"placeholder":"Overtime Charge","hide-details":"","label":_vm.overtimeChargeValue !== null ? 'Overtime Charge' : null},on:{"change":_vm.handleOvertimeChargeInput}}),_c('CUButton',{staticClass:"margin-l-6",attrs:{"color":"primary","width":"112","disabled":!_vm.overtimeChargeValue},on:{"click":function($event){return _vm.$emit('confirm-charge', _vm.overtimeChargeValue)}}},[_c('div',{staticClass:"d-flex align-center"},[(_vm.overtimeChargeValue < 0)?_c('CUIcon',{key:`minus-${_vm.overtimeChargeValue === 0}`,attrs:{"color":_vm.overtimeChargeValue === 0 ? 'gray-text-mid' : 'white',"width":"20","height":"20"}},[_vm._v(" minus ")]):_c('CUIcon',{key:`add-${_vm.overtimeChargeValue === 0}`,attrs:{"color":_vm.overtimeChargeValue === 0 ? 'gray-text-mid' : 'white',"width":"20","height":"20"}},[_vm._v(" add ")]),_c('span',{staticClass:"margin-l-2",class:{
            'text-gray-text-mid': _vm.overtimeChargeValue === 0,
            'text-white': _vm.overtimeChargeValue !== 0,
          }},[_vm._v(" "+_vm._s(_vm.chargeButtonLabel)+" ")])],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }