
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator'
import AutocompleteAddress from '@/components/AutocompleteAddress.vue'
import { Garage } from '@/models/dto/Garage'
import { GarageTimes, GarageError } from '@/models/dto/GarageTimes'
import { ref } from 'vue'
import {
  arrivalTimeOffsetList,
  convertDurationToMinutesInteger,
  formatSecondsToDuration,
} from '@/utils/time'
import dayjs from 'dayjs'
import auth from '@/store/modules/auth'
import types from '@/store/modules/types'
import sidebar from '@/store/modules/sidebar'
import { Note } from '@/models/dto/Note'
import StopNotesSidebar from './StopNotesSidebar.vue'
import { StopEstimation, Trip, TripEstimation } from '@/models/dto'
import quote from '@/store/modules/quote'
import { getMinDate, getMaxDate } from '@/utils/getDates'
import reservation from '@/store/modules/reservation'

@Component({
  components: { AutocompleteAddress },
})
export default class TripDetailsGarageCard extends Vue {
  @Prop({ required: false }) readonly trip: Trip
  @Prop({ type: Boolean, default: false }) readonly returning!: boolean
  @Prop({ type: Boolean, default: false })
  readonly canCalculateGarageEstimation!: boolean
  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean
  @Prop({ default: undefined, type: String })
  readonly defaultDate!: string
  @Prop({ default: () => ({}), type: Object })
  readonly garageError!: GarageError
  @Prop({ required: false }) readonly tripEstimation!: TripEstimation

  @Inject({ default: false }) isReservationTrip: boolean

  editNote = false
  editArrivalTime = false
  ts = ''
  tsDate = ''
  tsTime = ''
  arrivalTimeOffsetList = arrivalTimeOffsetList
  arrivalTimeOffset = null
  typeState = types
  formatSecondsToDuration = formatSecondsToDuration

  @Watch('garageTime', { immediate: true })
  handleUpdateGarageTime(): void {
    if (this.garageTime) {
      this.tsTime = dayjs(this.garageTime).tz(this.timeZone).format('HH:mm')
      this.tsDate = dayjs(this.garageTime)
        .tz(this.timeZone)
        .format('YYYY-MM-DD')
    }
  }

  get datePickerHasError(): boolean {
    return !!this.datePickerErrorMessage.length
  }

  get missingGarage(): boolean {
    return this.returning
      ? !!this.garageError.return.isGarageMissing
      : !!this.garageError.depart.isGarageMissing
  }

  get datePickerErrorMessage(): string[] {
    if (this.returning) {
      if (this.garageError.return.isInvalidDatetime && !this.tsDate) {
        return ['Date required']
      }

      if (this.garageError.return.isDateOutOfOrder) {
        return ['Out of order']
      }
      return []
    } else {
      if (this.garageError.depart.isInvalidDatetime && !this.tsDate) {
        return ['Date required']
      }
      if (this.garageError.depart.isDateOutOfOrder) {
        return ['Out of order']
      }
      return []
    }
  }

  get arrivalTimePickerHasError(): boolean {
    if (this.returning) {
      const { isDateOutOfOrder, isTimeOutOfOrder } =
        this.garageError.return || {}
      return isDateOutOfOrder || isTimeOutOfOrder
    } else {
      const { isPreTripArrivalTimeInvalid } = this.garageError.depart || {}
      return isPreTripArrivalTimeInvalid
    }
  }

  get arrivalTimePickerErrorMessage(): string[] {
    if (!this.returning) {
      const { isPreTripArrivalTimeInvalid } = this.garageError.depart
      if (isPreTripArrivalTimeInvalid) {
        return ['Out of order']
      }

      return []
    }

    // Returning garage
    const { isInvalidDatetime, isDateOutOfOrder, isTimeOutOfOrder } =
      this.garageError.return
    if (isInvalidDatetime) {
      return ['Time required']
    }

    if (!isDateOutOfOrder && isTimeOutOfOrder) {
      return ['Out of order']
    }

    return []
  }

  get departTimePickerHasError(): boolean {
    const { isDateOutOfOrder, isTimeOutOfOrder } = this.garageError.depart || {}
    return isDateOutOfOrder || isTimeOutOfOrder
  }

  get totalEstimationTime(): string {
    return this.getTotalEstimationTime() > 0
      ? this.formatSecondsToDuration(this.getTotalEstimationTime())
      : this.timeFromPrevious
  }

  get totalEstimationDistance(): number {
    return this.getTotalDistanceFromPrevious(
      this.tripEstimation.stopEstimations.length - 1
    )
  }

  get departTimePickerErrorMessage(): string[] {
    if (this.returning) {
      if (this.garageError.return.isTimeOutOfOrder) {
        return ['Out of order']
      }

      return []
    }

    const { isInvalidDatetime, isDateOutOfOrder, isTimeOutOfOrder } =
      this.garageError.depart

    if (isInvalidDatetime && !this.tsTime) {
      return ['Time required']
    }

    if (!isDateOutOfOrder && isTimeOutOfOrder) {
      return ['Out of order']
    }

    return []
  }

  get value(): GarageTimes {
    return this.trip.garageTimes
  }

  get stopType(): string {
    return !this.returning ? 'Departing' : 'Returning'
  }

  get timeFromPreviousStop(): StopEstimation {
    const index = this.tripEstimation?.garageEstimations?.length
    return this.tripEstimation?.garageEstimations[index - 1]
  }

  get showStopPreviewTime(): boolean {
    return !!(
      this.returning &&
      this.tripEstimation?.garageEstimations[1]?.distanceFromPrevious
    )
  }

  get lastStopOrder(): number {
    return this.tripEstimation?.stopEstimations?.length || 0
  }

  get garageId(): number {
    return !this.returning ? this.value?.garageId : this.value?.returnGarageId
  }

  get garageTime(): string {
    const ts = !this.returning
      ? this.value?.departureTime
      : this.value?.returnTime
    return ts && ts !== 'invalid' ? ts : ''
  }

  get arrivalTime(): string {
    const arrivalTime = this.value?.preTripArrivalTime
    return !arrivalTime
      ? ''
      : dayjs(arrivalTime).tz(this.timeZone).format('HH:mm')
  }

  getTotalEstimationTime(): number {
    const { tripEstimation, isReservationTrip } = this
    const index = tripEstimation?.stopEstimations.length

    const trip = isReservationTrip
      ? reservation.trip
      : quote.trips?.find(({ tripId }) => tripId === tripEstimation.tripId)

    let seconds = 0

    for (let i = 0; i <= index; i++) {
      const previousStop =
        i === 0
          ? trip?.garageTimes?.departureTime
          : getMaxDate(
              trip?.stops[i - 1].spotTime?.spotTime,
              trip?.stops[i - 1].pickupDatetime,
              trip?.stops[i - 1].dropoffDatetime
            )
      const currentStop =
        index === i
          ? trip?.garageTimes?.returnTime
          : getMinDate(
              trip?.stops[i].spotTime?.spotTime,
              trip?.stops[i].pickupDatetime,
              trip?.stops[i].dropoffDatetime
            )

      seconds +=
        previousStop && currentStop
          ? dayjs(currentStop).diff(previousStop)
          : null
    }
    return seconds / 1000
  }

  getTotalDistanceFromPrevious(index: number): number {
    let totalDistance =
      this.tripEstimation?.garageEstimations?.[0].distanceFromPrevious || 0

    for (let i = 0; i <= index; i++) {
      const stopEstimation = this.tripEstimation?.stopEstimations[i]
      totalDistance += stopEstimation?.distanceFromPrevious
    }

    totalDistance +=
      this.tripEstimation?.garageEstimations?.[1].distanceFromPrevious

    return Math.round(totalDistance)
  }

  get timeFromPrevious(): string {
    const { tripEstimation, isReservationTrip } = this
    const index = tripEstimation?.stopEstimations.length || 0

    const trip = isReservationTrip
      ? reservation.trip
      : quote.trips?.find(({ tripId }) => tripId === tripEstimation.tripId)

    const currentStop = trip?.garageTimes?.returnTime

    const previousStop =
      index === 0
        ? trip?.garageTimes?.departureTime
        : getMaxDate(
            trip?.stops[index].spotTime?.spotTime,
            trip?.stops[index].pickupDatetime,
            trip?.stops[index].dropoffDatetime
          )

    const seconds =
      previousStop && currentStop ? dayjs(currentStop).diff(previousStop) : null

    return formatSecondsToDuration(seconds / 1000)
  }

  handleAddNotes(): void {
    const note = this.returning
      ? this.value?.htmlReturnGarageTimeNotes ||
        this.value?.returnGarageTimeNotes
      : this.value?.htmlGarageTimeNotes || this.value?.garageTimeNotes
    sidebar.push({
      component: StopNotesSidebar,
      props: {
        trip: this.trip,
        id: this.returning ? this.value.returnGarageId : this.value.garageId,
        note: new Note({ html: note }),
        isGarage: true,
        isReturning: this.returning,
      },
      on: {
        submit: (note: Note) =>
          this.$emit(
            'input-silent',
            this.returning
              ? {
                  ...this.value,
                  htmlReturnGarageTimeNotes: note.html,
                  returnGarageTimeNotes: note.note,
                }
              : {
                  ...this.value,
                  htmlGarageTimeNotes: note.html,
                  garageTimeNotes: note.note,
                }
          ),
      },
    })
  }

  get notes(): string {
    const notes = !this.returning
      ? this.value?.htmlGarageTimeNotes || this.value?.garageTimeNotes
      : this.value?.htmlReturnGarageTimeNotes ||
        this.value?.returnGarageTimeNotes
    return notes || ''
  }

  get timeZone(): string {
    const garage = this.returning ? this.value.returnGarage : this.value.garage
    return garage?.address?.timeZone || auth.getUserTimeZone
  }

  @Watch('tsDate')
  onDateChange(newDate: string, oldDate: string): void {
    if (!oldDate && !!newDate && !this.returning) {
      this.setDefaultPreTripArrivalTime()
    }
  }

  handleCardMouseEnter(): void {
    if (
      (!this.returning && this.value?.garageTimeNotes) ||
      (this.returning && this.value?.returnGarageTimeNotes)
    ) {
      this.editNote = !this.disabled
    }
  }

  handleCardMouseLeave(): void {
    this.editNote = false
  }

  handleGarageChange(id: number): void {
    const garage = this.garages().find((g) => g.garageId === id)
    const oldGarage = !this.returning
      ? this.value?.garage
      : this.value?.returnGarage
    const oldTimeZone = oldGarage?.address?.timeZone || auth.getUserTimeZone
    const newTimeZone = garage?.address?.timeZone || auth.getUserTimeZone

    let updatedGarageTimes = { ...this.value }

    if (this.value?.departureTime && !this.returning) {
      const departureTimeCalendar = dayjs(this.value.departureTime)
        .tz(oldTimeZone)
        .tz(newTimeZone, true)
      updatedGarageTimes = {
        ...updatedGarageTimes,
        departureTime: dayjs(departureTimeCalendar).isValid()
          ? departureTimeCalendar.toISOString()
          : 'invalid',
      }
    }

    if (this.value?.preTripArrivalTime && !this.returning) {
      const preTripArrivalTimeCalendar = dayjs(this.value.preTripArrivalTime)
        .tz(oldTimeZone)
        .tz(newTimeZone, true)
      updatedGarageTimes = {
        ...updatedGarageTimes,
        preTripArrivalTime: dayjs(preTripArrivalTimeCalendar).isValid()
          ? preTripArrivalTimeCalendar.toISOString()
          : 'invalid',
      }
    }

    if (this.value?.returnTime && this.returning) {
      const returnTimeCalendar = dayjs(this.value.returnTime)
        .tz(oldTimeZone)
        .tz(newTimeZone, true)
      updatedGarageTimes = {
        ...updatedGarageTimes,
        returnTime: dayjs(returnTimeCalendar).isValid()
          ? returnTimeCalendar.toISOString()
          : 'invalid',
      }
    }

    if (!this.returning) {
      updatedGarageTimes = {
        ...updatedGarageTimes,
        garage,
        garageId: id,
        garageTimeNotes: garage.defaultGarageNotes,
      }
    } else {
      updatedGarageTimes = {
        ...updatedGarageTimes,
        returnGarage: garage,
        returnGarageId: id,
        returnGarageTimeNotes: garage.defaultGarageNotes,
      }
    }

    this.$emit('input', updatedGarageTimes)
  }

  handleDateChange(date: string): void {
    let updatedDatetime = null
    this.tsDate = date
    let updatedGarageTimes = { ...this.value }

    if (!date) {
      if (!this.returning) {
        updatedGarageTimes = {
          ...updatedGarageTimes,
          preTripArrivalTime: null,
          departureTime: null,
        }
        this.arrivalTimeOffset = null
        this.editArrivalTime = false
      } else {
        updatedGarageTimes = {
          ...updatedGarageTimes,
          returnTime: null,
        }
      }

      this.tsDate = null
      this.tsTime = null
      this.$emit('garage-card:reset-errors')
      this.$emit('input', updatedGarageTimes)
      return
    }

    if (date && this.tsTime) {
      const ts = dayjs(date, 'YYYY-MM-DD')

      updatedDatetime = dayjs(this.garageTime || dayjs(this.tsTime, 'HH:mm'))
        .set('year', ts.year())
        .set('month', ts.month())
        .set('date', ts.date())
        .tz(this.timeZone)
    } else {
      updatedDatetime = 'invalid'
    }

    const updatedISO =
      updatedDatetime && updatedDatetime !== 'invalid'
        ? updatedDatetime.toISOString()
        : null
    if (!this.returning) {
      updatedGarageTimes.departureTime = updatedISO
    } else {
      updatedGarageTimes.returnTime = updatedISO
    }

    if (this.arrivalTime && !this.returning && date && this.tsTime) {
      const preTripArrivalTime = updatedDatetime.subtract(
        this.arrivalTimeOffset,
        'minutes'
      )
      updatedGarageTimes.preTripArrivalTime = preTripArrivalTime.toISOString()
    }

    this.$emit('garage-card:reset-errors')
    this.$emit('input', updatedGarageTimes)
  }

  handleTimeChange(time: string): void {
    let updatedDatetime = null
    this.tsTime = time

    if (time && this.tsDate) {
      const ts = dayjs(time, 'HH:mm')

      updatedDatetime = dayjs(
        this.garageTime || dayjs(this.tsDate, 'YYYY-MM-DD')
      )
        .tz(this.timeZone)
        .set('hour', ts.hour())
        .set('minute', ts.minute())
        .tz(this.timeZone, true)
    } else {
      updatedDatetime = 'invalid'
    }

    const updatedGarageTimes = { ...this.value }
    const updatedISO =
      updatedDatetime && updatedDatetime !== 'invalid'
        ? updatedDatetime.toISOString()
        : updatedDatetime
    if (!this.returning) {
      updatedGarageTimes.departureTime = updatedISO
    } else {
      updatedGarageTimes.returnTime = updatedISO
    }

    if (this.arrivalTime && !this.returning && time && this.tsDate) {
      const preTripArrivalTime = updatedDatetime.subtract(
        this.arrivalTimeOffset,
        'minutes'
      )
      updatedGarageTimes.preTripArrivalTime = preTripArrivalTime.toISOString()
    } else if (
      !this.returning &&
      time &&
      this.tsDate &&
      auth.company.defaultPreTripArrivalTime
    ) {
      this.arrivalTimeOffset = convertDurationToMinutesInteger(
        auth.company.defaultPreTripArrivalTime
      )
      const preTripArrivalTime = updatedDatetime.subtract(
        this.arrivalTimeOffset,
        'minutes'
      )
      updatedGarageTimes.preTripArrivalTime = preTripArrivalTime.toISOString()
    }

    this.$emit('garage-card:reset-errors')
    this.$emit('input', updatedGarageTimes)
  }

  handlePreTripArrivalTimeChange(time: string): void {
    let updatedGarageTimes = { ...this.value }

    if (time) {
      const timeClock = dayjs(time, 'HH:mm').tz(this.timeZone, true)
      const existingGarageTime = dayjs(this.garageTime).tz(this.timeZone)

      const preTripArrivalTimeClock = existingGarageTime
        .set('hour', timeClock.hour())
        .set('minute', timeClock.minute())

      const departureTime = preTripArrivalTimeClock
        .add(this.arrivalTimeOffset, 'minutes')
        .toISOString()

      updatedGarageTimes = {
        ...updatedGarageTimes,
        preTripArrivalTime: preTripArrivalTimeClock.toISOString(),
        departureTime,
      }
    } else {
      updatedGarageTimes = {
        ...updatedGarageTimes,
        preTripArrivalTime: null,
      }
    }

    this.$emit('input', updatedGarageTimes)
    this.$emit('garage-card:reset-errors')
  }

  handlePreTripArrivalTimeOffsetChange(offset: number): void {
    let updatedPreTripArrivalTime = null
    const departureTime = this.value.departureTime

    if (offset && this.value.departureTime) {
      const preTripArrivalTimeObject = dayjs(departureTime)
        .tz(this.timeZone)
        .subtract(offset, 'minutes')
      if (
        !preTripArrivalTimeObject.isAfter(
          dayjs(departureTime).tz(this.timeZone)
        )
      ) {
        updatedPreTripArrivalTime = preTripArrivalTimeObject.toISOString()
      }
    }

    this.$emit('input', {
      ...this.value,
      preTripArrivalTime: updatedPreTripArrivalTime,
    })
    this.$emit('garage-card:reset-errors')
  }

  handleNoteChange(note: string): void {
    if (!this.returning) {
      this.$emit('input', { ...this.value, garageTimeNotes: note })
    } else {
      this.$emit('input', { ...this.value, returnGarageTimeNotes: note })
    }
  }

  handleSetCurrentTime(): void {
    this.setDatetime(dayjs().toISOString())
  }

  handleUndo(): void {
    this.tsTime = ''
    this.tsDate = ''
    if (!this.returning) {
      this.$emit('input', {
        ...this.value,
        garage: null,
        garageId: null,
        departureTime: null,
        preTripArrivalTime: null,
        garageTimeNotes: null,
        htmlGarageTimeNotes: null,
      })
    } else {
      this.$emit('input', {
        ...this.value,
        returnGarage: null,
        returnGarageId: null,
        returnTime: null,
        returnGarageTimeNotes: null,
        htmlReturnGarageTimeNotes: null,
      })
    }
    this.$emit('garage-card:reset-errors')
  }

  handleDeleteArrivalTime(): void {
    this.editArrivalTime = false
    this.arrivalTimeOffset = null
    this.$emit('input', { ...this.value, preTripArrivalTime: null })
    this.$emit('garage-card:reset-errors')
  }

  handleCalculateGarageEstimation(): void {
    this.$emit('calculate-garage-estimation', this.returning)
    this.$emit('garage-card:reset-errors')
  }

  setDefaultPreTripArrivalTime(): void {
    const { defaultPreTripArrivalTime } = auth.getCompany
    if (!defaultPreTripArrivalTime) {
      return
    }
    const preTripArrivalTime = convertDurationToMinutesInteger(
      defaultPreTripArrivalTime
    )

    this.arrivalTimeOffset = preTripArrivalTime
    this.handlePreTripArrivalTimeOffsetChange(preTripArrivalTime)
  }

  setDatetime(datetime: string): void {
    const ts = !datetime ? '' : dayjs(datetime).tz(dayjs.tz.guess(), true)
    if (!this.returning) {
      this.$emit('input', { ...this.value, departureTime: ts })
    } else {
      this.$emit('input', { ...this.value, returnTime: ts })
    }
  }

  garages(): Garage[] {
    return this.typeState.garages
  }

  get isLoadingGarages(): boolean {
    return this.typeState.loadingInformation.isLoadingGarages
  }

  get lg(): boolean {
    return this.$vuetify.breakpoint.width >= 1500
  }

  mounted(): void {
    if (!this.returning) {
      this.ts = this.value?.departureTime || ''
    } else {
      this.ts = this.value?.returnTime || ''
    }

    this.arrivalTimeOffset = dayjs(this.garageTime).diff(
      this.value?.preTripArrivalTime,
      'minutes'
    )
  }
}
