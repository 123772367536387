
import { Component, Prop, Vue } from 'vue-property-decorator'
import { currencyFilter } from '@/utils/string'

@Component({})
export default class TripPricingTotalFooter extends Vue {
  @Prop({ required: false, default: '' }) readonly label!: string
  @Prop({ required: false, default: '' }) readonly message!: string
  @Prop({ required: false, default: 0 }) readonly total!: number
  @Prop({ required: false, default: 0 }) readonly leadingColspan!: number
  @Prop({ required: false, default: () => currencyFilter })
  readonly formatter!: (_: number) => string
}
