import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { apiBaseUrl } from '@/utils/env'
import {
  BillingOverride,
  BillingOverrideResult,
  BillingOverrideVehicleView,
  BillingOverrideViewResult,
} from '@/models/BillingOverride'

const httpService: HttpService = new HttpService()

export default {
  create(tripId: number): Promise<AxiosResponse<BillingOverrideResult>> {
    const url = `https://${apiBaseUrl()}/billingOverrides/create/${tripId}`
    return httpService.post(url, {})
  },
  view(tripId: number): Promise<AxiosResponse<BillingOverrideViewResult>> {
    const url = `https://${apiBaseUrl()}/billingOverrides/view/${tripId}`
    return httpService.get(url, {})
  },
  update(
    payload: BillingOverride[] | BillingOverrideVehicleView[]
  ): Promise<AxiosResponse<BillingOverrideResult>> {
    const url = `https://${apiBaseUrl()}/billingOverrides`
    return httpService.put(url, payload)
  },
  delete(tripId: number): Promise<AxiosResponse<BillingOverrideResult>> {
    const url = `https://${apiBaseUrl()}/billingOverrides/${tripId}`
    return httpService.delete(url)
  },
}
