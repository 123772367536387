var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex align-center justify-space-between h-64"},[_c('div',{staticClass:"d-flex align-center justify-center"},_vm._l((_vm.rateTypes),function(type,typeIndex){return _c('div',{key:`base-fare-${type.key}-${typeIndex}`,staticClass:"margin-l-5"},[_c('p',{staticClass:"text-text-gray-3 font-bold text-center"},[_vm._v(" "+_vm._s(type.label)+" ")]),_c('div',[_c('CUChip',{attrs:{"border":"green","background":_vm.isRateTypeSelected(type) ? 'white' : '',"value":_vm.isRateTypeSelected(type)},on:{"input":function($event){return _vm.handleRateToggled(type)}}},[_c('CUCurrency',{attrs:{"value":_vm.getRateByTypeKey(type.key),"unimportant":!_vm.isRateTypeSelected(type)}})],1)],1)])}),0),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"h-60 border-solid border-l-1 border-r-0 border-y-0 border-bg-gray-3 margin-x-4"}),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"margin-r-1"},[_c('div',{staticClass:"d-flex align-center margin-b-1"},[_c('p',{staticClass:"font-bold font-14 text-text-gray-3 w-52 margin-r-1"},[_vm._v(" Quoted ")]),_c('p',[_vm._v(_vm._s(_vm.quotedBaseFareDisplay))])]),_c('div',{staticClass:"d-flex align-center min-w-152"},[_c('p',{staticClass:"font-bold font-14 text-text-gray-3 w-52 margin-r-1"},[_vm._v(" Actual ")]),_c('p',{class:{
              'text-secondary': _vm.displayDifferenceAsPositive,
              'text-error': _vm.displayDifferenceAsNegative,
            }},[_vm._v(" "+_vm._s(_vm.actualBaseFareLabel)+" ")]),(_vm.displayDifferenceAsPositive)?_c('span',{staticClass:"h-24"},[_c('CUIcon',{key:"icon-up",attrs:{"color":"secondary"}},[_vm._v("drop_arrow_up")])],1):(_vm.displayDifferenceAsNegative)?_c('span',{staticClass:"h-24"},[_c('CUIcon',{key:"icon-down",attrs:{"color":"error"}},[_vm._v("drop_arrow_down")])],1):_c('div',{staticClass:"w-24 h-24"})])]),_c('CUTextField',{key:"base-fare-charge",staticClass:"w-148 margin-r-4 cu-text-field--prepend-inner-value",attrs:{"value":_vm.baseFareAdjustmentDisplay,"hide-details":"","label":_vm.baseFareAdjustmentValue !== null ? 'Base Fare Charge' : null},on:{"change":_vm.handleBaseFareAdjustmentValueUpdate},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('span',{style:({ marginTop: '13px', minWidth: '9px' })},[_vm._v(" "+_vm._s(_vm.baseFareAdjustmentValue >= 0 ? '+' : '-')+" ")])]},proxy:true}])}),_c('CUButton',{attrs:{"color":"primary","width":"112","disabled":_vm.baseFareAdjustmentValue === 0},on:{"click":function($event){return _vm.$emit('confirm-charge', _vm.baseFareAdjustmentValue)}}},[_c('div',{staticClass:"d-flex align-center"},[(_vm.baseFareAdjustmentValue < 0)?_c('CUIcon',{key:`minus-${_vm.baseFareAdjustmentValue === 0}`,attrs:{"color":_vm.baseFareAdjustmentValue === 0 ? 'gray-text-mid' : 'white',"width":"20","height":"20"}},[_vm._v(" minus ")]):_c('CUIcon',{key:`add-${_vm.baseFareAdjustmentValue === 0}`,attrs:{"color":_vm.baseFareAdjustmentValue === 0 ? 'gray-text-mid' : 'white',"width":"20","height":"20"}},[_vm._v(" add ")]),_c('span',{staticClass:"margin-l-2",class:{
              'text-gray-text-mid': _vm.baseFareAdjustmentValue === 0,
              'text-white': _vm.baseFareAdjustmentValue !== 0,
            }},[_vm._v(" "+_vm._s(_vm.chargeButtonLabel)+" ")])],1)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }