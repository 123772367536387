import { render, staticRenderFns } from "./ReservationPostTripCharge.vue?vue&type=template&id=58cb2540"
import script from "./ReservationPostTripCharge.vue?vue&type=script&lang=ts"
export * from "./ReservationPostTripCharge.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports