
import { Component, Prop, Vue } from 'vue-property-decorator'
import { SimpleTableColumn } from '@/models/SimpleTableColumn'
import { currencyFilter } from '@/utils/string'
import CUSimpleTable from '@/components/CUSimpleTable.vue'
import sidebar from '@/store/modules/sidebar'
import TripPricingChargeForm from '@/components/TripPricingChargeForm.vue'
import TripPricingChargeSelector from '@/components/TripPricingChargeSelector.vue'
import { Trip, TripEstimation } from '@/models/dto'
import {
  LineItemCharge,
  LineItemSectionType,
} from '@/models/dto/LineItemCharge'
import {
  isPercent,
  lineItemChargeAmount,
  lineItemChargeBaseAmount,
} from '@/utils/charge'
import { LineItemChargeTypes } from '@/utils/enum'
import CUSimpleTableActionsCell from '@/components/CUSimpleTableActionsCell.vue'

@Component({
  components: { CUSimpleTableActionsCell, CUSimpleTable },
})
export default class TripPricingCharges extends Vue {
  @Prop({ required: true }) readonly value!: Trip
  @Prop({ required: true }) readonly tripEstimation!: TripEstimation
  @Prop({ required: false }) readonly section: LineItemSectionType
  @Prop({ required: false, default: '' }) readonly title!: string
  @Prop({ required: false, default: '' }) readonly name!: string
  @Prop({ type: Boolean, default: false }) readonly readOnly!: boolean
  @Prop({ type: Boolean, default: false }) readonly showFooterLine!: boolean

  get lineItemCharges(): LineItemCharge[] {
    return this.value.lineItemCharges || []
  }

  get lineItemChargesFiltered(): LineItemCharge[] {
    if (!this.section) {
      return []
    }
    return this.lineItemCharges.filter(
      (c) => c.lineItemSectionType?.key === this.section?.key
    )
  }

  get sum(): number {
    return this.lineItemChargesFiltered
      .filter((c) => c.isActive)
      .map((c) =>
        lineItemChargeAmount(c, lineItemChargeBaseAmount(c, this.value))
      )
      .reduce((x, y) => x + y, 0)
  }

  isPercentage(charge: LineItemCharge): boolean {
    const type = LineItemChargeTypes.PERCENTAGE
    return charge.lineItemChargeType?.key === type
  }

  chargeName(charge: LineItemCharge): string {
    return charge.name || '--'
  }

  chargeType(charge: LineItemCharge): string {
    return charge.lineItemChargeType?.label || '--'
  }

  chargeRate(charge: LineItemCharge): string {
    return this.isPercentage(charge)
      ? `${charge.percentage}%`
      : currencyFilter(charge.rate, true)
  }

  chargeQuantity(charge: LineItemCharge): number {
    return charge.quantity || 1
  }

  chargeAmount(charge: LineItemCharge): string {
    const baseAmount = lineItemChargeBaseAmount(charge, this.value)
    const amount = lineItemChargeAmount(charge, baseAmount)
    return charge.isActive ? currencyFilter(amount, true) : '--'
  }

  handleChargeAdd(): void {
    sidebar.push({
      component: TripPricingChargeSelector,
      props: {
        trip: this.value,
        tripEstimation: this.tripEstimation,
        section: this.section,
        name: this.name,
      },
      on: {
        submit: this.handleSelectionSubmit,
        cancel: this.handleSubmissionCancel,
      },
    })
  }

  handleChargeEdit(
    lineItemCharge: LineItemCharge,
    lineItemChargeIdx: number
  ): void {
    sidebar.push({
      title: `Edit ${this.name}`,
      component: TripPricingChargeForm,
      props: {
        isEditTax: this.name === 'Tax',
        trip: this.value,
        tripEstimation: this.tripEstimation,
        lineItemCharge,
        lineItemChargeIdx,
        hideDefaultSelector: true,
      },
      on: {
        update: this.handleChargeUpdate,
        cancel: this.handleSubmissionCancel,
      },
    })
  }

  handleChargeDelete(_: LineItemCharge, lineItemChargeIdx: number): void {
    const selected = this.lineItemChargesFiltered[lineItemChargeIdx]
    const idx = this.lineItemCharges.indexOf(selected)
    let lineItemCharges = [...this.lineItemCharges]
    lineItemCharges.splice(idx, 1)
    lineItemCharges = lineItemCharges.map((c, lineNumber) => ({
      ...c,
      lineNumber,
    }))
    this.$emit('input', { ...this.value, lineItemCharges })
    sidebar.pop()
  }

  handleChargeSubmit(lineItemCharge: LineItemCharge): void {
    const lineItemCharges = [...this.lineItemCharges, lineItemCharge].map(
      (c, lineNumber) => ({ ...c, lineNumber })
    )
    this.$emit('input', { ...this.value, lineItemCharges })
    sidebar.pop()
  }

  handleSelectionSubmit(charges: LineItemCharge[], edit = false): void {
    if (edit && charges.length === 1) {
      this.handleChargeEdit(charges[0], -1)
    } else {
      const lineItemCharges = [...this.lineItemCharges, ...charges].map(
        (c, lineNumber) => ({ ...c, lineNumber })
      )
      this.$emit('input', { ...this.value, lineItemCharges })
      sidebar.pop()
    }
  }

  handleChargeUpdate(
    lineItemCharge: LineItemCharge,
    lineItemChargeIdx: number
  ): void {
    const selected = this.lineItemChargesFiltered[lineItemChargeIdx]
    const idx = this.lineItemCharges.indexOf(selected)
    let lineItemCharges = [...this.lineItemCharges]
    if (idx < 0) {
      lineItemCharges = [...lineItemCharges, lineItemCharge]
    } else {
      lineItemCharges.splice(idx, 1, lineItemCharge)
    }
    lineItemCharges = lineItemCharges.map((c, lineNumber) => ({
      ...c,
      lineNumber,
    }))
    this.$emit('input', { ...this.value, lineItemCharges })
    sidebar.pop()
  }

  handleSubmissionCancel(): void {
    sidebar.pop()
  }

  headers: SimpleTableColumn<LineItemCharge>[] = [
    { text: 'Name', value: 'name', type: 'text', width: '20%' },
    {
      text: 'Type',
      value: 'lineItemChargeType/label',
      type: 'text',
      width: '18%',
    },
    { text: 'Quantity', value: 'quantity', type: 'number', width: '16%' },
    {
      text: 'Rate',
      value: 'rate',
      type: 'number',
      width: '17%',
      formatter: (c): string =>
        isPercent(c) ? `${c.percentage}%` : currencyFilter(c.rate),
    },
    {
      text: 'Amount',
      value: '',
      type: 'number',
      width: '12%',
      formatter: (c): string => this.chargeAmount(c),
    },
  ]
}
