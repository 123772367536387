
import { AffiliateOfferWithContactInfo } from '@/models/dto/Affiliate'
import sidebar from '@/store/modules/sidebar'
import { Vue, Component, Prop } from 'vue-property-decorator'
import CompanySidebarDetail from './CompanySidebarDetail.vue'
import ContactSidebarDetail from './ContactSidebarDetail.vue'

@Component({})
export default class SimpleTableAffiliateNameCell extends Vue {
  @Prop({ required: true, default: null }) item: AffiliateOfferWithContactInfo

  openContact(): void {
    if (this.item.customerId) {
      sidebar.popAllAndPush({
        component: ContactSidebarDetail,
        props: {
          userId: this.item.customerId,
          simple: true,
          contactType: '',
        },
      })
    } else if (this.item.customerAccountId) {
      sidebar.push({
        component: CompanySidebarDetail,
        props: { customerAccountId: this.item.customerAccountId },
      })
    }
  }
}
