
import { Component, Prop, Vue } from 'vue-property-decorator'
import CUCheckbox from '@/components/CUCheckbox.vue'
import { Trip, TripEstimation } from '@/models/dto'
import CUCurrency from '@/components/CUCurrency.vue'
import {
  CompanyChargeOption,
  LineItemSectionType,
  toCompanyChargeOption,
} from '@/models/dto/LineItemCharge'
import client from '@/services/companyChargeOption'
import { LineItemChargeTypes } from '@/utils/enum'
import {
  lineItemChargeAmount,
  lineItemChargeBaseAmount,
  toLineItemCharge,
} from '@/utils/charge'
import { currencyFilter } from '@/utils/string'
import sidebar from '@/store/modules/sidebar'
import TripPricingChargeForm from '@/components/TripPricingChargeForm.vue'

@Component({
  components: { CUCurrency, CUCheckbox },
})
export default class TripPricingChargeSelector extends Vue {
  @Prop({ required: true }) readonly trip!: Trip
  @Prop({ required: true }) readonly tripEstimation!: TripEstimation
  @Prop({ required: false }) readonly section: LineItemSectionType
  @Prop({ required: false, default: '' }) readonly name!: string

  selected: CompanyChargeOption[] = []
  options: CompanyChargeOption[] = []
  search = ''

  get charges(): CompanyChargeOption[] {
    return this.options || []
  }

  get chargesFiltered(): CompanyChargeOption[] {
    return this.charges
      .filter((o) => o.lineItemSectionType?.key === this.section?.key)
      .filter(this.searchFilter)
  }

  handleChargeCreate(): void {
    sidebar.push({
      title: `Create New ${this.name}`,
      component: TripPricingChargeForm,
      props: {
        name: this.name,
        trip: this.trip,
        tripEstimation: this.tripEstimation,
        section: this.section,
        persist: true,
      },
      on: {
        submit: this.submitCharge,
        cancel: this.$listeners.cancel,
      },
    })
  }

  handleSelectionChange(selected: CompanyChargeOption[]): void {
    this.selected = selected
  }

  isPercentage(option: CompanyChargeOption): boolean {
    const type = LineItemChargeTypes.PERCENTAGE
    return option?.lineItemChargeType?.key === type
  }

  getChargeLabel(option: CompanyChargeOption): string {
    return option?.lineItemChargeType?.label || ''
  }

  getChargeRate(option: CompanyChargeOption): string {
    if (!option) {
      return ''
    } else if (this.isPercentage(option)) {
      return `${option.percentage || 0}%`
    }
    return currencyFilter(option.rate, true)
  }

  getChargeAmountLabel(option: CompanyChargeOption): string {
    const lineItemCharge = toLineItemCharge(option)
    const baseAmount = lineItemChargeBaseAmount(lineItemCharge, this.trip)
    const amount = lineItemChargeAmount(lineItemCharge, baseAmount)
    return currencyFilter(amount, true)
  }

  getChargeName(option: CompanyChargeOption, length = 14): string {
    const name = option.name || ''
    return name.length > length ? `${name.substr(0, length)}...` : name
  }

  getChargeDescription(option: CompanyChargeOption): string {
    if (!option) {
      return ''
    }
    const label = this.getChargeLabel(option)
    const rate = this.getChargeRate(option)
    return `${label} (${rate})`
  }

  searchFilter(option: CompanyChargeOption): boolean {
    const search = (this.search || '').toLowerCase()
    const name = (option.name || '').toLowerCase()
    const label = (option.lineItemChargeType?.label || '').toLowerCase()
    return name.includes(search) || label.includes(search)
  }

  submitCharge(option: CompanyChargeOption): void {
    if (typeof this.$listeners.submit === 'function') {
      this.$listeners.submit([toLineItemCharge(option)])
    }
    sidebar.close()
  }

  submit(): void {
    this.$emit(
      'submit',
      this.selected.map(toLineItemCharge),
      this.selected.length === 1
    )
  }

  cancel(): void {
    this.$emit('cancel')
  }

  mounted(): void {
    client
      .tableView({ page: -1, pageSize: -1 })
      .then((res) => res.data.resultList)
      .then((charges) => (charges || []).map(toCompanyChargeOption))
      .then((options) => (this.options = options))
  }
}
