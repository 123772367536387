
import quickbooks from '@/services/quickbooks'
import auth from '@/store/modules/auth'
import sidebar from '@/store/modules/sidebar'
import { Vue, Component } from 'vue-property-decorator'
import rstate from '@/store/modules/reservation'
import { EventBus } from '@/utils/eventBus'
import SyncToQuickbooksCustomerSidebar from './SyncToQuickbooksCustomerSidebar.vue'
import HoldUpModal from './HoldUpModal.vue'

@Component({ components: { HoldUpModal } })
export default class QuickbooksReservationDetailPanel extends Vue {
  loading = false
  deleteLoading = false
  isVoidIntegrationModalOpen = false
  isDeleteIntegrationModalOpen = false
  voidIntegrationConfirmationQuestion = `Voiding the invoice on Quickbooks will not cancel the Reservation on Busify. This action cannot be reversed.<br><br>Are you sure you want to <strong>VOID THE INVOICE ${rstate.reservationInvoiceDetails?.invoiceNumber}</strong> on Quickbooks?`
  deleteIntegrationConfirmationQuestion = `Deleting the QuickBooks invoice will not cancel the Reservation on Busify. This action will stop all communication from Busify to QuickBooks for this reservation. <br><br>Are you sure you want to <strong></string><span style="color: #FB7743;">DELETE THE INVOICE ${rstate.reservationInvoiceDetails?.invoiceNumber} AND TURN OFF SYNC</span></strong> on Quickbooks?`

  rstate = rstate

  get invoiceNumber(): string {
    return rstate.reservationInvoiceDetails?.invoiceNumber || null
  }

  get isVoided(): boolean {
    return rstate.reservationInvoiceDetails?.voided || false
  }

  get deleteIntegrationText(): string {
    return this.isVoided
      ? 'Delete QuickBooks Invoice'
      : 'Delete QuickBooks Invoice and Turn Off Sync'
  }

  get customerInfo(): string {
    if (
      !rstate.reservationInvoiceDetails?.customerName ||
      !rstate.reservationInvoiceDetails?.customerId
    ) {
      return null
    }
    return `${rstate.reservationInvoiceDetails.customerName} (ID ${rstate.reservationInvoiceDetails.customerId})`
  }

  get isMultiReservationInvoice(): boolean {
    return rstate.reservationInvoiceDetails?.multiReservationInvoice || false
  }

  get paymentsAndChargesSyncEnabled(): boolean {
    return (
      rstate.reservationInvoiceDetails?.paymentsAndChargesSyncEnabled || false
    )
  }

  async togglePaymentsAndChargesSyncEnabled(): Promise<void> {
    rstate.setPartialReservationInvoiceDetails({
      paymentsAndChargesSyncEnabled: !this.paymentsAndChargesSyncEnabled,
    })
    try {
      await quickbooks.togglePaymentsAndChargesSyncForReservation(
        auth.getCompanyId,
        rstate.reservation?.reservationId,
        this.paymentsAndChargesSyncEnabled
      )
      rstate.fetchReservationInvoiceDetails()
      EventBus.$emit(
        'snackbar:success',
        'QuickBooks data updated for this Reservation'
      )
      return
    } catch (e) {
      EventBus.$emit(
        'snackbar:error',
        'There was an error updating the QuickBooks data for this Reservation'
      )
      console.log(e)
    }
  }

  openVoidIntegrationModal(): void {
    this.isVoidIntegrationModalOpen = true
  }

  openDeleteIntegrationModal(): void {
    this.isDeleteIntegrationModalOpen = true
  }

  async deleteIntegration(): Promise<void> {
    this.deleteLoading = true
    try {
      await quickbooks.deleteQuickbooksInvoice(
        auth.getCompanyId,
        rstate.reservationInvoiceDetails.invoiceId
      )
      EventBus.$emit('snackbar:success', 'Invoice deletion successful')
      rstate.fetchReservationInvoiceDetails()
      this.close()
    } catch (e) {
      EventBus.$emit(
        'snackbar:error',
        'There was an error updating the QuickBooks data for this Reservation'
      )
      console.log(e)
    } finally {
      this.deleteLoading = false
    }
  }

  handleViewInvoiceOnQuickbooks(): void {
    if (!rstate.reservationInvoiceDetails?.link) {
      return
    }
    window.open(rstate.reservationInvoiceDetails.link, '_blank')
  }

  async handleVoidQuickbooksInvoice(): Promise<void> {
    try {
      const response = await quickbooks.voidInvoiceByReservationId(
        auth.getCompanyId,
        rstate.reservation?.reservationId
      )
      if (response.data.successful) {
        rstate.setPartialReservationInvoiceDetails({ voided: true })
        EventBus.$emit('snackbar:success', 'Invoice void successful')
      }
    } catch (e) {
      EventBus.$emit(
        'snackbar:error',
        'There was an error voiding the QuickBooks invoice for this Reservation'
      )
      console.log(e)
    }
  }

  handleChangeCustomer(): void {
    sidebar.push({
      component: SyncToQuickbooksCustomerSidebar,
      title: 'Change QuickBooks Customer',
      props: {
        changeCustomer: true,
        userId: rstate.reservation.customerId,
      },
    })
  }

  close(): void {
    sidebar.pop()
  }

  created(): void {
    rstate.fetchReservationInvoiceDetails()
  }
}
