
import { Prop, Watch } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { Action } from '@/models/dto/Action'
import { currencyFilter, formatFullName } from '@/utils/string'
import CUExpansionPanel from '@/components/CUExpansionPanel.vue'
import CUContactCard from '@/components/CUContactCard.vue'
import CUCustomerAccountCard from '@/components/CUCustomerAccountCard.vue'
import { isBeforeInLocalTime } from '@/utils/date'
import { Reservation, VehicleAssignment } from '@/models/dto'
import ReservationAssignments from '@/components/ReservationAssignments.vue'
import ReservationDetailPanelNotes from './ReservationDetailPanelNotes.vue'
import CUCommentArea from '@/components/CUCommentArea.vue'
import { Comment } from '@/models/Comment'
import client from '@/services/reservation'
import auth from '@/store/modules/auth'
import dayjs from 'dayjs'
import sidebar from '@/store/modules/sidebar'
import ContactSidebarDetail from '@/components/ContactSidebarDetail.vue'
import ReservationChangeContactSidebar from '@/components/ReservationChangeContactSidebar.vue'
import { CustomerAccountGroup } from '@/models/dto/CustomerAccount'
import {
  ContactRequest,
  ContactTypeKey,
  SimpleContact,
} from '@/models/dto/Customer'
import QuoteTagSelector from '@/components/QuoteTagSelector.vue'
import contactClient from '@/services/customer'
import tagsClient from '@/services/tag'
import { Tag } from '@/models/dto/Tag'
import type from '@/services/type'
import { Status } from '@/models/dto/Status'
import { EventBus } from '@/utils/eventBus'
import reservation from '@/store/modules/reservation'
import { SourceCategory } from '@/utils/enum'
import CreateCompanyForm from './CreateCompanyForm.vue'
import AddContactSidebar from './AddContactSidebar.vue'
import CompanySidebarDetail from './CompanySidebarDetail.vue'
import pluralize from 'pluralize'
import company from '@/services/company'
import { EventType } from '@/models/EventType'
import CULabelField from '@/components/CULabelField.vue'
import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'
import { AffiliateOffer } from '@/models/dto/Affiliate'
import HoldUpModal from '@/components/HoldUpModal.vue'
import FileUploadQuoteAndRes from './FileUploadQuoteAndRes.vue'
import { File as CustomFile } from '@/models/dto/File'
import QuickbooksReservationDetailPanel from './QuickbooksReservationDetailPanel.vue'
import { ContactQuickbooksDetail } from '@/models/dto/Quickbooks'
import quickbooks from '@/services/quickbooks'
import SyncToQuickbooksCustomerSidebar from './SyncToQuickbooksCustomerSidebar.vue'
import { reservationStatusTypes } from '@/data/types'

@Component({
  components: {
    CUExpansionPanel,
    CUContactCard,
    CUCustomerAccountCard,
    ReservationAssignments,
    ReservationDetailPanelNotes,
    CUCommentArea,
    ReservationChangeContactSidebar,
    QuoteTagSelector,
    CULabelField,
    HoldUpModal,
    FileUploadQuoteAndRes,
  },
})
export default class ReservationSidebarDetail extends mixins(DateMixin) {
  @Prop({ required: true }) readonly reservation!: Reservation
  @Prop({ required: true }) readonly assignments!: VehicleAssignment[]

  currencyFilter = currencyFilter
  isBeforeInLocalTime = isBeforeInLocalTime
  pluralize = pluralize
  dayjs = dayjs
  auth = auth
  rstate = reservation

  dueDate = this.reservation.paymentsSummary?.[0]?.dueDate || ''
  driverMessages: Comment[] = []
  showGroupSelect = false
  contacts: SimpleContact[] = null
  tags: Tag[] = []
  statuses: Status[] = reservationStatusTypes
  eventTypes: EventType[] = []
  statusAction: Action[] = []
  showReferredByInput = false
  editManagedId = false
  customManagedId = this.managedId

  isSelectQBCustomerModalOpen = false
  isReservationSyncModalOpen = false
  quickbooksUser: ContactQuickbooksDetail = null
  submittingReservationSync = false

  selectQBCustomerModalConfirmationQuestion = `Are you sure you want to sync this Reservation?<br><br>In order to <strong>create an Invoice</strong> in your QuickBooks account you have to connect this Contact with a QuickBooks Customer.`

  get reservationSyncConfirmationQuestion(): string {
    return `Are you sure you want to sync this Reservation?<br><br>This action will <strong>create an Invoice</strong> in your QuickBooks account for QuickBooks Customer ${this.quickbooksUser?.quickbooksCustomerName} (ID ${this.quickbooksUser?.quickbooksCustomerId})`
  }

  get isReferral(): boolean {
    return reservation.reservation?.sourceCategory === SourceCategory.REFERRAL
  }

  bookingContact = null
  billingContact = null
  tripContact = null
  contactsLoading = false
  isRemoveAffiliateModalOpen = false

  @Watch('reservation.customerId')
  @Watch('reservation.billingCustomerId')
  @Watch('reservation.tripContactId')
  onContactChange(): void {
    this.loadContacts()
  }

  get reservationFiles(): CustomFile[] {
    return reservation.reservation?.files || []
  }

  handleFilesUpdated(files: CustomFile[]): void {
    reservation.updateFiles(files)
  }

  get customerAccountId(): number {
    return (
      this.reservation.customerAccountId ||
      this.bookingContact?.customerAccountId
    )
  }

  get managedId(): string {
    return this.reservation?.managedId || ''
  }

  get affiliateOffersCount(): number {
    return (
      this.reservation?.affiliateOffers?.filter((offer) => offer.isActive)
        ?.length || 0
    )
  }

  get affiliateOffer(): Partial<AffiliateOffer> {
    if (this.reservation?.affiliateOffers?.length) {
      const activeOffer = this.reservation.affiliateOffers.find(
        (offer) => offer.isActive
      )
      return {
        customerId: activeOffer.customerId,
        customerAccountId: activeOffer.customerAccountId,
        amount: activeOffer.amount,
      }
    }

    return null
  }

  handleClickAffiliateOffers(): void {
    try {
      this.$router.push('#farmout')
    } catch {
      // eslint-disable-next-line no-empty
    }
  }

  get status(): Status {
    return this.statuses.find((status) => {
      return status.key === this.reservation.reservationStatus
    })
  }

  get canViewContacts(): boolean {
    return auth.getUserRoleNames.includes(ACCESS_SETTINGS_ROLES.CONTACTS)
  }

  get isReservationSyncedWithQuickbooks(): boolean {
    return !!this.rstate.isReservationSyncedWithQuickbooks
  }

  get actions(): Action[] {
    return [
      ...(this.canViewContacts
        ? [{ label: 'Edit Contact', event: 'contact:edit' }]
        : []),
      { label: 'Change Contact', event: 'contact:change' },
      { label: 'Remove Contact', event: 'contact:delete' },
    ]
  }

  get bookingActions(): Action[] {
    return [
      ...(this.canViewContacts
        ? [{ label: 'Edit Contact', event: 'contact:edit' }]
        : []),
      { label: 'Change Contact', event: 'contact:change' },
    ]
  }

  get billingActions(): Action[] {
    return [
      ...(this.canViewContacts
        ? [{ label: 'Edit Contact', event: 'contact:edit' }]
        : []),
      { label: 'Change Contact', event: 'contact:change' },
      { label: 'Remove Contact', event: 'contact:delete' },
    ]
  }

  get affiliateActions(): Action[] {
    return [{ label: 'Edit Offer', event: 'affiliate:edit' }]
  }

  async handleQuickbooksSync(): Promise<void> {
    const userId = reservation?.reservation?.customerId
    let quickbooksCustomerDetailResponse
    if (userId) {
      try {
        // Start the Quickbooks fetch and handle its error separately
        quickbooksCustomerDetailResponse =
          await quickbooks.getContactQuickbooksDetail(auth.getCompanyId, userId)
      } catch (e) {
        // This will handle errors in the customer fetch only, because the Quickbooks error is caught separately
        console.log('Error with customer fetch:', e)
      }
      if (
        quickbooksCustomerDetailResponse?.data?.customerSync
          ?.quickbooksCustomerId
      ) {
        // hold up modal
        this.quickbooksUser =
          quickbooksCustomerDetailResponse?.data?.customerSync
        this.isReservationSyncModalOpen = true
        return
      }
      this.isSelectQBCustomerModalOpen = true
    }
  }

  async handleSyncReservation(): Promise<void> {
    this.submittingReservationSync = true
    try {
      await this.createQuickbooksInvoice()
      EventBus.$emit('snackbar:success', 'Invoice creation successful')
      await reservation.fetchReservationInvoiceDetails()
      if (reservation.isReservationSyncedWithQuickbooks) {
        this.openQuickbooksInvoiceDetail()
      }
    } catch (e) {
      console.log(e)
      EventBus.$emit(
        'snackbar:error',
        'Unable to create QuickBooks Invoice from Reservation'
      )
    }
    this.submittingReservationSync = false
  }

  async createQuickbooksInvoice(): Promise<void> {
    try {
      await quickbooks.createInvoiceFromReservations(
        auth.getCompanyId,
        reservation.reservation.reservationId
      )
    } catch (e) {
      EventBus.$emit(
        'snackbar:error',
        'Unable to create QuickBooks Invoice from Reservation'
      )
    }
  }

  openCustomerQuickbooksSyncSidebar(): void {
    sidebar.push({
      component: SyncToQuickbooksCustomerSidebar,
      title: 'Sync to QuickBooks Customer',
      props: {
        userId: reservation?.reservation?.customerId,
      },
    })
  }

  openQuickbooksInvoiceDetail(): void {
    sidebar.popAllAndPush({
      component: QuickbooksReservationDetailPanel,
      width: 764,
      wide: true,
      persist: true,
    })
  }

  get quickbooksInvoiceNumber(): string {
    return reservation.reservationInvoiceDetails?.invoiceNumber || null
  }

  get companyActions(): Action[] {
    if (this.reservation.customerAccountGroupId) {
      return [
        { label: 'Remove Company', event: 'contact:delete' },
        ...(this.canViewContacts
          ? [{ label: 'Edit Company', event: 'contact:edit' }]
          : []),
        { label: 'Change Group', event: 'contact:group-edit' },
        { label: 'Remove Group', event: 'contact:group-delete' },
      ]
    }
    return [
      { label: 'Remove Company', event: 'contact:delete' },
      ...(this.canViewContacts
        ? [{ label: 'Edit Company', event: 'contact:edit' }]
        : []),
      { label: 'Add Group', event: 'contact:group-add' },
    ]
  }

  get eventTypeActions(): Action[] {
    return this.eventTypes.map((e) => ({ label: e.label, event: 'input' }))
  }

  get eventType(): EventType {
    if (this.reservation.eventTypeId) {
      return this.eventTypes.find((e) => e.id === this.reservation.eventTypeId)
    }
    return {
      id: -1,
      label: '--',
      companyId: this.reservation.companyId,
      orderIndex: -1,
    }
  }

  get tripDateOrRange(): string {
    const pickupDate = this.reservation.stops[0]?.pickupDatetime
    const dropoffDate =
      this.reservation.stops[this.reservation.stops.length - 1]?.dropoffDatetime

    const timeZone =
      this.reservation.stops[0]?.address?.timeZone || auth.getUserTimeZone

    if (
      pickupDate &&
      dropoffDate &&
      isBeforeInLocalTime(pickupDate, dropoffDate, timeZone, 'day')
    ) {
      return `${this.formatMediumDate(pickupDate, {
        tz: timeZone,
      })} - ${this.formatMediumDate(dropoffDate, { tz: timeZone })}`
    }
    return pickupDate
      ? this.formatMediumDate(pickupDate.toString(), { tz: timeZone })
      : '-'
  }

  get dropoffDate(): string {
    const date =
      this.reservation.stops[this.reservation.stops.length - 1]?.dropoffDatetime
    return date ? this.formatMediumDate(date.toString()) : '-'
  }

  get travelTime(): string {
    const drivingTime = this.reservation.drivingTime || 0
    return `${Math.floor(drivingTime / 60)} h, ${drivingTime % 60} min`
  }

  get isPaymentLate(): boolean {
    return (
      dayjs(this.dueDate).isBefore(dayjs(), 'day') &&
      this.reservation.balance !== 0
    )
  }

  get statusActions(): Action[] {
    return this.statuses.map((s) => ({
      label: s.label,
      id: s.key,
      event: 'input',
    }))
  }

  get createdOn(): string {
    const tz = dayjs.tz.guess()
    return this.formatLongDateLongTime(this.reservation.createdOn, {
      tz,
      showDot: true,
      showMeridianUpper: true,
    })
  }

  handleNotesUpdate(): void {
    sidebar.pop()
    this.$emit('reservation:refresh')
  }

  handleManagedIdChange(): void {
    if (!this.customManagedId) {
      return
    }
    this.$emit('change:managed-id', this.customManagedId.substring(0, 50))
    this.editManagedId = false
  }

  handleUpdateReferredBy(): void {
    this.showReferredByInput = false
    this.$emit('change:referred-by')
  }

  handleAddCompany(): void {
    if (this.bookingContact.customerAccountId) {
      this.$emit(
        'change:customer-account',
        this.bookingContact.customerAccountId
      )
    } else {
      sidebar.push({
        component: CreateCompanyForm,
        props: {
          companyOnly: true,
        },
        on: {
          'company:created': (customerAccount: { id: number; name: string }) =>
            this.handleNewCompanyCreated(customerAccount.id),
        },
      })
    }
  }

  async handleNewCompanyCreated(customerAccountId: number): Promise<void> {
    const contactRequest = new ContactRequest(this.bookingContact)
    await contactClient.update(this.bookingContact.id, {
      ...contactRequest,
      customerAccountId,
    })
    this.$emit('change:customer-account', customerAccountId)
    sidebar.pop()
  }

  handleEditContact(options: {
    contactId: number
    contactType: ContactTypeKey
  }): void {
    const lockCompanyField = !!this.reservation.customerAccountId
    sidebar.push({
      component: ContactSidebarDetail,
      props: {
        userId: options.contactId,
        simple: true,
        contactType: options.contactType,
        tripId: this.reservation?.tripId,
        lockCompanyField,
      },
    })
  }
  handleReservationEventTypeChange(action: Action): void {
    const eventType = this.eventTypes.find((e) => e.label === action.label)
    this.$emit('change:event-type', eventType)
  }

  handleChangeContact(type: string): void {
    const prepopulateCreateContact =
      type === 'Trip'
        ? null
        : {
            companyId: this.reservation.customerAccountId,
            customerAccountId: this.reservation.customerAccountId,
          }

    const customerAccountId =
      type === 'Trip' ? null : this.reservation.customerAccountId

    sidebar.push({
      component: AddContactSidebar,
      title: `${type} Contact`,
      props: {
        contacts: this.contacts,
        contactId: null,
        label: type,
        contactType: type,
        tripId: this.reservation?.tripId,
        prepopulateCreateContact,
        customerAccountId,
        localEmitOnly: true,
      },
      width: 450,
      on: { 'contact:update': this.handleUpdateContact },
    })
  }

  async handleUpdateContact(options: {
    contact: SimpleContact
    contactType: ContactTypeKey
    tripId: number
  }): Promise<void> {
    if (!options?.contactType) {
      return
    }
    this.contactsLoading = true
    try {
      switch (options.contactType) {
        case 'Booking':
          await client.updateReservationBookingCustomer(
            this.reservation.reservationId,
            options.contact?.id || null
          )
          break
        case 'Billing':
          await client.updateReservationBillingCustomer(
            this.reservation.reservationId,
            options.contact?.id || null
          )
          break
        case 'Trip':
          await client.updateReservationTripContact(
            this.reservation.reservationId,
            options.contact?.id || null
          )
          break
        default:
          break
      }
    } catch {
      return
    }
    this.handleRefreshContacts()
    this.loadContacts()
  }

  handleEditCompany(): void {
    sidebar.push({
      component: CompanySidebarDetail,
      props: { customerAccountId: this.reservation.customerAccountId },
      on: {
        refresh: () => EventBus.$emit('customer-account:refresh'),
      },
    })
  }

  handleDeleteCompany(): void {
    this.$emit('change:customer-account', null)
  }

  async handleDeleteCompanyGroup(): Promise<void> {
    try {
      await client.updateReservationCustomerAccountGroup(
        this.reservation.reservationId,
        null
      )
    } catch (e: any) {
      console.error(e)
      return
    }
    this.$emit('update', {
      ...this.reservation,
      customerAccountGroupId: null,
      customerAccountGroupName: null,
    })
  }

  async handleAddCompanyGroup(group: CustomerAccountGroup): Promise<void> {
    try {
      await client.updateReservationCustomerAccountGroup(
        this.reservation.reservationId,
        group.customerAccountGroupId
      )
    } catch (e: any) {
      console.error(e)
      return
    }
    this.$emit('update', {
      ...this.reservation,
      customerAccountGroupId: group.customerAccountGroupId,
      customerAccountGroupName: group.name,
    })
    this.showGroupSelect = false
  }

  handleAddDriverMessage(message: string): void {
    const reservationComment = {
      note: message,
      reservation: {
        id: this.reservation.reservationId,
      },
    }
    client.addComment(reservationComment)
    const comment: Comment = {
      text: message,
      createdAt: new Date(),
      createdById: auth.userId,
      fullName: formatFullName(auth.user),
    }
    this.driverMessages.push(comment)
    EventBus.$emit('refresh-reservation')
  }

  handleChangeDueDate(val: string): void {
    this.dueDate = val
    this.$emit('change:due-date', this.dueDate)
  }

  handleReservationStatusChange(status: Action): void {
    this.$emit('change:status', status.id)
  }

  handleRefreshContacts(): void {
    EventBus.$emit('refresh-reservation')
  }

  prepareDriverMessages(): void {
    this.driverMessages = this.reservation.reservationComments.map(
      (comment) => {
        const message: Comment = {
          text: comment.comment,
          createdAt: new Date(comment.createdOn),
          createdById: comment.userId,
          fullName: comment.userName,
        }
        return message
      }
    )
  }

  async loadContacts(): Promise<void> {
    this.contactsLoading = true
    const { customerId, billingCustomerId, tripContactId } = this.reservation

    const loadBookingContact = async () => {
      if (customerId) {
        const {
          data: { customer },
        } = await contactClient.byId(customerId)
        this.bookingContact = { ...customer, id: customer.customerId }
      } else {
        this.bookingContact = null
      }
    }

    const loadBillingContact = async () => {
      if (billingCustomerId) {
        const {
          data: { customer },
        } = await contactClient.byId(billingCustomerId)
        this.billingContact = { ...customer, id: customer.customerId }
      } else {
        this.billingContact = null
      }
    }

    const loadTripContact = async () => {
      if (tripContactId) {
        const {
          data: { customer },
        } = await contactClient.byId(tripContactId)
        this.tripContact = { ...customer, id: customer.customerId }
      } else {
        this.tripContact = null
      }
    }

    await Promise.all([
      loadBookingContact(),
      loadBillingContact(),
      loadTripContact(),
    ])

    this.contactsLoading = false
  }

  async loadTags(): Promise<void> {
    const currentQuoteTagsResponse = await tagsClient.byQuoteId(
      this.reservation.quoteId
    )
    const currentQuoteTags = currentQuoteTagsResponse.data?.tags
    this.tags = currentQuoteTags
  }

  async loadCompanyEventTypes(): Promise<void> {
    const {
      data: { eventTypes },
    } = await company.getCompanyEventTypes(this.reservation.companyId)
    eventTypes.sort((a, b) => (a.orderIndex > b.orderIndex ? 1 : -1))
    this.eventTypes = [
      {
        id: null,
        label: '--',
        companyId: this.reservation.companyId,
        orderIndex: -1,
      },
      ...eventTypes,
    ]
  }

  async mounted(): Promise<void> {
    EventBus.$on('contact:update', this.handleUpdateContact)
    EventBus.$on('contact-sidebar:update', this.handleUpdateContact)

    await Promise.all([
      reservation.fetchReservationInvoiceDetails(),
      this.loadContacts(),
      this.loadTags(),
      this.prepareDriverMessages(),
      this.loadCompanyEventTypes(),
    ])
  }

  beforeDestroy(): void {
    EventBus.$off('contact:update', this.handleUpdateContact)
    EventBus.$off('contact-sidebar:update', this.handleUpdateContact)
  }
}
