import dayjs from 'dayjs'
import { convertMinutesToHoursAndMinutes } from './time'

// Helper function to add a symbol before positive numbers
export const numberWithSymbol = (num: number, hideSymbol = true): string => {
  return hideSymbol || num < 0 ? num.toString() : `+${num}`
}

// Helper function to format minutes into "Xh Ym" format
export const formatMinutesToTimeLabel = (
  totalMinutes: number,
  hideSymbol = true
): string => {
  const { hours, minutes } = convertMinutesToHoursAndMinutes(totalMinutes)

  const hoursLabel = hours ? `${numberWithSymbol(hours, hideSymbol)}h` : ''
  // Avoid the case of double negative symbols when hours are negative. E.g., -1h -30m -> -1h 30m
  const hideMinutesSymbol =
    hideSymbol || !!hoursLabel || (hours < 0 && minutes < 0)
  const minutesLabel = minutes
    ? `${numberWithSymbol(Math.abs(minutes), hideMinutesSymbol)}m`
    : ''

  return `${hoursLabel} ${minutesLabel}`.trim()
}

// Helper function to get an inclusive day difference label
export const getInclusiveDayDifferenceLabel = (
  startDatetime: string,
  endDatetime: string,
  timeZone = null
): string => {
  let start = null
  let end = null

  if (timeZone) {
    start = dayjs.utc(startDatetime).tz(timeZone).format('YYYY-MM-DD')
    end = dayjs.utc(endDatetime).tz(timeZone).format('YYYY-MM-DD')
  } else {
    start = dayjs.utc(startDatetime).format('YYYY-MM-DD')
    end = dayjs.utc(endDatetime).format('YYYY-MM-DD')
  }

  const differenceInDays = Math.abs(dayjs(start).diff(dayjs(end), 'd')) + 1
  return `${differenceInDays}d`
}

// Helper function to format miles
export const formatMilesToLabel = (
  totalMiles: number,
  hideSymbol = true
): string => {
  if (!totalMiles) {
    return ''
  }
  const roundedTotalMiles = Number(Number(totalMiles).toFixed(2))
  return `${numberWithSymbol(roundedTotalMiles, hideSymbol)}mi`
}
