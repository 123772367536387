
import colors from '@/scss/_colors-export.scss'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class CUCounter extends Vue {
  @Prop({ type: Number, default: 0 }) readonly value!: number
  @Prop({ type: Number, default: Number.MAX_SAFE_INTEGER })
  readonly maxValue!: number
  @Prop({ type: Number, default: Number.MIN_SAFE_INTEGER })
  readonly minValue!: number
  @Prop({ required: false, type: Boolean })
  readonly disabled!: boolean
  @Prop({ required: false, default: null })
  readonly color!: string
  @Prop({ required: false, type: Boolean })
  readonly widget!: boolean

  colors = colors

  decrement(): void {
    this.$emit('input', Math.max(this.minValue, this.value - 1))
  }

  increment(): void {
    this.$emit('input', Math.min(this.maxValue, this.value + 1))
  }

  handleChange(newVal: number): void {
    this.$emit(
      'input',
      Math.max(this.minValue, Math.min(this.maxValue, newVal))
    )
  }
}
