
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import state from '@/store/modules/reservation'
import dayjs from 'dayjs'
import { currencyFilter } from '@/utils/string'
import { getMinutesBetweenDates } from '@/utils/time'
import {
  formatMilesToLabel,
  formatMinutesToTimeLabel,
  getInclusiveDayDifferenceLabel,
} from '@/utils/posttrip'
@Component({})
export default class ReservationPostTripStatsHeader extends mixins(DateMixin) {
  state = state
  get assignedVehicleLabel(): string {
    const assignedVehicles = state.assignments.length
    let label = `${assignedVehicles} Vehicle`
    if (assignedVehicles > 1) {
      label += 's'
    }
    return label
  }

  get formattedPickup(): string {
    const trip = this.state.trip
    const pickup = this.state.firstPostTripDatetime

    const tz = this.state.firstTimeZone

    if (!pickup || !tz) {
      return ''
    }

    return this.formatLongDateShortTime(pickup, {
      tz,
      showMeridianUpper: true,
      showTimezone: true,
    })
  }

  get formattedDropoff(): string {
    const dropoff = this.state.lastPostTripDatetime
    const tz = this.state.lastTimeZone

    if (!dropoff || !tz) {
      return ''
    }

    return this.formatLongDateShortTime(dropoff, {
      tz,
      showMeridianUpper: true,
      showTimezone: true,
    })
  }

  get plannedLabel(): string {
    const start = this.state.firstPostTripDatetime
    const end = this.state.lastPostTripDatetime
    const plannedMinutes = getMinutesBetweenDates(start, end)
    const tz = this.state.trip?.stops?.[0]?.address?.timeZone

    const timeLabel = plannedMinutes
      ? formatMinutesToTimeLabel(plannedMinutes)
      : ''
    const dayLabel = getInclusiveDayDifferenceLabel(start, end, tz)

    const totalMiles = this.state.postTripDistanceMiles
    const mileageLabel = totalMiles ? formatMilesToLabel(totalMiles) : ''

    return [timeLabel, dayLabel, mileageLabel]
      .filter((label) => !!label)
      .join(' | ')
  }

  get quotedBaseFareLabel(): string {
    return currencyFilter(this.state.baseFareCharge)
  }
}
