
import reservation from '@/store/modules/reservation'
import { Vue, Component, Prop } from 'vue-property-decorator'
import {
  capitalizeFirstLetter,
  currencyFilter,
  phoneFormatFilter,
} from '@/utils/string'
import { Invoice } from '@/models/Invoice'
import { VehicleAssignment } from '@/models/dto'
import { Driver } from '@/models/dto/Driver'
import { EventBus } from '@/utils/eventBus'

@Component({})
export default class ReservationDriverPayHeader extends Vue {
  @Prop({ required: true }) assignmentIdx: number
  @Prop({ required: true }) invoice: Invoice
  @Prop({ required: true }) assignment: VehicleAssignment
  @Prop({ required: true }) driver: Driver

  capitalizeFirstLetter = capitalizeFirstLetter
  phoneFormatFilter = phoneFormatFilter
  currencyFilter = currencyFilter
  state = reservation

  totalCharges = null

  get plannedHours(): string {
    return (this.state.estimations.itineraryHours || 0).toFixed(1)
  }

  get driverName(): string {
    return `${this.driver.firstName} ${this.driver.lastName}`
  }

  getTagBorder(tag: string): string {
    switch (tag.toLowerCase()) {
      case 'paid':
        return 'secondary'
      case 'reviewed':
        return 'additional-blue'
      default:
        return 'gray-border-dark'
    }
  }

  getTagBackground(tag: string): string {
    switch (tag.toLowerCase()) {
      case 'paid':
        return 'secondary-light'
      case 'reviewed':
        return 'additional-blue-2'
      default:
        return 'bg-gray-2'
    }
  }

  updateTotal(data: {
    assignmentIdx: number
    duplicateIdx: number
    total: number
  }): void {
    if (
      data.assignmentIdx === this.assignmentIdx &&
      data.duplicateIdx === this.invoice.duplicateIndex
    ) {
      this.totalCharges = data.total
    }
  }

  mounted(): void {
    EventBus.$on('driver-pay:update-invoice-total', this.updateTotal)

    this.totalCharges = this.invoice.totalCharges
  }

  beforeDestroy(): void {
    EventBus.$off('driver-pay:update-invoice-total', this.updateTotal)
  }
}
