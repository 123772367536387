
import { Component, Prop, Vue } from 'vue-property-decorator'
import TripPricingCharges from '@/components/TripPricingCharges.vue'
import TripPricingMarkups from '@/components/TripPricingMarkups.vue'
import TripPricingTotalFooter from '@/components/TripPricingTotalFooter.vue'
import { Trip, TripChargeType, TripEstimation } from '@/models/dto'
import { LineItemChargeTypes, LineItemSectionTypes } from '@/utils/enum'
import {
  getRecurringTripTotal,
  getTripSubtotal,
  getTripTotal,
  getTripTotalBaseFare,
} from '@/utils/trip'
import tclient from '@/services/type'
import { LineItemCharge } from '@/models/dto/LineItemCharge'
import CUCurrency from '@/components/CUCurrency.vue'
import { currencyFilter } from '@/utils/string'
import { lineItemChargeAmount, lineItemChargeBaseAmount } from '@/utils/charge'

@Component({
  components: {
    CUCurrency,
    TripPricingCharges,
    TripPricingMarkups,
    TripPricingTotalFooter,
  },
})
export default class TripPricingSummary extends Vue {
  @Prop({ required: true }) readonly trip!: Trip
  @Prop({ required: true }) readonly title!: string
  @Prop({ required: false }) readonly tripIdx: number
  @Prop({ required: true }) readonly tripEstimation!: TripEstimation
  tripChargeTypes: TripChargeType[] = []

  get totalBaseFare(): string {
    return currencyFilter(getTripTotalBaseFare(this.trip))
  }

  get lineItemCharges(): LineItemCharge[] {
    return this.trip.lineItemCharges || []
  }

  get itemizedCharges(): LineItemCharge[] {
    const type = LineItemSectionTypes.ITEMIZED_CHARGE
    return this.lineItemCharges.filter(
      (c) => c.lineItemSectionType?.key === type && c.isActive
    )
  }

  get subtotalCharges(): LineItemCharge[] {
    const type = LineItemSectionTypes.SUBTOTAL
    return this.lineItemCharges.filter(
      (c) => c.lineItemSectionType?.key === type && c.isActive
    )
  }

  get tripName(): string {
    return (
      this.trip.routeName ||
      (this.tripIdx ? `Trip ${1 + this.tripIdx}` : 'Trip')
    )
  }

  get tripSubtotal(): string {
    return currencyFilter(getTripSubtotal(this.trip))
  }

  get tripTotal(): string {
    return currencyFilter(getTripTotal(this.trip))
  }

  get tripRecurringTotal(): string {
    return this.trip.recurrenceTripCount > 1
      ? currencyFilter(getRecurringTripTotal(this.trip))
      : null
  }

  chargeName(charge: LineItemCharge): string {
    const type = LineItemChargeTypes.PERCENTAGE
    const isPercentage = charge.lineItemChargeType?.key === type
    return isPercentage
      ? `${charge.name} (${charge.percentage}%)`
      : charge.name || '--'
  }

  chargeAmount(charge: LineItemCharge): string {
    const baseAmount = lineItemChargeBaseAmount(charge, this.trip)
    const amount = lineItemChargeAmount(charge, baseAmount)
    return currencyFilter(amount)
  }

  mounted(): void {
    tclient
      .tripChargeTypes()
      .then((res) => res.data)
      .then((tripChargeTypes) => (this.tripChargeTypes = tripChargeTypes))
  }
}
