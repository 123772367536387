
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Days, MarkupTypes } from '@/utils/enum'
import { Markup, MarkupDay, MarkupType } from '@/models/dto/Markup'
import tclient from '@/services/type'
import AutocompleteCustomer from '@/components/AutocompleteCustomer.vue'
import { Trip, TripEstimation, VehicleType } from '@/models/dto'
import { validateRules, validationRules } from '@/utils/rules'
import AutocompleteCustomerAccount from '@/components/AutocompleteCustomerAccount.vue'
import { MarketRateType } from '@/models/dto/Rate'

@Component({
  components: { AutocompleteCustomerAccount, AutocompleteCustomer },
})
export default class TripPricingMarkupForm extends Vue {
  @Prop({ required: true }) readonly trip!: Trip
  @Prop({ required: true }) readonly tripEstimation!: TripEstimation
  @Prop({ required: false }) readonly markup: Markup
  @Prop({ required: false }) readonly markupIdx: number
  @Prop({ type: Boolean, default: false }) readonly persist!: boolean
  form: Markup = new Markup()

  validation = validationRules
  types = MarkupTypes

  markupTypes: MarkupType[] = []
  vehicleTypes: VehicleType[] = []
  marketRateTypes: MarketRateType[] = []
  days: Partial<MarkupDay>[] = Object.keys(Days).map((_, day) => ({ day }))

  isMarkupType(type: MarkupTypes): boolean {
    return this.form?.markupType?.key === type
  }

  dayLabel(markupDay: MarkupDay): string {
    return Object.values(Days)[markupDay.day].substring(0, 3) || ''
  }

  submitForm(): void {
    this.$emit(!this.markup ? 'submit' : 'update', this.form)
  }

  submit(): void {
    validateRules(this).then((isValid) => {
      if (isValid) {
        this.submitForm()
      }
    })
  }

  cancel(): void {
    this.$emit('cancel')
  }

  handleMarkupTypeChange(markupType: MarkupType): void {
    const markupTypeId = markupType?.markupTypeId
    this.form = { ...this.form, markupType, markupTypeId }
  }

  handleMarkupDateRangeChange([startDate, endDate]: string[]): void {
    this.form = { ...this.form, startDate, endDate }
  }

  filterMarkupTypes(markupTypes: MarkupType[]): MarkupType[] {
    const exclusions: string[] = [MarkupTypes.EVENT]
    return (markupTypes || []).filter(
      (markupType) => !exclusions.includes(markupType.key)
    )
  }

  mounted(): void {
    if (this.markup) {
      this.form = { ...this.markup }
    }

    tclient
      .markupTypes()
      .then((res) => res.data.resultList)
      .then(this.filterMarkupTypes)
      .then((markupTypes) => (this.markupTypes = markupTypes))

    tclient
      .vehicleTypes()
      .then((res) => res.data)
      .then((vehicleTypes) => (this.vehicleTypes = vehicleTypes))

    tclient
      .marketRateTypes()
      .then((res) => res.data)
      .then((marketRateTypes) => (this.marketRateTypes = marketRateTypes))
  }
}
