
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import RecipientList from '@/components/RecipientList.vue'
import reservation from '@/store/modules/reservation'
import CustomerContactSearch from './CustomerContactSearch.vue'
import { Customer } from '@/models/dto/Customer'
import { validationRules } from '@/utils/rules'
import currency from 'currency.js'
import { currencyFilter, percentageFormatter } from '@/utils/string'
import { Contact } from '@/models/Contact'
import { AffiliateOffer } from '@/models/dto/Affiliate'
import typeClient from '@/services/type'
import CUContactCard from './CUContactCard.vue'
import { RequiredVehicleType } from '@/models/dto'

@Component({
  components: {
    RecipientList,
    CustomerContactSearch,
    CUContactCard,
  },
})
export default class SendToAffiliateForm extends Vue {
  @Prop({ required: false, default: null }) customer: Customer
  @Prop({ required: false, default: null }) offer: AffiliateOffer

  state = reservation
  rules = validationRules

  emailContacts = []
  formData = {
    amount: null,
    profit: null,
    margin: null,
  }
  displayData = {
    amount: null,
    profit: null,
    margin: null,
  }
  note = {
    note: null,
    html: null,
  }
  sendConfirmationEmail = true
  includeTripContactInfo = false
  vehicleList = []
  selectedVehicles = []
  vehicleDefaultsAltered = false
  passengerCount = null

  shouldDisplayLabel(val: number): boolean {
    if (val === 0) {
      return true
    }
    return !!val
  }

  handleUpdateTripContactInfo(val: boolean): void {
    this.includeTripContactInfo = val
    this.$emit('update:trip-contact', val)
  }

  handleUpdateSendConfirmationEmail(val: boolean): void {
    this.sendConfirmationEmail = val
    this.$emit('update:send-confirmation', val)
  }

  handleUpdateRecipientList(contacts: Contact[]): void {
    this.$emit('update:emails', contacts.map(({ email }) => email).join(','))
  }

  handleNoteTextChange(note: string): void {
    this.note.note = note
    this.$emit('update:note', this.note)
  }

  handleUpdatePassengerCount(passengerCount: number): void {
    this.passengerCount = passengerCount
    this.$emit('update:passengerCount', this.passengerCount)
  }

  handleNoteHtmlChange(html: string): void {
    this.note.html = html
    this.$emit('update:note', this.note)
  }

  handleAmountInput(): void {
    const newAmount = Math.max(
      parseFloat(this.displayData.amount.replace(/[^\d.-]/g, '')),
      0
    )

    if (
      this.formData.amount &&
      parseFloat(this.formData.amount.toFixed(2)) === newAmount
    ) {
      return
    }

    this.formData.amount = newAmount
    this.displayData.amount = currencyFilter(this.formData.amount)

    this.formData.profit = currency(this.state.reservation.amount).subtract(
      this.formData.amount
    ).value
    this.displayData.profit = currencyFilter(this.formData.profit)

    this.formData.margin =
      (this.formData.profit / this.state.reservation.amount) * 100
    this.displayData.margin = percentageFormatter(this.formData.margin)

    if (this.vehicleDefaultsAltered) {
      this.formData.profit = null
      this.formData.margin = null
      this.displayData.profit = null
      this.displayData.margin = null
    }

    this.emitAmounts()
  }

  handleProfitInput(): void {
    const newProfit = parseFloat(
      this.displayData.profit.replace(/[^\d.-]/g, '')
    )

    if (
      this.formData.profit &&
      parseFloat(this.formData.profit.toFixed(2)) === newProfit
    ) {
      return
    }

    this.formData.profit = newProfit
    this.displayData.profit = currencyFilter(this.formData.profit)

    this.formData.amount = currency(this.state.reservation.amount).subtract(
      this.formData.profit
    ).value
    this.displayData.amount = currencyFilter(this.formData.amount)

    this.formData.margin =
      (this.formData.profit / this.state.reservation.amount) * 100
    this.displayData.margin = percentageFormatter(this.formData.margin)

    this.emitAmounts()
  }

  handleMarginInput(): void {
    const newMargin = parseFloat(
      this.displayData.margin.replace(/[^\d.-]/g, '')
    )

    if (
      this.formData.margin &&
      parseFloat(this.formData.margin.toFixed(2)) === newMargin
    ) {
      return
    }

    this.formData.margin = newMargin
    this.displayData.margin = percentageFormatter(this.formData.margin)

    this.formData.profit = currency(this.state.reservation.amount)
      .multiply(this.formData.margin)
      .divide(100).value
    this.displayData.profit = currencyFilter(this.formData.profit)

    this.formData.amount = currency(this.state.reservation.amount).subtract(
      this.formData.profit
    ).value
    this.displayData.amount = currencyFilter(this.formData.amount)

    this.emitAmounts()
  }

  emitAmounts(): void {
    this.$emit('update:amount', this.formData.amount)
    this.$emit('update:profit', this.formData.profit)
  }

  handleReplaceContact(): void {
    this.$emit('replace-contact')
  }

  incrementCount(vehicle: any): void {
    vehicle.count++
    this.vehicleDefaultsAltered = true
    this.$emit('update:vehicles', this.selectedVehicles)
  }

  decrementCount(vehicle: any, idx: number): void {
    if (vehicle.count > 1) {
      vehicle.count--
    } else {
      this.selectedVehicles.splice(idx, 1)
    }
    this.vehicleDefaultsAltered = true
    this.$emit('update:vehicles', this.selectedVehicles)
  }

  handleAddVehicle(): void {
    this.selectedVehicles.push({
      item: this.vehicleList[0].label,
      count: 1,
      id: null,
    })
    this.vehicleDefaultsAltered = true
    this.$emit('update:vehicles', this.selectedVehicles)
  }

  async created(): Promise<void> {
    try {
      const vehiclesResponse = await typeClient.vehicleTypeTableView({
        page: 1,
        pageSize: -1,
      })
      this.vehicleList = vehiclesResponse.data.resultList
    } catch (error) {
      console.error(error)
    }
  }

  @Watch('offer', { deep: true })
  offerUpdated(): void {
    if (this.offer.id) {
      this.setFormData()
    }
  }

  setFormData(): void {
    this.emailContacts =
      this.offer.emails?.split(',').map((email) => {
        let type = email === this.customer.email ? 'Primary Contact' : null
        type = email === this.customer.customerAccount?.email ? 'Company' : type
        return { email, type }
      }) || []
    this.formData = {
      amount: this.offer.amount,
      profit: this.offer.profit,
      margin:
        this.offer.amount && this.offer.profit
          ? (this.offer.profit / this.state.reservation.amount) * 100
          : null,
    }

    this.displayData = {
      amount: currencyFilter(this.formData.amount),
      profit: currencyFilter(this.formData.profit),
      margin: percentageFormatter(this.formData.margin),
    }
    this.note = this.offer.note
    this.includeTripContactInfo = this.offer.includeTripContactInfo

    if (this.vehicleDefaultsAltered) {
      this.formData.profit = null
      this.formData.margin = null
      this.displayData.profit = null
      this.displayData.margin = null
    }
  }

  setDefaultVehicles(): void {
    const reservationDefaults =
      (this.state.reservation?.requiredVehicles as RequiredVehicleType[]).map(
        (vehicle) => ({
          item: vehicle.vehicleType.label,
          count: vehicle.quantity,
          id: null,
        })
      ) || []

    if (!this.offer.id) {
      this.selectedVehicles = reservationDefaults
    } else {
      this.selectedVehicles = this.offer.vehicleDetails.map((vehicle) => ({
        item: vehicle.vehicleType,
        count: vehicle.vehicleCount,
        id: vehicle.id,
      }))

      const selectedWithIdAsNull = this.selectedVehicles.map((vehicle) => ({
        ...vehicle,
        id: null,
      }))
      if (
        JSON.stringify(selectedWithIdAsNull) !==
        JSON.stringify(reservationDefaults)
      ) {
        this.vehicleDefaultsAltered = true
      }
    }
    this.$emit('update:vehicles', this.selectedVehicles)
  }

  setPassengerCount(): void {
    if (this.offer.passengerCount == null) {
      this.handleUpdatePassengerCount(this.state.reservation.passengerCount)
      return
    }
    this.passengerCount = this.offer.passengerCount
  }

  mounted(): void {
    if (this.customer) {
      this.emailContacts.push(
        ...[
          ...(this.customer.customerAccount?.email
            ? [{ email: this.customer.customerAccount.email, type: 'Company' }]
            : []),
          { email: this.customer.email, type: 'Primary Contact' },
        ]
      )
    }

    this.setDefaultVehicles()
    this.setPassengerCount()
    if (!this.offer.affiliateOfferId) {
      return
    }

    this.setFormData()
  }
}
