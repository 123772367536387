
import { Prop, Inject, Provide, Watch } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { GarageTimes, GarageError } from '@/models/dto/GarageTimes'
import {
  ExistingPlace,
  Stop,
  StopErrors,
  Vehicle,
  VehicleType,
} from '@/models/dto'
import {
  Trip,
  TripEstimation,
  TripType,
  TripContact,
  TripStopTypeKey,
} from '@/models/dto/Trip'
import CUCounter from '@/components/CUCounter.vue'
import TripDetailsGarageCard from '@/components/TripDetailsGarageCard.vue'
import TripDetailsStopCard from '@/components/TripDetailsStopCard.vue'
import TripEstimationsFooter from '@/components/TripEstimationsFooter.vue'
import sidebar from '@/store/modules/sidebar'
import quoteClient from '@/services/quotes'
import AddContactSidebar from './AddContactSidebar.vue'
import { countryPhoneFormatFilter, formatFullName } from '@/utils/string'
import { Quote } from '@/models/dto/Quote'
import dayjs from 'dayjs'
import deepClone from '@/utils/deepClone'
import TripDetailsAddStopDivider from '@/components/TripDetailsAddStopDivider.vue'
import { getRecurringAmountDueNow, getTripAmountDueNow } from '@/utils/trip'
import draggable from 'vuedraggable'
import { EventBus } from '@/utils/eventBus'
import reservation from '@/store/modules/reservation'
import types from '@/store/modules/types'
import { SourceCategory } from '@/utils/enum'
import quote from '@/store/modules/quote'
import { isNotEmptyInput } from '@/utils/validators'
import ContactSidebarDetail from './ContactSidebarDetail.vue'
import { ContactTypeKey, SimpleContact } from '@/models/dto/Customer'
import auth from '@/store/modules/auth'
import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'
import tripClient from '@/services/trip'
import companyStopsStore from '@/store/modules/companyStops'

@Component({
  components: {
    TripEstimationsFooter,
    TripDetailsGarageCard,
    TripDetailsStopCard,
    TripDetailsAddStopDivider,
    CUCounter,
    draggable,
  },
})
export default class TripDetails extends mixins(DateMixin) {
  @Prop({ required: true }) readonly trip!: Trip
  @Prop({ required: false }) readonly tripEstimation!: TripEstimation
  @Prop({ default: false, type: Boolean }) readonly isSaving!: boolean
  @Inject({ default: false }) isReservationTrip: boolean
  isNotEmptyInput = isNotEmptyInput

  @Provide() addresses = (): ExistingPlace[] => this.getTripAddresses()

  showTripContactEditIcon = false
  isDraggingStop = false
  isTripContactMenuOpen = false
  draggedIndex = null
  recurringTripCount = 0
  loading = false
  isDepartureGarageDirty = false
  isReturnGarageDirty = false

  // Validation
  vehicleSelectErrorMessage = null
  garageErrors: GarageError = {
    depart: {},
    return: {},
  }
  stopErrors = {}
  tripTypeError: string = null
  passengerCountError: string = null
  requiredDriversError: string = null
  rstate = reservation
  typeState = types
  state = quote

  get isReferral(): boolean {
    return (
      this.isReservationTrip &&
      reservation.reservation?.sourceCategory === SourceCategory.REFERRAL
    )
  }

  get defaultSpotTimeOffset(): number {
    return auth.getCompany.defaultSpotTimeOffset
  }

  get defaultGarageId(): number {
    return auth.getCompany.defaultGarageId
  }

  get vehicleList(): Vehicle[] {
    return this.typeState.vehicles
  }

  get isVehicleListLoading(): boolean {
    return this.typeState.loadingInformation.isLoadingVehicles
  }

  get vehicleTypes(): VehicleType[] {
    return this.typeState.getVehicleTypes
  }

  get isVehicleTypesLoading(): boolean {
    return this.typeState.loadingInformation.isLoadingVehicleTypes
  }

  get tripTypes(): Record<string, TripType> {
    return this.typeState.tripTypes
  }

  get isLoadingTripTypes(): boolean {
    return this.typeState.loadingInformation.isLoadingTripTypes
  }

  get tripType(): TripType {
    return this.tripTypes[this.trip.tripTypeId]
  }

  get stops(): Stop[] {
    return this.trip.stops || []
  }

  get stopIds(): string[] {
    return this.stops.map(({ id }) => id)
  }

  get lastUpdated(): string {
    const ts = this.trip?.updatedOn
    return ts
      ? this.formatShortDateShortTime(ts, {
          showMeridianUpper: true,
        })
      : null
  }

  get tripContactString(): string {
    if (!this.trip?.tripContact) {
      return ''
    }
    const { tripContact: contact } = this.trip
    return `${formatFullName(contact)}; ${countryPhoneFormatFilter(
      contact.phone,
      contact.phoneCountryKey
    )} ${contact.phoneExtension ? `ext. ${contact.phoneExtension}` : ``}`
  }

  get tripTypeRowStyle(): Record<string, string> {
    // Disabling until site is responsive
    const isResponsive = false
    if (this.$vuetify.breakpoint.lgAndUp || !isResponsive) {
      return { marginLeft: '6px' }
    }
    return {}
  }

  get addTripContactRowStyle(): Record<string, string> {
    // Disabling until site is responsive
    const isResponsive = false
    if (this.$vuetify.breakpoint.lgAndUp || !isResponsive) {
      return { marginLeft: '19px' }
    }
    return {}
  }

  get isConverted(): boolean {
    return !!this.state?.quote?.isConverted
  }

  get draggableModel(): any[] {
    let stops: any = [...this.trip.stops]
    stops = stops.map((stop) => ({ ...stop, type: 'stop' }))
    if (!this.isReferral) {
      const index = stops.length === 1 ? stops.length : stops.length - 1
      stops.splice(index, 0, { type: 'button' })
    }
    return stops
  }

  set draggableModel(newValue) {
    let stops = newValue.filter((stop) => stop.type !== 'button')
    stops = stops.map((stop, index) => ({ ...stop, orderIndex: index }))
    const firstStop = stops[0]
    const lastStop = stops[stops.length - 1]
    if (!firstStop || !lastStop) {
      return
    }

    if (firstStop.dropoffDatetime && !firstStop.pickupDatetime) {
      firstStop.pickupDatetime = firstStop.dropoffDatetime
      firstStop.pickupDate = firstStop.dropoffDate
      firstStop.pickupTime = firstStop.dropoffTime
    }
    firstStop.dropoffDatetime = null
    firstStop.dropoffDate = null
    firstStop.dropoffTime = null

    if (stops.length > 1) {
      if (lastStop.pickupDatetime && !lastStop.dropoffDatetime) {
        lastStop.dropoffDatetime = lastStop.pickupDatetime
        lastStop.dropoffDate = lastStop.pickupDate
        lastStop.dropoffTime = lastStop.pickupTime
      }

      lastStop.pickupDatetime = null
      lastStop.pickupDate = null
      lastStop.pickupTime = null
      lastStop.spotTime = null
    }
    this.$emit('input', { stops })
  }

  get allStopsWithDates(): Stop[] {
    return this.stops.filter((s) => !!s.pickupDate || !!s.dropoffDate)
  }

  // Sorted from 0...n
  get allStopsWithDatesAsc(): Stop[] {
    return [...this.allStopsWithDates].sort((a, b) =>
      a.orderIndex < b.orderIndex ? 1 : -1
    )
  }

  // Sorted from n...0
  get allStopsWithDatesDesc(): Stop[] {
    return [...this.allStopsWithDates].sort((a, b) =>
      a.orderIndex > b.orderIndex ? 1 : -1
    )
  }

  get canViewContacts(): boolean {
    return auth.getUserRoleNames.includes(ACCESS_SETTINGS_ROLES.CONTACTS)
  }

  get isDepartingGarageError(): boolean {
    return (
      this.garageErrors.depart?.isAfterFirstStop ||
      this.garageErrors.depart?.isInvalidDatetime ||
      this.garageErrors.depart?.isPreTripArrivalTimeInvalid
    )
  }

  get isReturnGarageError(): boolean {
    return (
      this.garageErrors.return?.isBeforeLastStop ||
      this.garageErrors.return?.isInvalidDatetime
    )
  }
  get updateFutureTrips(): boolean {
    return reservation?.trip?.updateRecurringTrips || false
  }

  handleUpdateFutureTripsChange(val: boolean): void {
    reservation.updateTrip({ updateRecurringTrips: val })
    this.getUpdatedRecurringTripCount()
  }

  get updateRecurringTripsFromDate(): string {
    const firstPickup = reservation.trip.stops.find(
      (stop) => !!stop?.pickupDatetime
    )
    if (!firstPickup) {
      return null
    }
    if (!reservation?.trip?.updateRecurringTripsFromDate) {
      reservation.updateTrip({
        updateRecurringTripsFromDate: dayjs(firstPickup.pickupDatetime).tz(
          firstPickup.address.timeZone || auth.getUserTimeZone
        ),
      })
    }
    return reservation?.trip?.updateRecurringTripsFromDate
      ? dayjs(reservation.trip.updateRecurringTripsFromDate).format(
          'YYYY-MM-DD'
        )
      : dayjs(firstPickup.pickupDatetime)
          .tz(firstPickup.address?.timeZone || auth.getUserTimeZone)
          .format('YYYY-MM-DD')
  }

  handleUpdateRecurringTripsFromDateChange(val: string): void {
    const firstPickup = reservation.trip.stops.find(
      (stop) => !!stop?.pickupDatetime
    )
    reservation.updateTrip({
      updateRecurringTripsFromDate: dayjs(val).tz(
        firstPickup.address.timeZone || auth.getUserTimeZone
      ),
    })
    this.getUpdatedRecurringTripCount()
  }

  async getUpdatedRecurringTripCount(): Promise<void> {
    if (
      !reservation.trip.updateRecurringTripsFromDate ||
      !reservation.trip.updateRecurringTrips
    ) {
      this.recurringTripCount = null
      return
    }
    const recurringTripCountResponse = await tripClient.getRecurringTripCount(
      this.trip.tripId,
      dayjs(reservation.trip.updateRecurringTripsFromDate).toISOString()
    )
    this.recurringTripCount = recurringTripCountResponse?.data?.count
  }

  async handleSave(): Promise<void> {
    if (!this.rstate.tripHasBeenModified) {
      return
    }

    if (!this.validate()) {
      EventBus.$emit(
        'snackbar:error',
        'You are missing some required information, please review and try again.'
      )
      return
    }

    this.loading = true

    try {
      this.cleanGarageTimes(this.trip)
      await tripClient.modifyTripByStops({
        tripId: this.rstate.trip.tripId,
        hardConflictOverride: true,
        payload: this.rstate.trip,
      })
      EventBus.$emit('snackbar:success', 'Reservation successfully saved')
      this.$emit('refresh')
      this.rstate.updateTripHasBeenModified(false)
    } catch (e) {
      EventBus.$emit('snackbar:error', e)
    } finally {
      this.loading = false
    }
  }

  @Watch('draggableModel.length')
  onDraggableModelChange(): void {
    this.resetStopErrors()
  }
  // Whenever the total of a quote or the recurrence on a quote is updated,
  // we need to update the amount due now on a quote so that the
  // payment terms table reflects it correctly
  @Watch('trip.total')
  @Watch('trip.recurrenceTripCount')
  onTripTotalUpdate(): void {
    const amountDueNow = getTripAmountDueNow(this.trip)
    // Overriding deposit amount here so deposit percentage has priority
    // when trip total or trip count changes
    const recurringAmountDueNow = getRecurringAmountDueNow(this.trip, true)
    this.$emit('input', {
      amountDueNow,
      recurringAmountDueNow,
      depositAmount: recurringAmountDueNow,
    })
  }

  getTripAddresses(): ExistingPlace[] {
    return (
      this.trip?.stops
        ?.filter((s) => s.address)
        .map((stop) => {
          return {
            place: stop.address,
            description: stop.address?.addressName || stop.address?.name,
            addressTitle: stop.address?.title,
            stopIndex: stop.orderIndex + 1,
          }
        }) || []
    )
  }

  handleDragStart(e: { oldIndex }): void {
    this.isDraggingStop = true
    this.draggedIndex = e.oldIndex
  }

  handleDragEnd(): void {
    this.isDraggingStop = false
    this.draggedIndex = null
  }
  shouldDisplayLabel(val: number): boolean {
    if (val === 0) {
      return true
    }
    return !!val
  }

  getSelectedVehicleName(vehicle: Vehicle): string {
    const specificVehicle = vehicle.vehicleId
      ? this.vehicleList.find((v) => v.vehicleId === vehicle.vehicleId)
      : null
    return (
      specificVehicle?.vehicleName ||
      this.getVehicleTypeLabel(vehicle.vehicleTypeId)
    )
  }

  getVehicleTypeLabel(id: number): string {
    return this.vehicleTypes.find((type) => type.id === id)?.label
  }

  async calculateArrivalTime(
    existingStop: Stop,
    stopType: TripStopTypeKey,
    defaultSpotTimeOffset: number
  ): Promise<void> {
    const stopIndex = this.getStopIndex(existingStop)
    const stop = this.trip.stops[stopIndex]
    const tz = stop.address?.timeZone || auth.getUserTimeZone
    const previousStop = this.trip.stops[stopIndex - 1]
    const cleanTrip = this.cleanGarageTimes(this.trip)
    const quoteForEstimations = new Quote({ trips: [cleanTrip] })
    const tripEstimation = await quoteClient.tripEstimations(
      quoteForEstimations
    )
    const tripEstimationData = tripEstimation.data[0]
    const duration = tripEstimationData.timesFromPreviousStop[stopIndex]

    // If the previous stop has a pickupDatetime, use this as the base of the
    // calculation; otherwise, use the dropoffDatetime
    let previousStopDatetime

    if (previousStop.pickupDatetime) {
      previousStopDatetime = dayjs(previousStop.pickupDatetime).tz(
        dayjs.tz.guess(),
        true
      )
    } else {
      previousStopDatetime = dayjs(previousStop.dropoffDatetime).tz(
        dayjs.tz.guess(),
        true
      )
    }

    const arrivalDatetime = previousStopDatetime.add(duration, 'seconds')
    const arrivalDate = arrivalDatetime.tz(tz).format('YYYY-MM-DD')
    const arrivalTime = arrivalDatetime.tz(tz).format('HH:mm')

    // If the stop has a dropoff, set the dropoff time to the estimated time
    // Otherwise, if there's no dropoff but there is a pickup, check if there's a spot time
    //   - If there's a spot time, set the spot time to the estimated time, and adjust
    //     the pickup date accordingly
    //   - Else, set the pickup time to the estimated time
    let updatedStop
    if (stopType === 'dropoff_only' || stopType === 'dropoff_and_pickup') {
      updatedStop = {
        ...stop,
        dropoffDatetime: arrivalDatetime.toISOString(),
        dropoffDate: arrivalDate,
        dropoffTime: arrivalTime,
      }
    } else if (existingStop.spotTime?.spotTime || defaultSpotTimeOffset) {
      let spotTimeOffset
      if (existingStop.spotTime?.spotTime) {
        spotTimeOffset = dayjs(existingStop.pickupDatetime).diff(
          existingStop.spotTime.spotTime,
          'minutes'
        )
      } else {
        spotTimeOffset = defaultSpotTimeOffset
      }

      const spotTime = arrivalDatetime
      const pickupDatetime = spotTime
        .add(spotTimeOffset, 'minutes')
        .tz(dayjs.tz.guess(), true)
      const pickupDate = pickupDatetime.tz(tz).format('YYYY-MM-DD')
      const pickupTime = pickupDatetime.tz(tz).format('HH:mm')

      updatedStop = {
        ...stop,
        spotTime: { spotTime },
        pickupDatetime,
        pickupDate,
        pickupTime,
      }
    } else {
      updatedStop = {
        ...stop,
        pickupDatetime: arrivalDatetime.toISOString(),
        pickupDate: arrivalDate,
        pickupTime: arrivalTime,
      }
    }

    this.handleUpdateStop(stop, updatedStop)
  }

  async calculateGarageEstimation(returning: boolean): Promise<void> {
    const quoteForEstimations = new Quote({
      trips: [
        deepClone({
          ...this.trip,
          stops: this.trip.stops.filter((s) => !!s.address),
        }),
      ],
    })
    for (const trip of quoteForEstimations.trips) {
      this.cleanGarageTimes(trip)

      trip.garageTimes.returnGarageId =
        trip.garageTimes.returnGarageId || trip.garageTimes.garageId
    }
    const tripEstimation = await quoteClient.tripEstimations(
      quoteForEstimations
    )
    const tripEstimationData = tripEstimation.data[0]

    if (!returning) {
      const duration = tripEstimationData.firstDeadLegDuration
      const preTripDuration = this.trip.garageTimes.preTripArrivalTime
        ? dayjs(this.trip.garageTimes.departureTime).diff(
            this.trip.garageTimes.preTripArrivalTime,
            'seconds'
          )
        : null
      const firstStop = this.trip.stops[0]
      const firstStopDatetime = firstStop.spotTime
        ? dayjs(firstStop.spotTime.spotTime)
        : dayjs(firstStop.pickupDatetime)
      const newGarageDepartureDateTime = firstStopDatetime.subtract(
        duration,
        'seconds'
      )
      const newGarageArrivalDateTime = preTripDuration
        ? dayjs(newGarageDepartureDateTime).subtract(preTripDuration, 'seconds')
        : null
      this.handleUpdateGarageTimes({
        ...this.trip.garageTimes,
        departureTime: newGarageDepartureDateTime.toISOString(),
        preTripArrivalTime: newGarageArrivalDateTime?.toISOString() || null,
      })
    } else {
      const duration = tripEstimationData.lastDeadLegDuration
      const lastStop = this.trip.stops[this.trip.stops.length - 1]
      const lastStopDatetime = dayjs(lastStop.dropoffDatetime)
      const newGarageReturnDateTime = lastStopDatetime.add(duration, 'seconds')
      this.handleUpdateGarageTimes({
        ...this.trip.garageTimes,
        returnTime: newGarageReturnDateTime.toISOString(),
      })
    }

    this.resetGarageErrors(returning ? 'return' : 'depart')
  }

  cleanGarageTimes(trip: Trip): Trip {
    if (trip.garageTimes?.returnTime === 'invalid') {
      trip.garageTimes.returnTime = null
    }
    if (trip.garageTimes?.departureTime === 'invalid') {
      trip.garageTimes.departureTime = null
    }
    return trip
  }

  getStopIndex(stop: Stop): number {
    return this.trip.stops.findIndex(
      ({ orderIndex }) => orderIndex === stop.orderIndex
    )
  }

  getDefaultDateForDepartingGarage(): string {
    if (this.allStopsWithDatesAsc.length) {
      return (
        this.allStopsWithDatesAsc[0].pickupDate ||
        this.allStopsWithDatesAsc[0].dropoffDate
      )
    }
    return null
  }

  getDefaultDateForReturningGarage(): string {
    if (this.allStopsWithDatesDesc.length) {
      return (
        this.allStopsWithDatesDesc[0].pickupDate ||
        this.allStopsWithDatesDesc[0].dropoffDate
      )
    }
    return null
  }

  getDefaultDateForStop(idx: number): string {
    const currentStop = this.stops[idx]
    const previousStopsWithDates = this.allStopsWithDatesAsc.filter(
      (s) => s?.orderIndex < currentStop?.orderIndex
    )

    // Check if any of the previous stops have dates
    if (previousStopsWithDates.length) {
      return (
        previousStopsWithDates[0].pickupDate ||
        previousStopsWithDates[0].dropoffDate
      )
    }

    if (this.allStopsWithDatesAsc.length) {
      return (
        this.allStopsWithDatesAsc[0].pickupDate ||
        this.allStopsWithDatesAsc[0].dropoffDate
      )
    }

    return null
  }

  canCalculateArrival(stop: Stop): boolean {
    const stopIndex = this.getStopIndex(stop)
    if (stopIndex === 0) {
      return false
    }
    if (
      (this.trip.stops[stopIndex - 1]?.pickupDatetime ||
        this.trip.stops[stopIndex - 1]?.dropoffDatetime) &&
      this.trip.stops[stopIndex - 1]?.address &&
      this.trip.stops[stopIndex]?.address
    ) {
      return true
    }
  }

  canCalculateGarageEstimation(
    options: { returning: boolean } = { returning: false }
  ): boolean {
    const garageId = options.returning
      ? this.trip.garageTimes?.returnGarageId
      : this.trip.garageTimes?.garageId

    if (!this.trip.stops.length || !garageId) {
      return false
    }

    const firstStop = this.trip.stops[0]
    const lastStop = this.trip.stops[this.trip.stops.length - 1]

    if (
      (options.returning && lastStop.dropoffDatetime && lastStop.address) ||
      (!options.returning && firstStop.pickupDatetime && firstStop.address)
    ) {
      return true
    }
    return false
  }

  vehicleType(vehicle: Vehicle): VehicleType {
    return this.vehicleTypes[vehicle.vehicleTypeId]
  }

  vehicleName(vehicleId: number): string {
    if (this.vehicleList) {
      return this.vehicleList?.find(
        (vehicle) => vehicle.vehicleId === vehicleId
      )?.vehicleName
    }
  }

  setTripContact(options: {
    contact: SimpleContact
    contactType: ContactTypeKey
    tripId: number
  }): void {
    if (
      options.tripId !== this.trip.tripId &&
      this.trip.tripContactId !== options.contact.id
    ) {
      return
    }
    let tripContact: Partial<TripContact>
    if (!options.contact) {
      tripContact = null
    } else {
      tripContact = {
        id: options.contact.id,
        firstName: options.contact.firstName,
        lastName: options.contact.lastName,
        email: options.contact.email,
        phone: options.contact.phone,
        phoneExtension: options.contact.phoneExtension,
        phoneCountryKey: options.contact.phoneCountryKey,
      }
    }
    this.$emit('input', {
      ...this.trip,
      tripContact,
      tripContactId: tripContact?.id,
    })
  }

  handleEditContact(): void {
    sidebar.push({
      component: ContactSidebarDetail,
      props: {
        userId: this.trip.tripContactId,
        simple: true,
        contactType: 'Trip',
        tripId: this.trip.tripId,
      },
    })
  }

  handleAddTripContact(): void {
    sidebar.push({
      component: AddContactSidebar,
      title: 'Add Trip Contact',
      props: {
        contactId: this.trip.tripContactId,
        label: 'Trip',
        tripId: this.trip.tripId,
        contactType: 'Trip',
      },
    })
  }

  handleAddVehicle(): void {
    const vehicles: Vehicle[] = [...(this.trip.vehicles || []), new Vehicle()]
    this.$emit('input', { vehicles })
  }

  handleAddStop(): void {
    const index = this.stops.length === 1 ? 1 : this.stops.length - 1
    let stops: Stop[] = [...this.stops]
    const stop: Stop = new Stop({ orderIndex: index })
    stops.splice(index, 0, stop)
    stops = stops.map((stop, i) => ({ ...stop, orderIndex: i }))
    this.$emit('input', { ...this.trip, stops })
  }

  shouldUpdateLastStopAddress(
    stopIdx: number,
    oldStop: Stop,
    newStop: Stop,
    lastStop: Stop
  ): boolean {
    const oldStopAddressName = oldStop.address?.addressName
    const newStopAddressName = newStop.address?.addressName

    const isFirstStop = stopIdx === 0
    const hasOldStopAddressNameChanged =
      oldStopAddressName !== newStopAddressName
    const oldStopAddressEmpty = !oldStop.address
    const newStopHasAddress = !!newStop.address
    const isRoundTrip = this.trip.tripTypeKey === 'round_trip'
    const lastStopAddressEmpty = !lastStop.address

    return (
      isFirstStop &&
      (oldStopAddressEmpty || hasOldStopAddressNameChanged) &&
      newStopHasAddress &&
      isRoundTrip &&
      lastStopAddressEmpty
    )
  }

  handleUpdateStop(oldStop: Stop, stop: Stop, silent = false): void {
    const stopIdx = this.getStopIndex(oldStop)
    let stops: Stop[] = [...this.stops]
    const lastStop = stops[stops.length - 1]

    const shouldUpdateLastStopAddress = this.shouldUpdateLastStopAddress(
      stopIdx,
      oldStop,
      stop,
      lastStop
    )

    if (shouldUpdateLastStopAddress) {
      stops.splice(stops.length - 1, 1, {
        ...lastStop,
        address: stop.address,
      })
    }
    stops.splice(stopIdx, 1, stop)
    stops = stops.map((stop, orderIndex) => ({ ...stop, orderIndex }))
    const startDate = stops[0]?.pickupDatetime || stops[0]?.dropoffDatetime
    let endDate =
      stops[stops.length - 1]?.dropoffDatetime ||
      stops[stops.length - 1]?.pickupDatetime

    if (startDate && endDate) {
      if (dayjs(startDate).isAfter(dayjs(endDate))) {
        endDate = startDate
      }
    }

    let recurrences = deepClone(this.trip.recurrences)
    if (recurrences?.length) {
      recurrences = recurrences.map(r => ({...r, startDate }))
    }

    this.$emit(silent ? 'input-silent' : 'input', {
      ...this.trip,
      recurrences,
      stops,
      startDate,
      endDate,
    })
    this.$nextTick(this.prepopulateGarageTimes)
    this.validateFormSilently()
  }

  prepopulateGarageTimes(): void {
    const { garageTimes } = this.trip
    const { departureTime, returnTime, garageId, returnGarageId } =
      garageTimes || {}
    if (
      !this.isDepartureGarageDirty &&
      !departureTime &&
      garageId === this.defaultGarageId &&
      this.canCalculateGarageEstimation({ returning: false })
    ) {
      this.calculateGarageEstimation(false)
    }

    if (
      !this.isReturnGarageDirty &&
      !returnTime &&
      returnGarageId === this.defaultGarageId &&
      this.canCalculateGarageEstimation({ returning: true })
    ) {
      this.calculateGarageEstimation(true)
    }
  }

  handleDeleteStop(stop: Stop): void {
    const stopIdx = this.getStopIndex(stop)
    let stops: Stop[] = [...this.stops]
    stops.splice(stopIdx, 1)
    stops = stops.map((stop, orderIndex) => ({ ...stop, orderIndex }))

    const firstStop = stops.find((stop) => stop.orderIndex === 0)
    const lastStop = stops.find((stop) => stop.orderIndex === stops.length - 1)

    firstStop.dropoffDatetime = null
    firstStop.dropoffDate = null
    firstStop.dropoffTime = null
    lastStop.pickupDatetime = null
    lastStop.pickupDate = null
    lastStop.pickupTime = null

    this.$emit('input', { ...this.trip, stops })
  }

  handleUpdateTripType(tripType: TripType): void {
    this.tripTypeError = null
    const trip: Partial<Trip> = {
      tripType,
      tripTypeId: tripType.id,
      tripTypeKey: tripType.key,
    }
    const stops = [...this.trip.stops]
    const allStopsAreBlank = stops.every((s) => !s.address)
    if (
      tripType.key === 'round_trip' &&
      allStopsAreBlank &&
      stops.length === 2
    ) {
      this.handleAddStop()
    }

    this.$emit('input', trip)
    this.validateFormSilently()
  }

  handleUpdatePassengerCount(passengerCount: number): void {
    this.passengerCountError = null
    this.$emit('input', { passengerCount })
    this.validateFormSilently()
  }

  handleUpdateRequiredDrivers(requiredDrivers: number): void {
    this.requiredDriversError = null
    this.$emit('input', { requiredDrivers })
    this.validateFormSilently()
  }

  handleVehicleChange(idx: number, vehicleId: number): void {
    const vehicle = this.vehicleList.find((v) => v.vehicleId === vehicleId)
    const vehicleType = this.vehicleTypes.find(
      (v) => v.id === vehicle?.vehicleTypeId
    )
    if (vehicle && vehicleType) {
      const vehicles: Vehicle[] = [...this.trip.vehicles]
      vehicles[idx] = {
        ...vehicles[idx],
        vehicleId: vehicle.vehicleId,
        vehicleName: vehicle.vehicleName,
        vehicleTypeId: vehicleType.id,
      }

      this.$emit('input', { vehicles })

      this.validateFormSilently()
    }
  }

  handleVehicleTypeChange(idx: number, vehicleTypeId: number): void {
    const vehicleType = this.vehicleTypes.find((v) => v.id === vehicleTypeId)
    if (vehicleType) {
      const vehicles: Vehicle[] = [...this.trip.vehicles]
      vehicles[idx] = {
        ...vehicles[idx],
        vehicleId: null,
        vehicleName: null,
        vehicleTypeId: vehicleType.id,
      }

      this.$emit('input', { vehicles })
      this.validateFormSilently()
    }
  }

  handleVehicleSelectChange(id: number): void {
    if (id == null) {
      return
    }
    this.vehicleSelectErrorMessage = null
  }

  handleUpdateVehicleQuantity(idx: number, quantity: number): void {
    const vehicles: Vehicle[] = [...this.trip.vehicles]
    if (quantity > 0) {
      vehicles.splice(idx, 1, { ...vehicles[idx], quantity })
    } else if (vehicles.length > 1) {
      vehicles.splice(idx, 1)
    }

    this.$emit('input', { vehicles })
  }

  handleUpdateDepartureGarageTime(
    garageTimes: GarageTimes,
    silent = false
  ): void {
    this.isDepartureGarageDirty = true
    this.handleUpdateGarageTimes(garageTimes, silent)
  }

  handleUpdateReturnGarageTime(garageTimes: GarageTimes, silent = false): void {
    this.isReturnGarageDirty = true
    this.handleUpdateGarageTimes(garageTimes, silent)
  }

  handleUpdateGarageTimes(garageTimes: GarageTimes, silent = false): void {
    this.$emit(silent ? 'input-silent' : 'input', { garageTimes })
  }

  created(): void {
    this.typeState.getAllTypes()
    companyStopsStore.loadSavedCompanyStops()
  }

  mounted(): void {
    EventBus.$on('contact-sidebar:update', this.setTripContact)
    EventBus.$on('contact:update', this.setTripContact)
  }

  beforeDestroy(): void {
    EventBus.$off('contact-sidebar:update', this.setTripContact)
    EventBus.$off('contact:update', this.setTripContact)
  }

  validateVehicleSelect(): boolean {
    this.vehicleSelectErrorMessage = ''

    const hasTripVehicle = !!this.trip.vehicles.find(
      (vehicle) => !!vehicle.vehicleTypeId
    )
    if (!hasTripVehicle) {
      this.vehicleSelectErrorMessage = 'Required'
    }
    return hasTripVehicle
  }

  resetErrorsForStopCard(idx: number): void {
    for (let i = idx; i < this.draggableModel.length; i++) {
      this.stopErrors[i] = {
        address: false,
        dropoff: {},
        pickup: {},
        spotTime: false,
      }
    }

    if (idx === 0) {
      this.resetGarageErrors('depart')
    } else if (idx === this.draggableModel.length - 1) {
      this.resetGarageErrors('return')
    }
  }

  validateAllStopsWithDateHaveAddress(): boolean {
    let errorExists = false
    for (const stopIndex in this.draggableModel) {
      const stop = this.draggableModel[stopIndex]
      if (stop.type === 'button') {
        continue
      }
      const { address, pickupDatetime, dropoffDatetime } = stop
      if (!address && (pickupDatetime || dropoffDatetime)) {
        this.stopErrors[stopIndex] = this.stopErrors[stopIndex] || {}
        this.stopErrors[stopIndex].address = true
        errorExists = true
      }
    }
    return errorExists
  }

  validateAllStopsHaveAddress(): boolean {
    let errorExists = false
    for (const stopIndex in this.draggableModel) {
      const stop = this.draggableModel[stopIndex]
      if (stop.type === 'button') {
        continue
      }
      const { address } = stop
      if (!address) {
        this.stopErrors[stopIndex] = this.stopErrors[stopIndex] || {}
        this.stopErrors[stopIndex].address = true
        errorExists = true
      }
    }
    return errorExists
  }

  // Given an index, returns the latest pickup and dropoff datetimes
  // for all stops before that index
  getLatestDatetimesForIndex(index: number | string): string {
    index = Number(index)
    const dates = this.draggableModel
      .slice(0, index)
      .filter((s) => s.type !== 'button')
      .filter((s) => !!s.address)
      .map((s) => [s.pickupDatetime, s.dropoffDatetime].filter((d) => !!d))
      .flat()
      .sort((a, b) => (a < b ? 1 : -1))

    return dates[0]
  }

  getLatestDateForIndex(index: number | string): string {
    index = Number(index)
    const dates = this.draggableModel
      .slice(0, index)
      .filter((s) => s.type !== 'button')
      .filter((s) => !!s.address)
      .map((s) => [s.pickupDate, s.dropoffDate].filter((d) => !!d))
      .flat()
      .sort((a, b) => (a < b ? 1 : -1))

    return dates[0]
  }

  // Check whether a stop has a date without a time, or a time without a date
  validateMissingDatesAndTimes(stop: Stop, errors: StopErrors): StopErrors {
    const {
      pickupDatetime,
      dropoffDatetime,
      pickupTime,
      pickupDate,
      dropoffTime,
      dropoffDate,
      address,
    } = stop

    if (!pickupDatetime) {
      if (pickupDate && !pickupTime) {
        errors.pickup = { ...errors.pickup, missingTime: true }
      } else if (pickupTime && !pickupDate) {
        errors.pickup = { ...errors.pickup, missingDate: true }
      } else if (!pickupDate && !pickupTime && !dropoffDatetime && !!address) {
        errors.pickup = {
          ...errors.pickup,
          missingDate: true,
          missingTime: true,
        }
      }
    }

    if (!dropoffDatetime) {
      if (dropoffDate && !dropoffTime) {
        errors.dropoff = { ...errors.dropoff, missingTime: true }
      } else if (dropoffTime && !dropoffDate) {
        errors.dropoff = { ...errors.dropoff, missingDate: true }
      } else if (!dropoffDate && !dropoffTime && !pickupDatetime && !!address) {
        errors.dropoff = {
          ...errors.dropoff,
          missingDate: true,
          missingTime: true,
        }
      }
    }
    return errors
  }

  // Check whether a garage:
  // - Has a date without a time or a time without a date
  // - For a departure garage:
  //   - Has a departure datetime after the first stop's pickup datetime
  //   - Has a departure datetime after the first stop's spot time
  //   - Has a preTripArrivalTime after the first stop's pickup datetime
  //   - Has a preTripArrivalTime after the first stop's spot time
  // - For a return garage:
  //   - Has a return datetime before the last stop's dropoff datetime
  validateGarageTimes(): GarageError {
    const { garageTimes, stops } = this.trip
    const { departureTime, returnTime, preTripArrivalTime } = garageTimes || {}
    const departGarageHasAddress = !!garageTimes?.garage?.address
    const returnGarageHasAddress = !!garageTimes?.returnGarage?.address

    let garageErrors: GarageError = {
      depart: {},
      return: {},
    }

    if (!garageTimes) {
      return garageErrors
    }

    if (departureTime === 'invalid') {
      garageErrors.depart.isInvalidDatetime = true
    } else if (!departureTime && departGarageHasAddress) {
      garageErrors.depart.isInvalidDatetime = true
    }

    if (returnTime === 'invalid') {
      garageErrors.return.isInvalidDatetime = true
    } else if (!returnTime && returnGarageHasAddress) {
      garageErrors.return.isInvalidDatetime = true
    }

    const firstStop = stops[0]
    const lastStop = stops[stops.length - 1]
    const {
      pickupDatetime: firstStopPickupDatetime,
      pickupDate: firstStopPickupDate,
    } = firstStop
    const { address: departureAddress } = garageTimes.garage || {}
    const { address: returnGarageAddress } = garageTimes.returnGarage || {}

    if (
      firstStopPickupDatetime &&
      departureTime &&
      dayjs(firstStopPickupDatetime).isBefore(dayjs(departureTime)) &&
      departGarageHasAddress
    ) {
      const departureDate = dayjs(departureTime)
        .tz(departureAddress.timeZone)
        .format('YYYY-MM-DD')

      // Check if the first stop's pickup date is before the departure date
      if (dayjs(firstStopPickupDate).isBefore(departureDate)) {
        garageErrors.depart.isDateOutOfOrder = true
      } else {
        garageErrors.depart.isTimeOutOfOrder = true
      }
    }

    if (
      lastStop?.dropoffDatetime &&
      returnTime &&
      dayjs(lastStop.dropoffDatetime).isAfter(dayjs(returnTime)) &&
      returnGarageHasAddress
    ) {
      const returnDate = dayjs(returnTime)
        .tz(returnGarageAddress.timeZone)
        .format('YYYY-MM-DD')

      if (dayjs(lastStop.dropoffDate).isAfter(returnDate)) {
        garageErrors.return.isDateOutOfOrder = true
      } else {
        garageErrors.return.isTimeOutOfOrder = true
      }
    }

    if (
      firstStop?.pickupDatetime &&
      preTripArrivalTime &&
      dayjs(firstStop.pickupDatetime).isBefore(dayjs(preTripArrivalTime)) &&
      departGarageHasAddress
    ) {
      garageErrors.depart.isPreTripArrivalTimeInvalid = true
    }

    if (
      firstStop?.spotTime?.spotTime &&
      preTripArrivalTime &&
      dayjs(firstStop.spotTime.spotTime).isBefore(dayjs(preTripArrivalTime)) &&
      departGarageHasAddress
    ) {
      garageErrors.depart.isPreTripArrivalTimeInvalid = true
    }

    if (
      firstStop?.spotTime?.spotTime &&
      departureTime &&
      dayjs(firstStop.spotTime.spotTime).isBefore(dayjs(departureTime)) &&
      departGarageHasAddress
    ) {
      garageErrors.depart.isDateOutOfOrder = true
    }

    garageErrors = this.validateGarageLocations(garageErrors)

    return garageErrors
  }

  validateGarageLocations(garageErrors: GarageError): GarageError {
    garageErrors.depart.isGarageMissing = false
    garageErrors.return.isGarageMissing = false

    if (!this.trip.garageTimes?.garageId) {
      if (this.trip.garageTimes?.departureTime) {
        garageErrors.depart.isGarageMissing = true
      }
    }

    if (!this.trip.garageTimes?.returnGarageId) {
      if (this.trip.garageTimes?.returnTime) {
        garageErrors.return.isGarageMissing = true
      }
    }

    return garageErrors
  }

  validateStopsAreInOrder(
    stopIndex: number,
    stop: Stop,
    errors: StopErrors
  ): StopErrors {
    const {
      pickupDatetime: pickupDatetimeStr,
      dropoffDatetime: dropoffDatetimeStr,
      pickupDate: pickupDateStr,
      dropoffDate: dropoffDateStr,
    } = stop

    const pickupDatetime = pickupDatetimeStr ? dayjs(pickupDatetimeStr) : null
    const dropoffDatetime = dropoffDatetimeStr
      ? dayjs(dropoffDatetimeStr)
      : null
    const pickupDate = pickupDateStr ? dayjs(pickupDateStr) : null
    const dropoffDate = dropoffDateStr ? dayjs(dropoffDateStr) : null

    const latestDatetime = this.getLatestDatetimesForIndex(stopIndex)
    const latestDateStr = this.getLatestDateForIndex(stopIndex)
    const latestDate = latestDateStr ? dayjs(latestDateStr) : null

    // Validate if the pickup date is before the dropoff date
    if (
      pickupDatetime &&
      dropoffDatetime &&
      pickupDatetime.isBefore(dropoffDatetime)
    ) {
      if (pickupDate.isBefore(dropoffDate)) {
        errors.pickup = { ...errors.pickup, dateOutOfOrder: true }
      } else {
        errors.pickup = { ...errors.pickup, timeOutOfOrder: true }
      }
    }

    // Validate if the pickup date is out of order
    if (
      pickupDatetime &&
      latestDatetime &&
      dayjs(pickupDatetime).isBefore(dayjs(latestDatetime))
    ) {
      if (pickupDate.isBefore(latestDate)) {
        errors.pickup = { ...errors.pickup, dateOutOfOrder: true }
      } else {
        errors.pickup = { ...errors.pickup, timeOutOfOrder: true }
      }
    }

    // Validate if the dropoff date is out of order
    if (
      dropoffDatetime &&
      latestDatetime &&
      dayjs(dropoffDatetime).isBefore(dayjs(latestDatetime))
    ) {
      if (dropoffDate.isBefore(latestDate)) {
        errors.dropoff = { ...errors.dropoff, dateOutOfOrder: true }
      } else {
        errors.dropoff = { ...errors.dropoff, timeOutOfOrder: true }
      }
    }

    return errors
  }

  doesStopOrGarageErrorExist(
    stopErrors: Record<number, StopErrors>,
    garageErrors: GarageError
  ): boolean {
    // Given an object made up of either nested objects
    // or booleans, return an array of all the boolean values
    // E.g. { a: { b: true, c: false }, d: true } => [true, false, true]
    const extractBooleanValues = (obj) => {
      const boolArr = []
      for (const prop in obj) {
        if (typeof obj[prop] === 'boolean') {
          boolArr.push(obj[prop])
        } else if (typeof obj[prop] === 'object') {
          boolArr.push(...extractBooleanValues(obj[prop]))
        }
      }
      return boolArr
    }

    const stopErrorValues = Object.values(stopErrors)
    const hasStopErrors = stopErrorValues.some((value) =>
      extractBooleanValues(value).some((bool) => !!bool)
    )

    const hasGarageErrors = Object.values(garageErrors).some((error) =>
      Object.values(error).some((value) => !!value)
    )

    return hasStopErrors || hasGarageErrors
  }

  validateSpotTimes(
    stopIndex: number,
    stop: Stop,
    errors: StopErrors
  ): StopErrors {
    const { spotTime, dropoffDatetime } = stop
    const spotTimeExists = !!spotTime
    const latestDatetime = this.getLatestDatetimesForIndex(stopIndex)

    if (!spotTimeExists) {
      return errors
    }
    if (
      latestDatetime &&
      dayjs(spotTime.spotTime).isBefore(dayjs(latestDatetime))
    ) {
      errors.spotTime = { ...errors.spotTime, isBeforePrevStop: true }
    }

    if (
      dropoffDatetime &&
      dayjs(spotTime.spotTime).isBefore(dayjs(dropoffDatetime))
    ) {
      errors.spotTime = { ...errors.spotTime, isBeforeDropoff: true }
    }

    return errors
  }

  // For a trip, check the following:
  // - Validate that the stops are in order
  // - Validate that the spot times are valid given their order
  // - Validate that the dates and times are not missing on stops
  // - Validate that the garages are in order
  // - Validate that the garages with dates and times are not missing locations
  validateStopsAndGarageTimes(): boolean {
    const stopErrors = { ...this.stopErrors }

    for (const [stopIndex, stop] of this.draggableModel.entries()) {
      let errors: StopErrors = {
        address: false,
        dropoff: {},
        pickup: {},
        spotTime: {},
      }

      if (stop.type === 'button') {
        continue
      }

      errors = this.validateStopsAreInOrder(stopIndex, stop, errors)
      errors = this.validateSpotTimes(stopIndex, stop, errors)
      errors = this.validateMissingDatesAndTimes(stop, errors)

      stopErrors[stopIndex] = errors
    }

    const garageErrors = this.validateGarageTimes()
    const errorExists = this.doesStopOrGarageErrorExist(
      stopErrors,
      garageErrors
    )

    this.garageErrors = garageErrors
    this.stopErrors = stopErrors

    if (!errorExists) {
      this.$emit('trip-details:reset-errors')
    }
    return !errorExists
  }

  // Without surfacing errors, check whether the trip details form is valid
  // If it is, then emit a 'reset' event up to the parent component,
  // so that the parent component can remove any errors from the needed
  // tab
  async validateFormSilently(): Promise<boolean> {
    let isFormValid = true
    await this.$nextTick(() => {
      if (!this.trip.passengerCount) {
        isFormValid = false
      }

      if (!this.trip.tripTypeId) {
        isFormValid = false
      }

      if (!this.trip.requiredDrivers) {
        isFormValid = false
      }

      const hasTripVehicle = !!this.trip.vehicles.find(
        (vehicle) => !!vehicle.vehicleTypeId
      )
      if (!hasTripVehicle) {
        isFormValid = false
      }

      const allStopsHaveAddress = this.trip.stops.reduce(
        (aggregate, stop) => aggregate && !!stop.address,
        true
      )
      if (!allStopsHaveAddress) {
        isFormValid = false
      }
    })

    if (isFormValid) {
      this.$emit('trip-details:reset-errors')
    }
    return isFormValid
  }

  validateTripDetails(): boolean {
    if (!this.trip.tripType || this.trip.tripType?.key === 'none') {
      this.tripTypeError = 'Required'
    }

    if (!Number(this.trip.passengerCount)) {
      this.passengerCountError = 'Required'
    }

    if (!Number(this.trip.requiredDrivers)) {
      this.requiredDriversError = 'Required'
    }

    return !(
      !!this.tripTypeError ||
      !!this.passengerCountError ||
      !!this.requiredDriversError
    )
  }

  validate(skipStopsAndGarages = false): boolean {
    const isFormValid = this.validateTripDetails()
    const isVehicleSelectValid = this.validateVehicleSelect()
    const isValidStopsAndGarages = skipStopsAndGarages
      ? true
      : this.validateStopsAndGarageTimes()

    return isFormValid && isVehicleSelectValid && isValidStopsAndGarages
  }

  hasError(obj: Record<string, boolean>): boolean {
    return Object.values(obj).reduce((a, o) => a || o, false)
  }

  getStopErrors(idx: number): boolean {
    const errors = this.stopErrors[idx]
    if (!errors) {
      return false
    }

    if (
      this.hasError(errors.pickup) ||
      this.hasError(errors.dropoff) ||
      errors.spotTime?.isBeforePrevStop ||
      errors.spotTime?.isBeforeDropoff ||
      errors.address
    ) {
      return true
    }
    return false
  }

  resetGarageErrors(key: string): void {
    this.garageErrors[key] = {}
  }

  resetStopErrors(): void {
    const errors = {}

    for (const index in this.draggableModel) {
      errors[index] = {
        address: false,
        dropoff: {},
        pickup: {},
        spotTime: {},
      }
    }
    this.stopErrors = errors
  }
}
