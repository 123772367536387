import { SubTicket } from './dto/Ticket'

export interface Comment {
  text: string
  createdAt: Date
  createdById: number
  id?: number
  fullName?: string
}

export function convertSubTicketToComment(
  subTicket: SubTicket,
  fullName: string
): Comment {
  const comment: Comment = {
    text: subTicket.comments,
    createdAt: subTicket.createdAt,
    createdById: subTicket.createdById,
    id: subTicket.subTicketId,
    fullName,
  }
  return comment
}

export function convertCommentToSubTicket(comment: Comment): SubTicket {
  const subTicket: SubTicket = {
    comments: comment.text,
    createdAt: comment.createdAt,
    createdById: comment.createdById,
  }
  return subTicket
}
