
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class CUAutocomplete extends Vue {
  @Prop({ type: Boolean, default: false }) readonly hideInnerLabel!: boolean
  @Prop({ type: Boolean, default: false }) readonly centered!: boolean

  get listeners(): Record<string, unknown> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this
    return Object.assign({}, this.$listeners, {
      input(event: InputEvent) {
        vm.$emit('input', event)
      },
    })
  }
}
