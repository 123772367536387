
import { Action } from '@/models/dto/Action'
import { Prop } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import rstate from '@/store/modules/reservation'
import reservation from '@/services/reservation'
import { PaymentTransaction } from '@/models/dto/PaymentTransaction'
import CUSimpleTable from '@/components/CUSimpleTable.vue'
import { SimpleTableColumn } from '@/models/SimpleTableColumn'
import { cleanEmptyHtml, currencyFilter } from '@/utils/string'
import SimpleTableCreatedCell from '@/components/SimpleTableCreatedCell.vue'
import { Transaction } from '@/models/dto/Transaction'
import PaymentDetails from '@/components/PaymentDetails.vue'
import sidebar from '@/store/modules/sidebar'
import LogPaymentSidebar from './LogPaymentSidebar.vue'
import ReservationProcessPaymentSidebar from '@/components/ReservationProcessPaymentSidebar.vue'
import { EventBus } from '@/utils/eventBus'
import AddRefundSidebar from '@/components/AddRefundSidebar.vue'
import ReservationChargesSidebar from '@/components/ReservationChargesSidebar.vue'
import CreateInvoiceSidebar from '@/components/CreateInvoiceSidebar.vue'
import { SourceCategory } from '@/utils/enum'
import auth from '@/store/modules/auth'
import { UserGroupIds } from '@/data/userGroups'
import CUDataTableVoidPaymentLineItem from './CUDataTableVoidPaymentLineItem.vue'
import app from '@/store/modules/app'
import SyncToQuickbooksCustomerSidebar from '@/components/SyncToQuickbooksCustomerSidebar.vue'
import quickbooks from '@/services/quickbooks'
import QuickbooksReservationDetailPanel from './QuickbooksReservationDetailPanel.vue'
import HoldUpModal from './HoldUpModal.vue'
import { ContactQuickbooksDetail } from '@/models/dto/Quickbooks'

@Component({
  components: {
    CUSimpleTable,
    PaymentDetails,
    HoldUpModal,
  },
})
export default class ReservationPayment extends mixins(DateMixin) {
  isSelectQBCustomerModalOpen = false
  isReservationSyncModalOpen = false
  quickbooksUser: ContactQuickbooksDetail = null
  submittingReservationSync = false

  rstate = rstate

  selectQBCustomerModalConfirmationQuestion = `Are you sure you want to sync this Reservation?<br><br>In order to <strong>create an Invoice</strong> in your QuickBooks account you have to connect this Contact with a QuickBooks Customer.`

  get reservationSyncConfirmationQuestion(): string {
    return `Are you sure you want to sync this Reservation?<br><br>This action will <strong>create an Invoice</strong> in your QuickBooks account for QuickBooks Customer ${this.quickbooksUser?.quickbooksCustomerName} (ID ${this.quickbooksUser?.quickbooksCustomerId})`
  }

  get transactions(): PaymentTransaction[] {
    return rstate.transactions || []
  }

  get actions(): Action[] {
    const actions = [
      {
        label: 'Invoice',
        id: 'invoice',
        event: 'reservation-payment:invoice',
      },
      {
        label: 'Process Payment',
        id: 'process',
        event: 'reservation-payment:process',
      },
      {
        label: 'Log Payment',
        id: 'log',
        event: 'reservation-payment:log',
      },
      {
        label: 'Add Charge',
        id: 'add-charge',
        event: 'reservation-payment:add',
      },
      {
        label: 'Reduce Charge',
        id: 'reduce',
        event: 'reservation-payment:reduce',
      },
      {
        label: 'Log Refund',
        id: 'refund',
        event: 'reservation-payment:refund',
      },
    ]
    if (
      auth.isQuickbooksIntegrationEnabled &&
      !rstate.isReservationSyncedWithQuickbooks &&
      !rstate.isReservationQuickbooksInvoicePending
    ) {
      actions.push({
        label: 'Sync With Quickbooks',
        id: 'quickbooks-sync',
        event: 'quickbooks-invoice:sync',
      })
    }

    return actions
  }

  get invoiceLastSentDate(): string {
    return rstate.reservation?.invoiceLastSentDate
      ? this.formatShortDateShortTime(rstate.reservation?.invoiceLastSentDate, {
          showMeridianUpper: true,
        })
      : 'never'
  }

  get totalCharges(): string {
    return currencyFilter(
      this.transactions?.reduce((sum, transaction) => {
        sum += transaction.isVoid ? 0 : transaction.charge || 0
        return sum
      }, 0)
    )
  }

  get totalPayments(): string {
    return currencyFilter(
      this.transactions?.reduce((sum, transaction) => {
        sum -= transaction.isVoid ? 0 : transaction.payment || 0
        return sum
      }, 0)
    )
  }

  get totalBalance(): string {
    return currencyFilter(
      this.transactions?.reduce((sum, transaction) => {
        sum -= transaction.isVoid ? 0 : transaction.payment || 0
        sum += transaction.isVoid ? 0 : transaction.charge || 0
        return sum
      }, 0)
    )
  }

  get isReferral(): boolean {
    return rstate.reservation?.sourceCategory === SourceCategory.REFERRAL
  }

  get isAdmin(): boolean {
    return auth.getUser?.groupId === UserGroupIds.ADMIN
  }

  async handleSyncReservation(): Promise<void> {
    this.submittingReservationSync = true
    try {
      await this.createQuickbooksInvoice()
      await rstate.fetchReservationInvoiceDetails()
      if (rstate.isReservationSyncedWithQuickbooks) {
        sidebar.popAllAndPush({
          component: QuickbooksReservationDetailPanel,
          width: 764,
          wide: true,
          persist: true,
        })
      }
    } catch (e) {
      console.log(e)
      EventBus.$emit(
        'snackbar:error',
        'Unable to create QuickBooks Invoice from Reservation'
      )
    }
    this.submittingReservationSync = false
  }

  async createQuickbooksInvoice(): Promise<void> {
    try {
      await quickbooks.createInvoiceFromReservations(
        auth.getCompanyId,
        rstate.reservation.reservationId
      )
    } catch (e) {
      EventBus.$emit(
        'snackbar:error',
        'Unable to create QuickBooks Invoice from Reservation'
      )
    }
  }

  handleClickRow(transaction: Transaction): void {
    sidebar.popAllAndPush({
      component: PaymentDetails,
      props: {
        transaction,
      },
      width: 350,
      title: `${this.getTransactionType(transaction)} Details`,
    })
  }

  handleInvoice(): void {
    // this.$router.push({
    //   name: 'invoice',
    //   params: { id: this.reservation.hash },
    // })
    sidebar.push({
      title: 'Create Invoice',
      component: CreateInvoiceSidebar,
    })
  }

  handleLogPayment(): void {
    sidebar.push({
      title: 'Log Payment',
      component: LogPaymentSidebar,
      props: { isSingleReservationPayment: true },
    })
  }

  handleProcessPayment(): void {
    sidebar.push({
      component: ReservationProcessPaymentSidebar,
      title: 'Process Payment',
      props: { isSingleReservationPayment: true },
    })
  }

  handleAddRefund(): void {
    sidebar.push({
      component: AddRefundSidebar,
      title: 'Log Refund',
    })
  }

  async handleQuickbooksSync(): Promise<void> {
    const userId = rstate?.reservation?.customerId
    let quickbooksCustomerDetailResponse
    if (userId) {
      try {
        // Start the Quickbooks fetch and handle its error separately
        quickbooksCustomerDetailResponse =
          await quickbooks.getContactQuickbooksDetail(auth.getCompanyId, userId)
      } catch (e) {
        // This will handle errors in the customer fetch only, because the Quickbooks error is caught separately
        console.log('Error with customer fetch:', e)
      }
      if (
        quickbooksCustomerDetailResponse?.data?.customerSync
          ?.quickbooksCustomerId
      ) {
        // hold up modal
        this.quickbooksUser =
          quickbooksCustomerDetailResponse?.data?.customerSync
        this.isReservationSyncModalOpen = true
        return
      }
      this.isSelectQBCustomerModalOpen = true
    }
  }

  openCustomerQuickbooksSyncSidebar(): void {
    sidebar.push({
      component: SyncToQuickbooksCustomerSidebar,
      title: 'Sync to QuickBooks Customer',
      props: {
        userId: rstate?.reservation?.customerId,
      },
    })
  }

  handleAddCharge(): void {
    sidebar.push({
      component: ReservationChargesSidebar,
      title: 'Add Charge',
    })
  }

  handleReduceCharge(): void {
    sidebar.push({
      component: ReservationChargesSidebar,
      title: 'Reduce Charge',
      props: { reduceCharge: true },
    })
  }

  headers: SimpleTableColumn<PaymentTransaction>[] = [
    {
      text: 'Created',
      value: 'createdOn',
      type: 'component',
      component: SimpleTableCreatedCell,
      width: 140,
    },
    {
      text: 'Type',
      value: 'transactionType',
      type: 'text',
      width: 140,
      formatter: (row: PaymentTransaction): string =>
        this.getTransactionType(row),
    },
    {
      text: 'Name',
      value: 'description',
      type: 'text',
      width: 160,
    },
    {
      text: 'Payment Notes',
      value: 'customerNotes',
      type: 'text',
      formatter: (row: PaymentTransaction): string => row.customerNotes || '--',
    },
    {
      text: 'Internal Notes',
      value: 'notes',
      type: 'text',
      formatter: (row: PaymentTransaction): string =>
        cleanEmptyHtml(row.notes) || '--',
    },
    {
      text: 'Amount',
      value: 'amount',
      type: 'number',
      align: 'end',
      width: 80,
      formatter: (row: PaymentTransaction): string => this.amountFormatter(row),
    },
  ]

  amountFormatter(row: PaymentTransaction): string {
    if (row.charge) {
      return currencyFilter(row.charge)
    } else if (row.payment) {
      if (row.description.includes('Refund')) {
        return currencyFilter(row.payment)
      }
      return currencyFilter(row.payment * -1)
    }
    return '--'
  }

  getTransactionType(row: PaymentTransaction | Transaction): string {
    let transactionType = '--'
    if (
      row?.description?.includes('Refund') &&
      row?.description !== 'Refund Adjustment'
    ) {
      transactionType = 'Refund'
    } else if (row.charge) {
      transactionType = 'Charge'
    } else if (row.payment) {
      transactionType = 'Payment'
    }
    if (row.isVoid) {
      transactionType += ' (voided)'
    }
    return transactionType
  }

  placeholder(): void {
    return
  }

  updateInvoiceLastSentDate(): void {
    rstate.updateReservation({ invoiceLastSentDate: new Date().toISOString() })
  }

  mounted(): void {
    EventBus.$on(
      'reservation:update-invoice-last-sent-date',
      this.updateInvoiceLastSentDate
    )

    if (this.isAdmin) {
      this.headers.push({
        text: '',
        value: '',
        type: 'component',
        component: CUDataTableVoidPaymentLineItem,
      })
    }
  }

  beforeDestroy(): void {
    EventBus.$off(
      'reservation:update-invoice-last-sent-date',
      this.updateInvoiceLastSentDate
    )
  }
}
