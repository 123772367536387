
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Tag } from '@/models/dto/Tag'
import tagsClient from '@/services/tag'

@Component({})
export default class QuoteTagSelector extends Vue {
  @Prop({ required: true }) readonly quoteId!: number
  @Prop({ required: false }) readonly inputTags!: Tag[]
  @Prop({ type: Boolean, default: false }) isReservation: boolean
  addingTag = false
  tags: Tag[] = []
  newTagInput: string = null

  toggleAddTag(): void {
    this.addingTag = !this.addingTag
  }

  async addNewTag(): Promise<void> {
    if (!this.newTagInput) {
      return
    }
    const tagName = this.newTagInput
    const tagPayload = {
      tagName,
    }

    const createTagResponse = await tagsClient.createTag(tagPayload)
    const newTagData = createTagResponse.data?.tag
    this.tags.push(newTagData)
    this.newTagInput = ''

    if (this.quoteId) {
      tagsClient.addQuoteTags([newTagData.tagId], this.quoteId)
    } else {
      this.$emit('tag:update', this.tags)
    }
  }

  removeTag(tagId: string): void {
    const tagIdAsNumber = parseInt(tagId)
    this.tags = this.tags.filter((tag: Tag) => {
      return tag.tagId !== tagIdAsNumber
    })
    if (this.quoteId) {
      tagsClient.removeQuoteTag(tagIdAsNumber, this.quoteId)
    } else {
      this.$emit('tag:update', this.tags)
    }
  }

  async mounted(): Promise<void> {
    if (!this.quoteId) {
      this.tags = [...this.inputTags]
      return
    }
    const currentQuoteTagsResponse = await tagsClient.byQuoteId(this.quoteId)
    const currentQuoteTags = currentQuoteTagsResponse.data?.tags
    this.tags = currentQuoteTags
  }
}
