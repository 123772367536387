
import { BillingOverrideVehicleView } from '@/models/BillingOverride'
import {
  convertMinutesToHoursAndMinutes,
  getMinutesBetweenDates,
} from '@/utils/time'
import dayjs from 'dayjs'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import state from '@/store/modules/reservation'
import { formatMinutesToTimeLabel } from '@/utils/posttrip'

@Component({})
export default class ReservationPostTripVehicleBody extends Vue {
  @Prop({ required: true }) vehicle!: BillingOverrideVehicleView
  @Prop({ type: Boolean, default: false }) loading!: boolean

  isDirty = false

  @Watch('vehicle', { deep: true })
  onVehicleChange(): void {
    this.isDirty = true
  }

  get pickupAndDropoffDifferenceLabel(): string {
    let totalMinutes = 0
    if (this.vehicle.startDatetime && this.vehicle.finishDatetime) {
      totalMinutes = getMinutesBetweenDates(
        this.vehicle.startDatetime,
        this.vehicle.finishDatetime
      )
    }
    const timeLabel = totalMinutes ? formatMinutesToTimeLabel(totalMinutes) : ''
    return timeLabel
  }

  get mileageDifferenceLabel(): string {
    if (
      this.odometryReadingAtStart === null ||
      this.odometryReadingAtFinish === null
    ) {
      return '-- mi'
    }
    const difference = (
      this.odometryReadingAtFinish - this.odometryReadingAtStart
    ).toFixed(2)
    return `${difference} mi`
  }

  get pickupDate(): string {
    return dayjs
      .utc(this.vehicle.startDatetime)
      .tz(state.firstStopTimeZone)
      .format('YYYY-MM-DD')
  }

  get pickupTime(): string {
    return dayjs
      .utc(this.vehicle.startDatetime)
      .tz(state.firstStopTimeZone)
      .format('HH:mm')
  }

  get dropoffDate(): string {
    return dayjs
      .utc(this.vehicle.finishDatetime)
      .tz(state.lastStopTimeZone)
      .format('YYYY-MM-DD')
  }

  get dropoffTime(): string {
    return dayjs
      .utc(this.vehicle.finishDatetime)
      .tz(state.lastStopTimeZone)
      .format('HH:mm')
  }

  get billableHours(): number {
    const { hours } = convertMinutesToHoursAndMinutes(
      this.vehicle.billableMinutes
    )

    return hours
  }

  get billableMinutes(): number {
    const { minutes } = convertMinutesToHoursAndMinutes(
      this.vehicle.billableMinutes
    )

    return minutes
  }

  get odometryReadingAtStart(): number {
    return this.vehicle.odometryReadingAtStart
  }

  get odometryReadingAtFinish(): number {
    return this.vehicle.odometryReadingAtFinish
  }

  get formattedOdometryReadingAtStart(): string {
    if (this.odometryReadingAtStart == null) {
      return ''
    }
    return parseFloat(this.odometryReadingAtStart.toFixed(2)).toLocaleString(
      'en-US',
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    )
  }

  get formattedOdometryReadingAtFinish(): string {
    if (this.odometryReadingAtFinish == null) {
      return ''
    }
    return parseFloat(this.odometryReadingAtFinish.toFixed(2)).toLocaleString(
      'en-US',
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    )
  }

  get formattedBillableMiles(): string {
    if (this.billableMiles == null) {
      return ''
    }
    return parseFloat(this.billableMiles.toFixed(2)).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }

  get billableMiles(): number {
    return this.vehicle.billableMiles
  }

  get plannedMinutes(): number {
    return getMinutesBetweenDates(
      state.firstPostTripDatetime,
      state.lastPostTripDatetime
    )
  }

  get plannedMiles(): number {
    return state.postTripDistanceMiles
  }

  get billableHoursLabel(): number {
    return Math.floor(this.vehicle.billableMinutes / 60)
  }

  get billableMinutesLabel(): number {
    return this.vehicle.billableMinutes % 60
  }

  handlePickupDateChange(e: string): void {
    let event = {}
    let currentTime = dayjs.utc()
    if (this.vehicle.startDatetime) {
      currentTime = dayjs.utc(this.vehicle.startDatetime)
    }
    const timePart = currentTime.tz(state.firstStopTimeZone).format('HH:mm:ss')
    const newLocalDatetime = dayjs.tz(
      `${e}T${timePart}`,
      state.firstStopTimeZone
    )
    const newVehicleStartDatetime = newLocalDatetime
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss')
    event = { ...event, startDatetime: newVehicleStartDatetime }
    if (this.vehicle.finishDatetime) {
      let totalMinutes = getMinutesBetweenDates(
        newVehicleStartDatetime,
        this.vehicle.finishDatetime
      )

      totalMinutes = Math.abs(totalMinutes)
      const billableMinuteDifference = Math.round(
        totalMinutes - this.plannedMinutes
      )

      event = {
        ...event,
        billableMinutes: totalMinutes,
        billableMinuteDifference,
      }
    }

    this.$emit('change', event)
    return
  }

  handlePickupTimeChange(e: string): void {
    let event = {}
    let currentDate = dayjs.utc()
    if (this.vehicle.startDatetime) {
      currentDate = dayjs.utc(this.vehicle.startDatetime)
    }
    const datePart = currentDate
      .tz(state.firstStopTimeZone)
      .format('YYYY-MM-DD')

    const newLocalDatetime = dayjs.tz(
      `${datePart}T${e}`,
      state.firstStopTimeZone
    )

    const newVehicleStartDatetime = newLocalDatetime
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss')

    event = { ...event, startDatetime: newVehicleStartDatetime }
    if (this.vehicle.finishDatetime) {
      let totalMinutes = getMinutesBetweenDates(
        newVehicleStartDatetime,
        this.vehicle.finishDatetime
      )

      totalMinutes = Math.abs(totalMinutes)
      const billableMinuteDifference = Math.round(
        totalMinutes - this.plannedMinutes
      )

      event = {
        ...event,
        billableMinutes: totalMinutes,
        billableMinuteDifference,
      }
    }

    this.$emit('change', event)

    return
  }

  handleDropoffDateChange(e: string): void {
    let event = {}
    let currentTime = dayjs.utc()
    if (this.vehicle.finishDatetime) {
      currentTime = dayjs.utc(this.vehicle.finishDatetime)
    }
    const timePart = currentTime.tz(state.lastStopTimeZone).format('HH:mm:ss')
    const newLocalDatetime = dayjs.tz(
      `${e}T${timePart}`,
      state.lastStopTimeZone
    )
    const newVehicleFinishDateTime = newLocalDatetime
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss')
    event = { ...event, finishDatetime: newVehicleFinishDateTime }
    if (this.vehicle.startDatetime) {
      let totalMinutes = getMinutesBetweenDates(
        this.vehicle.startDatetime,
        newVehicleFinishDateTime
      )

      totalMinutes = Math.abs(totalMinutes)

      const billableMinuteDifference = Math.round(
        totalMinutes - this.plannedMinutes
      )
      event = {
        ...event,
        billableMinutes: totalMinutes,
        billableMinuteDifference,
      }
    }

    this.$emit('change', event)
    return
  }

  handleDropoffTimeChange(e: string): void {
    let event = {}
    let currentDate = dayjs.utc()
    if (this.vehicle.finishDatetime) {
      currentDate = dayjs.utc(this.vehicle.finishDatetime)
    }
    const datePart = currentDate.tz(state.lastStopTimeZone).format('YYYY-MM-DD')
    const newLocalDatetime = dayjs.tz(
      `${datePart}T${e}`,
      state.lastStopTimeZone
    )
    const newVehicleFinishDateTime = newLocalDatetime
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss')

    event = { ...event, finishDatetime: newVehicleFinishDateTime }
    if (this.vehicle.startDatetime) {
      let totalMinutes = getMinutesBetweenDates(
        this.vehicle.startDatetime,
        newVehicleFinishDateTime
      )

      totalMinutes = Math.abs(totalMinutes)

      const billableMinuteDifference = Math.round(
        totalMinutes - this.plannedMinutes
      )
      event = {
        ...event,
        billableMinutes: totalMinutes,
        billableMinuteDifference,
      }
    }

    this.$emit('change', event)

    return
  }

  handleBillableHoursChange(e: string): void {
    let parsedEvent = parseFloat(e.replace(/[^0-9.]/g, ''))
    if (isNaN(parsedEvent)) {
      parsedEvent = 0
    }

    parsedEvent = Math.abs(parsedEvent)
    const totalMinutes = Number(
      (parsedEvent * 60 + this.billableMinutesLabel).toFixed(0)
    )
    const plannedMinutes = this.plannedMinutes
    const billableMinuteDifference = parseFloat(
      (totalMinutes - Number(plannedMinutes)).toFixed(2)
    )

    this.$emit('change', {
      billableMinutes: totalMinutes,
      billableMinuteDifference,
    })
  }

  handleBillableMinutesChange(e: number): void {
    if (isNaN(e)) {
      e = 0
    }

    e = Math.abs(e)

    const totalMinutes = Number(
      (Number(e) + this.billableHoursLabel * 60).toFixed(0)
    )
    const plannedMinutes = this.plannedMinutes
    const billableMinuteDifference = totalMinutes - Number(plannedMinutes)

    this.$emit('change', {
      billableMinutes: totalMinutes,
      billableMinuteDifference,
    })
  }

  handleOdometryReadingAtStartChange(e: string): void {
    let odometryReadingAtStart = parseFloat(e.replace(/[^0-9.]/g, ''))
    if (isNaN(odometryReadingAtStart)) {
      odometryReadingAtStart = 0
    }
    let event = {}

    odometryReadingAtStart = Number(Number(odometryReadingAtStart).toFixed(2))
    if (this.odometryReadingAtFinish != null) {
      let billableMiles = Number(
        (this.odometryReadingAtFinish - odometryReadingAtStart).toFixed(2)
      )
      billableMiles = Math.abs(billableMiles)
      const billableMileDifference = Number(
        (billableMiles - this.plannedMiles).toFixed(2)
      )
      event = {
        ...event,
        billableMileDifference,
        plannedMiles: this.plannedMiles,
        billableMiles,
      }
    }
    event = { ...event, odometryReadingAtStart }
    this.$emit('change', event)
  }

  handleOdometryReadingAtFinishChange(e: string): void {
    let odometryReadingAtFinish = parseFloat(e.replace(/[^0-9.]/g, ''))
    if (isNaN(odometryReadingAtFinish)) {
      odometryReadingAtFinish = 0
    }
    let event = {}
    odometryReadingAtFinish = Number(Number(odometryReadingAtFinish).toFixed(2))
    if (this.odometryReadingAtStart != null) {
      let billableMiles = Number(
        (odometryReadingAtFinish - this.odometryReadingAtStart).toFixed(2)
      )

      billableMiles = Math.abs(billableMiles)
      const billableMileDifference = Number(
        (billableMiles - this.plannedMiles).toFixed(2)
      )
      event = {
        ...event,
        billableMileDifference,
        billableMiles,
        plannedMiles: this.plannedMiles,
      }
    }
    event = {
      ...event,
      odometryReadingAtFinish,
    }
    this.$emit('change', event)
  }

  handleBillableMilesChange(billableMiles: string): void {
    let parsedMiles = parseFloat(billableMiles.replace(/[^0-9.]/g, ''))
    if (isNaN(parsedMiles)) {
      parsedMiles = 0
    }
    parsedMiles = Math.abs(parsedMiles)
    const plannedMiles = state.postTripDistanceMiles
    const billableMileDifference = parseFloat(
      (parsedMiles - Number(plannedMiles)).toFixed(2)
    )
    this.$emit('change', { billableMiles: parsedMiles, billableMileDifference })
  }

  handleSave(): void {
    this.$emit('save')
    this.isDirty = false
  }
}
