
import { Vue, Component, Prop } from 'vue-property-decorator'
import CustomerContactSearch from '@/components/CustomerContactSearch.vue'
import { SimpleContact } from '@/models/dto/Customer'
import sidebar from '@/store/modules/sidebar'

@Component({
  components: {
    CustomerContactSearch,
  },
})
export default class ReservationChangeContactSidebar extends Vue {
  @Prop({ required: true, default: null }) contacts: SimpleContact[]
  contact: SimpleContact = null

  clear(): void {
    sidebar.pop()
  }

  submit(): void {
    this.$emit('contact-search:change', this.contact)
    sidebar.close()
    return
  }

  handleSelectContact(e: SimpleContact): void {
    this.contact = e
  }
}
