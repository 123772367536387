
import reservation from '@/store/modules/reservation'
import { Prop, Watch } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import {
  capitalizeFirstLetter,
  currencyFilter,
  percentageFormatter,
  phoneFormatFilter,
  pluralize,
  stringToFloat,
} from '@/utils/string'
import dayjs from 'dayjs'
import ReservationDriverPayStats from '@/components/ReservationDriverPayStats.vue'
import ReservationDriverPayHeader from '@/components/ReservationDriverPayHeader.vue'
import ReservationDriverPayFooter from '@/components/ReservationDriverPayFooter.vue'
import client from '@/services/driverpay'
import {
  DriverPaymentCharge,
  RateType,
  UpdateChargesRequest,
} from '@/models/DriverPay'
import auth from '@/store/modules/auth'
import { EventBus } from '@/utils/eventBus'
import { getTripBaseFare } from '@/utils/trip'

@Component({
  components: {
    ReservationDriverPayStats,
    ReservationDriverPayHeader,
    ReservationDriverPayFooter,
  },
})
export default class ReservationDriverPayBody extends mixins(DateMixin) {
  @Prop({ required: true }) invoice: any
  @Prop({ required: true }) assignmentIdx: number
  @Prop({ type: Boolean, required: true }) isOriginal: boolean

  capitalizeFirstLetter = capitalizeFirstLetter
  phoneFormatFilter = phoneFormatFilter
  currencyFilter = currencyFilter
  percentageFormatter = percentageFormatter
  state = reservation

  isLoading = false
  isSaving = false
  isDirty = false

  invoiceDetails = null
  paystubDetails = null
  charges = []
  driverDefaultRates = []
  companyDefaultRates = null
  rateTypes: RateType[] = []

  get visibleRateTypes(): RateType[] {
    return this.rateTypes.filter((r) => !r.isHidden)
  }

  getVisibleRateTypesForCharge(charge: DriverPaymentCharge): RateType[] {
    return this.rateTypes.filter(
      (rateType) =>
        !rateType.isHidden ||
        rateType.id === charge.rateTypeId ||
        (charge.rateTypeId === null &&
          rateType.isDefault &&
          rateType.baseRateKey === charge.details.rate)
    )
  }

  @Watch('charges', { deep: true })
  onChargesUpdate(): void {
    this.isDirty = true
    EventBus.$emit('driver-pay:update-invoice-total', {
      assignmentIdx: this.assignmentIdx,
      duplicateIdx: this.invoice.duplicateIndex || null,
      total: this.totalCharges,
    })
  }

  get isPaid(): boolean {
    return this.invoice.status.toLowerCase() === 'paid'
  }

  get isPending(): boolean {
    return this.invoice.status.toLowerCase() === 'pending'
  }

  get isReviewed(): boolean {
    return this.invoice.status.toLowerCase() === 'reviewed'
  }

  get isDraft(): boolean {
    return this.invoice.status.toLowerCase() === 'draft'
  }

  isPercentage(index: number): boolean {
    return this.basePayCharges[index].details?.rate === 'PERCENTAGE'
  }

  get basePayCharges(): any[] {
    return this.charges.filter((c) => c.chargeType === 'BASE_PAY' && !c.delete)
  }

  get reimbursementCharges(): any[] {
    return this.charges.filter(
      (c) => c.chargeType === 'REIMBURSEMENT' && !c.delete
    )
  }

  get passedThroughCharges(): any[] {
    return this.charges.filter(
      (c) => c.chargeType === 'PASSED_THROUGH' && !c.delete
    )
  }

  get totalTime(): number {
    return Math.abs(
      Math.ceil(
        dayjs(this.invoice.stop).diff(this.invoice.start, 'minutes') / 60
      )
    )
  }

  get totalCharges(): number {
    return this.charges.reduce((acc, c) => acc + (!c.delete ? c.total : 0), 0)
  }

  get totalBasePay(): number {
    return this.basePayCharges.reduce((acc, c) => acc + c.total, 0)
  }

  get totalReimbursementCharges(): number {
    return this.reimbursementCharges.reduce((acc, c) => acc + c.total, 0)
  }

  get totalPassedThroughCharges(): number {
    return this.passedThroughCharges.reduce((acc, c) => acc + c.total, 0)
  }

  get defaultRate(): any {
    const vehicleTypeIds = this.state.assignments.reduce((obj, a) => {
      obj[a.vehicleId] = a.vehicleTypeId
      return obj
    }, {})

    return (
      this.driverDefaultRates.find(
        (r) => r.vehicleType.id === vehicleTypeIds[this.invoice.vehicleId]
      ) ||
      this.driverDefaultRates.find((r) => r.vehicleType.id === null) ||
      {}
    )
  }

  get totalBaseFareCharges(): number {
    return getTripBaseFare(this.state.trip)
  }

  getDefaultChargeAmount(
    method = this.companyDefaultRates?.defaultMethod
  ): number {
    switch (method) {
      case 'HOURLY':
        return this.defaultRate.hourlyRate || 0
      case 'DAILY':
        return this.defaultRate.dailyRate || 0
      case 'PERCENTAGE':
        return this.companyDefaultRates.percentConfig.type === 'BASE_FARE'
          ? this.totalBaseFareCharges
          : this.state.reservation.amount || 0
      case 'PER_TRIP':
        return this.defaultRate.perTripMinRate || 0
      case 'PER_DIEM':
        return this.defaultRate.perDiem || 0
      default:
        return 0
    }
  }

  getDefaultCount(method = this.companyDefaultRates?.defaultMethod): number {
    const hours = Math.max(
      this.totalTime || 0,
      this.companyDefaultRates?.perTripMinHours || -1
    )
    const days =
      Math.abs(
        dayjs(dayjs(this.invoice.stop).startOf('day')).diff(
          dayjs(this.invoice.start).startOf('day'),
          'day'
        )
      ) + 1

    switch (method) {
      case 'HOURLY':
        return hours
      case 'DAILY':
        return days
      case 'PERCENTAGE':
        return this.companyDefaultRates.percentConfig.type === 'BASE_FARE'
          ? this.defaultRate.percentageBase
          : this.defaultRate.percentageTotal || 0
      case 'PER_DIEM':
        return days
      case 'PER_TRIP':
      case 'GRATUITY':
        return 1
      default:
        return 1
    }
  }

  async fetchRateTypes(): Promise<void> {
    try {
      const response = await client.getCompanyRateTypes()
      this.rateTypes = response.data.sort((a, b) => a.orderIndex - b.orderIndex)
    } catch (error) {
      console.error('Error fetching rate types:', error)
      EventBus.$emit('snackbar:error', 'Failed to load rate types')
    }
  }

  displayChargeAmount(index: number): string {
    return this.basePayCharges[index].details.amount
      ? this.currencyFilter(this.basePayCharges[index].details.amount)
      : ''
  }

  displayCountAsPercentage(index: number): string {
    return this.basePayCharges[index].details.count
      ? this.percentageFormatter(this.basePayCharges[index].details.count)
      : ''
  }

  displayChargeTotal(index: number): string {
    return this.basePayCharges[index].total
      ? this.currencyFilter(this.basePayCharges[index].total)
      : ''
  }

  displayReimbursementTotal(index: number): string {
    return this.reimbursementCharges[index].total
      ? this.currencyFilter(this.reimbursementCharges[index].total)
      : ''
  }

  async handleSave(
    options = { refresh: true, skipWaiting: false }
  ): Promise<void> {
    this.isSaving = true

    // For now, default invoice start / end to the reservation start/end dates
    const start = reservation.firstDatetime
    const stop = reservation.lastDatetime
    const payload: UpdateChargesRequest = {
      invoiceId: this.invoice.id,
      charges: this.charges,
      start,
      stop,
      companyId: auth.getCompanyId,
    }

    try {
      const res = await client.updateInvoiceCharges(payload)
      if (options.refresh) {
        EventBus.$emit(
          'snackbar:success',
          'Driver invoice updated successfully'
        )
        reservation.updateInvoice({
          invoiceId: this.invoice.id,
          invoiceStatus: 'DRAFT',
          invoiceTotal: this.totalCharges,
        })

        await this.loadChargeData()
        this.isDirty = false
      }
      EventBus.$emit('refresh-tableview')
    } catch (e) {
      if (options.refresh) {
        EventBus.$emit('snackbar:error', 'Failed to update driver invoice')
      }
    }

    if (!options.skipWaiting) {
      this.isSaving = false
    }
  }

  async handleMarkAsDraft(): Promise<void> {
    this.isSaving = true
    const invoiceIds = [this.invoice.id]
    const status = 'DRAFT'

    const res = await client.updateInvoiceStatuses(invoiceIds, status)

    if (res.status === 200) {
      EventBus.$emit('snackbar:success', 'Driver invoice updated successfully')
      reservation.updateInvoice({
        invoiceId: this.invoice.id,
        invoiceStatus: 'DRAFT',
        invoiceTotal: this.totalCharges,
      })
    } else {
      EventBus.$emit('snackbar:error', 'Failed to update driver invoice')
    }

    EventBus.$emit('refresh-tableview')
    this.isSaving = false
  }

  async handleMarkAsReviewed(): Promise<void> {
    this.isSaving = true
    const invoiceIds = [this.invoice.id]
    const status = 'REVIEWED'

    await this.handleSave({ refresh: false, skipWaiting: true })
    const res = await client.updateInvoiceStatuses(invoiceIds, status)

    if (res.status === 200) {
      EventBus.$emit('snackbar:success', 'Driver invoice updated successfully')
      reservation.updateInvoice({
        invoiceId: this.invoice.id,
        invoiceStatus: 'REVIEWED',
        invoiceTotal: this.totalCharges,
      })
    } else {
      EventBus.$emit('snackbar:error', 'Failed to update driver invoice')
    }

    EventBus.$emit('refresh-tableview')
    this.isSaving = false
  }

  getTimeDifference(data: any): { hours: string; days: string } {
    if (!data.startDate || !data.endDate) {
      return { hours: '0 hours', days: '0 days' }
    }

    const hours = Math.abs(
      dayjs(`${data.startDate} ${data.startTime || '00:00'}`).diff(
        dayjs(`${data.endDate} ${data.endTime || '00:00'}`),
        'hour'
      )
    )
    const days = Math.abs(Math.ceil(hours / 24))

    return {
      hours: `${hours} ${pluralize(hours, 'hour')}`,
      days: `${days} ${pluralize(days, 'day')}`,
    }
  }

  handleBasePayRateTypeUpdate(rateId: string, index: number): void {
    const charge = this.basePayCharges[index]
    const visibleRateTypes = this.getVisibleRateTypesForCharge(charge)
    const selectedRate = visibleRateTypes.find((r) => r.id === rateId)

    if (selectedRate) {
      charge.rateTypeId = selectedRate.id
      charge.details.rate = selectedRate.baseRateKey

      if (selectedRate.isDefault) {
        const newCharge = this.getDefaultBasePayCharge(
          charge,
          selectedRate.baseRateKey
        )
        charge.details.count = newCharge.details.count
        charge.details.amount = newCharge.details.amount
        charge.total = newCharge.total
      } else {
        charge.details.amount = null
        charge.details.count = null
        charge.total = null
      }
    }
  }

  handleBasePayAmountUpdate(value: string, index: number): void {
    const charge = this.basePayCharges[index]
    const rate = stringToFloat(value)
    const total = rate * charge.details.count
    charge.details.amount = rate
    charge.total =
      charge.details.rate === 'PERCENTAGE' ? Math.round(total) / 100 : total
  }

  handleBasePayCountUpdateAsPercentage(value: string, index: number): void {
    const charge = this.basePayCharges[index]
    const count = stringToFloat(value)
    const total = Math.round(charge.details.amount * count) / 100
    charge.details.count = count
    charge.total = total
  }

  handleBasePayCountUpdate(value: string, index: number): void {
    const charge = this.basePayCharges[index]
    let count = Number(value)
    if (count < 1 || isNaN(count)) {
      count = 1
    }
    const total = charge.details.amount * count
    charge.details.count = count
    charge.total = total
  }

  handleBasePayTotalUpdate(value: string, index: number): void {
    const charge = this.basePayCharges[index]
    let total = stringToFloat(value)
    if (total < 1 || isNaN(total)) {
      total = 0
    }
    const amount = Math.round((total / charge.details.count) * 100) / 100
    charge.total = amount * charge.details.count
    charge.details.amount = amount
  }

  handleBasePayNotesUpdate(value: string, index: number): void {
    const charge = this.basePayCharges[index]
    charge.notes = value
  }

  deleteBasePayCharge(bIndex: number): void {
    const charge = this.charges.filter(
      (c) => c.chargeType === 'BASE_PAY' && !c.delete
    )[bIndex]
    charge.delete = true
  }

  deleteReimbursementCharge(bIndex: number): void {
    const charge = this.charges.filter(
      (c) => c.chargeType === 'REIMBURSEMENT' && !c.delete
    )[bIndex]
    charge.delete = true
  }

  addBasePayCharge(): void {
    this.charges.push(this.getDefaultBasePayCharge())
  }

  addReimbursementCharge(): void {
    this.charges.push(this.getDefaultReimbursementCharge())
  }

  handleReimbursementNameUpdate(value: string, index: number): void {
    const charge = this.reimbursementCharges[index]
    charge.details.name = value
  }

  handleReimbursementDateUpdate(value: string, index: number): void {
    const charge = this.reimbursementCharges[index]
    charge.details.date = value
  }

  handleReimbursementTotalUpdate(value: string, index: number): void {
    const charge = this.reimbursementCharges[index]
    let total = stringToFloat(value)
    if (total < 1 || isNaN(total)) {
      total = 0
    }
    charge.total = total
  }

  handleReimbursementNotesUpdate(value: string, index: number): void {
    const charge = this.reimbursementCharges[index]
    charge.notes = value
  }

  handlePassedThroughChargeAmountUpdate(value: string, index: number): void {
    const charge = this.passedThroughCharges[index]
    let amount = Number(value)
    if (amount < 1 || isNaN(amount)) {
      amount = 0
    }
    charge.amount = amount
  }

  handlePassedThroughChargeNotesUpdate(value: string, index: number): void {
    const charge = this.passedThroughCharges[index]
    charge.notes = value
  }

  deletePassedThroughCharge(index: number): void {
    const charge = this.passedThroughCharges[index]
    charge.delete = true
  }

  async loadInvoiceData(): Promise<void> {
    const invoiceRes = await client.invoiceById(this.invoice.id)
    if (!invoiceRes.data) {
      return
    }
    this.invoiceDetails = invoiceRes.data
  }

  async loadPaystubData(): Promise<void> {
    if (!this.invoiceDetails.payStubId) {
      return
    }
    const paystubRes = await client.paystubById(this.invoiceDetails.payStubId)
    if (!paystubRes.data) {
      return
    }
    this.paystubDetails = paystubRes.data
  }

  async loadChargeData(): Promise<void> {
    const chargeRes = await client.chargesByInvoiceId(this.invoice.id)
    if (!chargeRes.data?.length) {
      return
    }
    const charges = chargeRes.data

    this.charges = charges.map((c) => {
      const charge = { ...c, delete: false }
      if (c.chargeType === 'REIMBURSEMENT') {
        charge.date = c.date || ''
        charge.name = c.name || ''
      }
      return charge
    })
  }

  async loadDefaultRates(): Promise<void> {
    const [driverDefaults, companyDefaults] = await Promise.all([
      client.ratesByDriverId(this.invoice.driver.userId),
      client.getCompanyDefaults(auth.getCompanyId),
    ])
    this.driverDefaultRates = driverDefaults.data
    this.companyDefaultRates = companyDefaults.data
  }

  async mounted(): Promise<void> {
    this.isLoading = true

    await Promise.all([
      this.loadInvoiceData(),
      this.loadChargeData(),
      this.loadDefaultRates(),
      this.fetchRateTypes(),
    ])
    if (this.invoiceDetails.payStubId) {
      await this.loadPaystubData()
    }
    this.isDirty = false
    this.isLoading = false
  }

  getDefaultReimbursementCharge(): DriverPaymentCharge {
    return {
      id: null,
      invoiceId: this.invoice.id,
      chargeType: 'REIMBURSEMENT',
      createdOn: null,
      total: 0,
      notes: '',
      details: {
        name: '',
        date: '',
      },
      delete: false,
    }
  }

  getDefaultBasePayCharge(
    charge = null,
    rate = this.companyDefaultRates?.defaultMethod
  ): DriverPaymentCharge {
    const amount = this.getDefaultChargeAmount(rate)
    const count = this.getDefaultCount(rate)
    let total = count ? amount * count : null
    if (rate === 'PERCENTAGE') {
      total = Math.round(total) / 100
    }

    return {
      id: charge?.id || null,
      invoiceId: this.invoice.id,
      chargeType: 'BASE_PAY',
      total,
      notes: charge?.notes || '',
      createdOn: charge?.createdOn || null,
      rateTypeId: charge?.rateTypeId || null,
      details: {
        rate,
        amount,
        count,
      },
      delete: false,
    }
  }

  getBasePayChargeRateType(charge: DriverPaymentCharge): RateType {
    if (charge.rateTypeId) {
      return (
        this.rateTypes.find((r) => r.id === charge.rateTypeId) ||
        this.rateTypes[0]
      )
    }
    return (
      this.rateTypes.find(
        (r) => r.baseRateKey === charge.details.rate && r.isDefault
      ) || this.rateTypes[0]
    )
  }

  formatChargeDate(date: string): string {
    return this.formatLongDate(date)
  }

  async handleDuplicate(): Promise<void> {
    if (this.isDraft) {
      await this.handleSave({
        refresh: false,
        skipWaiting: false,
      })
    }

    try {
      const res = await client.duplicateInvoice(this.invoice.id)
      if (res.status === 200) {
        await reservation.fetchInvoices()
      }
      EventBus.$emit('refresh-tableview')
    } catch (e) {
      console.log(e)
      EventBus.$emit(
        'snackbar:error',
        'There was an error duplicating this invoice'
      )
    }
  }

  async handleDeleteInvoice(): Promise<void> {
    try {
      await client.deleteInvoice(this.invoice.id)
      await reservation.fetchInvoices()
      EventBus.$emit('refresh-tableview')
    } catch (e) {
      console.log(e)
      EventBus.$emit(
        'snackbar:error',
        'There was an error deleting this invoice'
      )
    }
  }
}
