
import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator'
import { KeyCode } from '@/utils/enum'
import { Reservation } from '@/models/dto'
import { filter } from '@/utils/filter'
import client from '@/services/reservation'
import { ValidationRule } from '@/utils/rules'

@Component
export default class AutocompleteAddress extends Vue {
  @Model('change') readonly value!: Reservation | null
  @Prop({ type: Boolean, default: false }) readonly autoFocus!: boolean
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean
  @Prop({ type: Boolean, default: false }) readonly hideOptions!: boolean
  @Prop({ default: null }) readonly tabIndex!: number
  @Prop({ default: '' }) readonly identifier!: string
  @Prop({ default: null }) readonly initialSearch!: string | null
  @Prop({ default: null }) readonly appendIcon!: string | null
  @Prop({ default: 'managedId' }) readonly inputValue: string | null
  @Prop({ default: null }) readonly contentWidth: string | null
  @Prop({ type: Boolean, default: false }) readonly validate!: boolean
  @Prop({ required: false, default: '' }) readonly errorMessage!: boolean

  loading = false
  selecting = false
  search: string | null = null
  debounce: number | null = null
  autocompleteItems: Reservation[] = []
  arrowPosition: number | null = null
  isFocused = false

  @Watch('search')
  searchChanged(value: string): void {
    if (value === this.value?.[this.inputValue]) {
      this.reservationAutocomplete(value)
    }
    this.$emit('change:input', this.search)
  }

  @Watch('value', { immediate: true })
  onReservationChange(value: Reservation): void {
    this.search = value?.[this.inputValue] || null
  }

  focus(): void {
    if (!this.$attrs.readonly) {
      this.isFocused = true
    }
  }

  blur(): void {
    this.isFocused = false
  }

  searchReservations(): Promise<Reservation[]> {
    const filters = filter()
    const parentFilter = filters.createParent('and')
    filters.add(parentFilter, {
      column: {
        _t_id: '9753f17c-aa0d-4285-8362-e2563ecce5a2',
        value: 'managedId',
        filterAsIs: true,
        filterProp: 'managedId',
        filterType: 'contains',
      },
      value: this.search,
    })
    const params = {
      page: 1,
      pageSize: 10,
      filters: filters.asQueryParams(),
      sorts: undefined,
    }
    return client.tableView(params).then((res) => res.data?.resultList || [])
  }

  reservationAutocomplete(input: string): void {
    if (!input) {
      this.clearReservation()
      return
    }
    if (this.debounce) {
      window.clearTimeout(this.debounce)
    }
    this.debounce = window.setTimeout(async () => {
      this.search = input
      this.loading = true
      this.arrowPosition = null
      this.autocompleteItems = await this.searchReservations()
      this.loading = false
    }, 250)
  }

  selectReservation(reservation: Reservation): void {
    if (!reservation) {
      return
    }
    this.selecting = true
    this.$nextTick(() => {
      this.$emit('change', reservation)
    })
    this.selecting = false
  }

  clearReservation(): void {
    this.autocompleteItems = []
    this.search = null
    this.$emit('change', null)
  }

  handleKeyEvent(keyCode: number): void {
    const maxPosition = this.autocompleteItems.length
    if (keyCode === KeyCode.UpArrow) {
      if (this.arrowPosition === null) {
        this.arrowPosition = maxPosition
      } else if (this.arrowPosition && this.arrowPosition >= 0) {
        this.arrowPosition--
      }
      return
    }
    if (keyCode === KeyCode.DownArrow) {
      if (this.arrowPosition === null) {
        this.arrowPosition = 0
      } else if (this.arrowPosition + 1 === maxPosition) {
        this.arrowPosition = 0
      } else {
        this.arrowPosition++
      }
      return
    }
    if (keyCode === KeyCode.Enter) {
      if (
        this.arrowPosition !== null &&
        this.autocompleteItems[this.arrowPosition]
      ) {
        this.selectReservation(this.autocompleteItems[this.arrowPosition])
        this.blur()
      }
      return
    }
  }

  get rules(): ValidationRule<string>[] {
    return (this.$attrs?.rules || []) as ValidationRule<string>[]
  }

  get validator(): ValidationRule<string>[] {
    const error =
      this.validate &&
      !this.isFocused &&
      !this.selecting &&
      !!this.search &&
      this.search !== this.value?.[this.inputValue]
    return error ? [(_: string) => this.errorMessage || false] : []
  }
}
