
import { Vue, Component } from 'vue-property-decorator'
import ReservationPostTripStatsHeader from './ReservationPostTripStatsHeader.vue'
import state from '@/store/modules/reservation'
import { BillingOverrideVehicleView } from '@/models/BillingOverride'
import ReservationPostTripVehicleHeader from '@/components/ReservationPostTripVehicleHeader.vue'
import ReservationPostTripVehicleBody from '@/components/ReservationPostTripVehicleBody.vue'
import ReservationPostTripCharge from '@/components/ReservationPostTripCharge.vue'
import billingOverride from '@/services/billingOverride'
import { EventBus } from '@/utils/eventBus'
import deepClone from '@/utils/deepClone'
import dayjs from 'dayjs'
import { getTripPricing } from '@/utils/reservation'
import { BidTripVehicle, Stop, Trip, Vehicle } from '@/models/dto'
import { getMinutesBetweenDates } from '@/utils/time'
import { Pricing, TripPricing } from '@/models/dto/Pricing'
@Component({
  components: {
    ReservationPostTripStatsHeader,
    ReservationPostTripVehicleHeader,
    ReservationPostTripVehicleBody,
    ReservationPostTripCharge,
  },
})
export default class ReservationPostTrip extends Vue {
  loading = false

  get vehicles(): BillingOverrideVehicleView[] {
    return state.billingOverrideView?.vehicles
  }

  get timeZone(): string {
    return state.firstStopTimeZone
  }

  quotedHourlyRate = 0
  quotedMileageRate = 0
  quotedDailyRate = 0

  handleBillingOverrideUpdate(
    billingOverride: Partial<BillingOverrideVehicleView>,
    vehicleId: number
  ): void {
    state.updateBillingOverride({
      billingOverride,
      vehicleId,
    })
    this.$nextTick(this.updateQuotedRates)
  }

  /**
   * Creates a pricing trip object based on the provided vehicle and trip information.
   *
   * This method constructs a new trip object for pricing purposes by incorporating details
   * from the provided vehicle, original trip, first and last stops. It sets the pickup and
   * drop-off datetime, determines override live miles if applicable, and ensures the correct
   * vehicle information is included.
   * If a matching pricing vehicle isn't found for the vehicle's type ID within the original trip,
   * a default pricing vehicle is generated.
   *
   * @param {BillingOverrideVehicleView} vehicle - The vehicle details used to create the pricing trip.
   * @param {Trip & { overrideLiveMiles?: number }} originalTrip - The original trip details.
   * @param {Stop} firstStop - The first stop associated with the trip.
   * @param {Stop} lastStop - The last stop associated with the trip.
   *
   * @returns {Trip & { overrideLiveMiles?: number }} A newly constructed trip object for pricing purposes.
   */
  createPricingTrip(
    vehicle: BillingOverrideVehicleView,
    originalTrip: Trip & { overrideLiveMiles?: number },
    firstStop: Stop,
    lastStop: Stop
  ): Trip & { overrideLiveMiles?: number } {
    const pickupDatetime = dayjs(vehicle.startDatetime).format(
      'YYYY-MM-DDTHH:mm:ss'
    )
    const dropoffDatetime = dayjs(pickupDatetime)
      .add(vehicle.billableMinutes, 'minute')
      .format('YYYY-MM-DDTHH:mm:ss')
    firstStop.pickupDatetime = pickupDatetime
    lastStop.dropoffDatetime = dropoffDatetime

    let overrideLiveMiles = null
    if (vehicle.billableMiles && vehicle.billableMileDifference) {
      overrideLiveMiles = vehicle.billableMiles
    }

    const matchingPricingVehicle =
      originalTrip.vehicles.find(
        (v) => v.vehicleTypeId === vehicle.vehicleTypeId
      ) || this.generateDefaultPricingVehicle(vehicle, originalTrip)

    matchingPricingVehicle.vehicleId = vehicle.vehicleId
    const vehicles = [matchingPricingVehicle]

    const pricingTrip: Trip & { overrideLiveMiles?: number } = {
      ...originalTrip,
      stops: [deepClone(firstStop), deepClone(lastStop)],
      startDate: pickupDatetime,
      endDate: dropoffDatetime,
      vehicles,
      garageTimes: {
        companyId: null,
        tripId: null,
        departureTime: '',
        returnTime: '',
      },
      overrideLiveMiles,
    }

    return pricingTrip
  }

  /**
   * Generates a default pricing vehicle object based on the provided vehicle and trip details.
   *
   * This method produces a default vehicle object with
   * predefined fields, used when a matching pricing vehicle isn't found
   * for a specific vehicle type ID within a trip.
   *
   * @param {BillingOverrideVehicleView} vehicle - The vehicle details used to generate the default pricing vehicle.
   * @param {Trip} originalTrip - The original trip details associated with the vehicle.
   *
   * @returns {Vehicle} A default vehicle object with predefined fields.
   */

  generateDefaultPricingVehicle(
    vehicle: BillingOverrideVehicleView,
    originalTrip: Trip
  ): Vehicle {
    return {
      tripVehicleId: null,
      tripId: originalTrip.tripId,
      vehicleTypeId: vehicle.vehicleTypeId,
      vehicleId: vehicle.vehicleId,
      quantity: 1,
      vehicleType: {
        id: vehicle.vehicleTypeId,
        label: null,
        key: null,
        description: null,
      },
      imagePath: null,
      vehicleTypeKey: null,
      dummy: false,
    }
  }

  /**
   * Calculates the hourly price for a given pricing result based on the hourly rate and hourly minimums
   * provided in the pricing result.
   *
   * @param {TripPricing} pricingResult - The pricing result containing pricing details
   * @param {Trip} equivalentTrip - The trip for which the hourly price needs to be calculated.
   *
   * @returns {number} The calculated hourly price based on the provided pricing result and trip.
   */
  calculateHourlyPrice(
    pricingResult: TripPricing,
    equivalentTrip: Trip
  ): number {
    const pricingVehicle = pricingResult.pricingVehicles[0]
    const { highMarketRate: hourlyRate, hourlyMinimum } = pricingVehicle
    const billableMinutes = getMinutesBetweenDates(
      equivalentTrip.startDate,
      equivalentTrip.endDate
    )

    // Set the billable hours to the greater of the billable minutes or the hourly minimum
    const billableHours = Math.max(
      parseFloat((billableMinutes / 60).toFixed(2)),
      hourlyMinimum
    )
    return hourlyRate * billableHours
  }

  /**
   * Calculate the quoted rates for each pricing method based on the provided pricing results and trips.
   *
   * Iterate through each pricing result and corresponding trip to compute
   * the quoted rates for hourly, daily, and mileage pricing methods.
   * For hourly rates, the function also takes into account the hourly minimums and market rates.
   *
   * @param {Pricing[]} pricingResults - Array of pricing results to be processed.
   * @param {Trip[]} pricingTrips - Array of associated trips corresponding to the pricing results.
   *
   * @returns {Object} An object containing the calculated quoted rates for hourly, daily, and mileage.
   */
  calculateQuotedRates(
    pricingResults: Pricing[],
    pricingTrips: Trip[]
  ): {
    hourly: number
    daily: number
    mileage: number
  } {
    let quotedHourlyRate = 0
    let quotedDailyRate = 0
    let quotedMileageRate = 0

    // eslint-disable-next-line bus-stuff/no-for-each
    pricingResults.forEach((pricingResult, idx) => {
      const { pricings } = pricingResult
      for (const price of pricings) {
        const equivalentTrip = pricingTrips[idx]

        switch (price.pricingMethod) {
          case 'daily_rate':
            quotedDailyRate += price.priceLow
            break
          case 'hourly_rate':
            quotedHourlyRate += this.calculateHourlyPrice(price, equivalentTrip)
            break
          case 'mileage':
            quotedMileageRate += price.priceLow
            break
        }
      }
    })

    return {
      hourly: quotedHourlyRate,
      daily: quotedDailyRate,
      mileage: quotedMileageRate,
    }
  }

  //  * Updates the quoted rates for a set of trips based on billing overrides and vehicle details.
  //  * This method performs the following:
  //  * - Creates pricing trips for each vehicle.
  //  * - Gets the pricing for each trip.
  //  * - Calculates and updates the quoted hourly, daily, and mileage rates.
  async updateQuotedRates(): Promise<void> {
    const quote = state.quote
    const originalTrip = deepClone(state.trip)
    const stops = originalTrip.stops

    const sortedStops = stops.sort((a, b) => a.orderIndex - b.orderIndex)
    const firstStop = sortedStops[0]
    const lastStop = sortedStops[sortedStops.length - 1]

    const pricingTrips = state.billingOverrideView.vehicles.map((vehicle) =>
      this.createPricingTrip(vehicle, originalTrip, firstStop, lastStop)
    )

    const pricingPromises = pricingTrips.map((trip) =>
      getTripPricing(quote, trip, trip.overrideLiveMiles)
    )

    try {
      const pricingResults = await Promise.all(pricingPromises)
      const { hourly, daily, mileage } = await this.calculateQuotedRates(
        pricingResults,
        pricingTrips
      )

      this.quotedHourlyRate = hourly
      this.quotedDailyRate = daily
      this.quotedMileageRate = mileage
    } catch (err) {
      console.error(err)
    }
  }

  async handleSave(): Promise<void> {
    this.loading = true

    try {
      const payload = state.billingOverrideView.vehicles
      const res = await billingOverride.update(payload)
      await this.updateQuotedRates()
      if (res.status === 200) {
        EventBus.$emit('snackbar:success', 'Post Trip was saved successfully')
      }
    } catch (e) {
      console.error(e)
      EventBus.$emit('snackbar:error', 'Error saving Post Trip')
    } finally {
      this.loading = false
    }
  }

  async mounted(): Promise<void> {
    await this.updateQuotedRates()
  }
}
