
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Contact } from '@/models/Contact'
import RecipientList from '@/components/RecipientList.vue'
import { validateRules } from '@/utils/rules'
import auth from '@/store/modules/auth'

@Component({
  components: { RecipientList },
})
export default class SendTripInfoSidebar extends Vue {
  @Prop({ required: false, default: () => [] }) readonly contacts!: Contact[]
  recipients: Contact[] = []

  // POST LAUNCH
  // preview(): void {
  //   this.$emit('preview', auth.getUser)
  // }

  cancel(): void {
    this.$emit('cancel')
  }

  async submit(): Promise<void> {
    const isValid = await validateRules(this)
    if (isValid) {
      this.$emit('notify', this.recipients)
    }
  }

  mounted(): void {
    this.recipients = [...this.contacts]
  }
}
