
import { Component, Prop, Vue } from 'vue-property-decorator'
import { currencyFilter } from '@/utils/string'

@Component({})
export default class CUCurrency extends Vue {
  @Prop({ required: true }) readonly value!: number
  @Prop({ required: false, default: 14 }) readonly size!: number
  @Prop({ type: Boolean, default: false }) readonly auto!: boolean
  @Prop({ type: Boolean, default: false }) readonly positive!: boolean
  @Prop({ type: Boolean, default: false }) readonly negative!: boolean
  @Prop({ type: Boolean, default: false }) readonly unimportant!: boolean
  @Prop({ type: Boolean, default: false }) readonly hideSign!: boolean
  @Prop({ required: false, default: () => currencyFilter })
  readonly formatter!: (_: number) => string

  get formattedValue(): string {
    return this.formatter(this.hideSign ? Math.abs(this.value) : this.value)
  }

  get autoFormatting(): string {
    if (this.unimportant) {
      return this.unimportantFormatting
    } else if (this.positive) {
      return this.positiveFormatting
    } else if (this.negative) {
      return this.negativeFormatting
    } else if (this.auto && this.value > 0) {
      return this.positiveFormatting
    } else if (this.auto && this.value < 0) {
      return this.negativeFormatting
    }
    return this.normalFormatting
  }

  get positiveFormatting(): string {
    return `font-${this.size} text-green`
  }

  get negativeFormatting(): string {
    return `font-${this.size} text-rust text-decoration-line-through`
  }

  get unimportantFormatting(): string {
    return `font-${this.size} text-gray-text-dark`
  }

  get normalFormatting(): string {
    return `font-${this.size}`
  }
}
