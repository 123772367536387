
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { pricingSelectionIdToRateTypes, rateTypes } from '@/utils/pricing'
import { Type } from '@/models/dto'
import CUCurrency from '@/components/CUCurrency.vue'
import { currencyFilter } from '@/utils/string'

@Component({
  components: {
    CUCurrency,
  },
})
export default class ReservationPostTripChargeQuoted extends Vue {
  @Prop({ required: true }) readonly dailyRate: number
  @Prop({ required: true }) readonly mileageRate: number
  @Prop({ required: true }) readonly hourlyRate: number
  @Prop({ required: true }) readonly quotedBaseFare: number
  @Prop({ required: false, default: null })
  readonly defaultPricingSelectionId: number

  @Watch('dailyRate')
  @Watch('mileageRate')
  @Watch('hourlyRate')
  onRatesUpdate(): void {
    // When our rates update, if any of the rate types are selected, recalculate the baseFareAdjustmentValue
    this.baseFareAdjustmentValue = this.baseFareDifference
  }

  rateTypes = rateTypes
  selectedRateTypeKeys: string[] = []
  baseFareAdjustmentValue = 0

  get chargeButtonLabel(): string {
    return this.baseFareAdjustmentValue < 0 ? 'Reduce' : 'Add'
  }

  get quotedBaseFareDisplay(): string {
    return currencyFilter(this.quotedBaseFare)
  }

  get selectedBaseFare(): number {
    return this.selectedRateTypeKeys.reduce((acc, key) => {
      return acc + this.getRateByTypeKey(key)
    }, 0)
  }

  get actualBaseFareLabel(): string {
    return currencyFilter(this.selectedBaseFare)
  }

  get baseFareDifference(): number {
    return parseFloat((this.selectedBaseFare - this.quotedBaseFare).toFixed(2))
  }

  get baseFareAdjustmentDisplay(): string {
    return currencyFilter(Math.abs(this.baseFareAdjustmentValue))
  }

  get displayDifferenceAsNegative(): boolean {
    return this.baseFareDifference < 0
  }

  get displayDifferenceAsPositive(): boolean {
    return this.baseFareDifference > 0
  }

  get displayDifferenceAsZero(): boolean {
    return this.baseFareDifference === 0
  }

  // Handlers
  handleRateToggled(type: Type): void {
    if (this.isRateTypeSelected(type)) {
      this.selectedRateTypeKeys = this.selectedRateTypeKeys.filter(
        (key) => key !== type.key
      )
    } else {
      this.selectedRateTypeKeys = [...this.selectedRateTypeKeys, type.key]
    }
    this.baseFareAdjustmentValue = this.baseFareDifference
  }

  handleBaseFareAdjustmentValueUpdate(e: string): void {
    let baseFareAdjustmentValue = parseFloat(e.replace(/[^0-9.-]/g, ''))
    if (isNaN(baseFareAdjustmentValue)) {
      baseFareAdjustmentValue = 0
    }

    // If the input string contains a negative sign, set baseFareAdjustmentValue to the negative amount
    if (e.includes('-')) {
      this.baseFareAdjustmentValue = -Math.abs(baseFareAdjustmentValue)
    } else {
      // Otherwise, set it to the positive amount
      this.baseFareAdjustmentValue = Math.abs(baseFareAdjustmentValue)
    }

    this.selectedRateTypeKeys = []
  }

  // Helper methods
  isRateTypeSelected(type: Type): boolean {
    return this.selectedRateTypeKeys.includes(type.key)
  }

  getRateByTypeKey(key: string): number {
    if (key === 'daily') {
      return this.dailyRate
    }

    if (key === 'hourly') {
      return this.hourlyRate
    }

    if (key === 'mileage') {
      return this.mileageRate
    }
    return 0
  }

  mounted(): void {
    // Set the default selected rate types
    if (this.defaultPricingSelectionId) {
      const rateTypes = pricingSelectionIdToRateTypes(
        this.defaultPricingSelectionId
      )
      this.selectedRateTypeKeys = rateTypes.map((type) => type.key)
    }

    // Initialize the default base fare adjustment value to match the difference between fares
    this.baseFareAdjustmentValue = this.baseFareDifference
  }
}
