
import reservation from '@/store/modules/reservation'
import { Vue, Component } from 'vue-property-decorator'
import ReservationDriverPayStats from '@/components/ReservationDriverPayStats.vue'
import ReservationDriverPayDriverDetail from '@/components/ReservationDriverPayDriverDetail.vue'

@Component({
  components: {
    ReservationDriverPayStats,
    ReservationDriverPayDriverDetail,
  },
})
export default class ReservationDriverPay extends Vue {
  state = reservation

  driverData: any[] = []

  get driverAssignments(): any[] {
    return this.state.assignments?.flatMap((a) => a.driverAssignments) || []
  }

  mounted(): void {
    reservation.fetchInvoices()
  }
}
