import { apiBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { Refund } from '@/models/dto/Refund'
import { ApiResult } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<Refund>>> {
    const { sorts, filters, pageSize = 10, page = 1 } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }

    query = encodeURI(query)
    return httpService.get(`https://${apiBaseUrl()}/tables/refunds?${query}`)
  },
  void(id: number): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${apiBaseUrl()}/refund/void/${id}`
    return httpService.delete(url)
  },
}
