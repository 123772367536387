
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import PaymentSidebarHeader from '@/components/PaymentSidebarHeader.vue'
import sidebar from '@/store/modules/sidebar'
import payments from '@/services/payments'
import CUSidebarContent from '@/layouts/CUSidebarContent.vue'
import CUSidebarContentWithFooterButtons from '@/components/CUSidebarContentWithFooterButtons.vue'
import auth from '@/store/modules/auth'
import rstate from '@/store/modules/reservation'
import reservation from '@/services/reservation'
import { EventBus } from '@/utils/eventBus'
import { isNotEmptyInput } from '@/utils/validators'
import RecipientList from '@/components/RecipientList.vue'
import {
  ReservationAddChargeRequest,
  ReservationChargeRequest,
  ReservationReduceChargeRequest,
} from '@/models/dto/Reservation'
import customer from '@/services/customer'

@Component({
  components: {
    PaymentSidebarHeader,
    CUSidebarContent,
    CUSidebarContentWithFooterButtons,
    RecipientList,
  },
})
export default class ReservationChargesSidebar extends Vue {
  @Prop({ default: false, type: Boolean }) readonly reduceCharge!: boolean
  @Prop({ default: undefined, required: false })
  readonly prefilledAmount!: number
  @Prop({ default: undefined, required: false })
  readonly prefilledChargeType!: number

  loading = false
  chargeTypes = []
  contacts = []
  isNotEmptyInput = isNotEmptyInput
  formData = {
    chargeType: null,
    amount: 0,
    sendEmail: true,
    internalNotes: '',
    paymentNotes: '',
  }

  get confirmationEmails(): string[] {
    if (!this.formData.sendEmail) {
      return []
    }
    return this.contacts.map((u) => u.email)
  }

  cancel(): void {
    sidebar.pop()
  }

  async loadChargeTypes(): Promise<void> {
    const res = await payments.getChargeTypes()
    this.chargeTypes = res.data.sort((a, b) => a.label.localeCompare(b.label))
  }

  async loadContacts(): Promise<void> {
    const {
      customerEmail: email,
      customerFirstName: firstName,
      customerLastName: lastName,
      customerId: id,
    } = rstate.reservation
    const bookingContact = { email, firstName, lastName, id }
    const billingContactId = rstate.reservation.billingCustomerId

    this.contacts.push({ ...bookingContact, type: 'Booking' })
    if (billingContactId) {
      const { data: billingCustomer } = await customer.byId(billingContactId)
      this.contacts.push({ ...billingCustomer.customer, type: 'Billing' })
    }
  }

  async submit(): Promise<void> {
    if (!this.$refs['form']['validate']()) {
      return
    }

    this.loading = true

    const payload: ReservationChargeRequest = {
      chargeType: this.formData.chargeType,
      companyId: auth.getCompanyId,
      reservationId: rstate.reservation.reservationId,
      customerNotes: {
        note: this.formData.paymentNotes,
        html: this.formData.paymentNotes,
      },
      notes: {
        note: this.formData.internalNotes,
        html: this.formData.internalNotes,
      },
      sendEmail: this.formData.sendEmail,
      userId: auth.getUserId,
      confirmationEmails: this.confirmationEmails,
    }

    try {
      let res
      if (!this.reduceCharge) {
        const addChargePayload: ReservationAddChargeRequest = {
          ...payload,
          amount: String(this.formData.amount),
        }
        res = await reservation.addReservationCharge(addChargePayload)
      } else {
        const reduceChargePayload: ReservationReduceChargeRequest = {
          ...payload,
          reductionAmount: String(this.formData.amount),
        }
        res = await reservation.reduceReservationCharge(reduceChargePayload)
      }
      if (res.status === 200) {
        this.handleSubmitSuccess()
      }
    } catch (e: any) {
      console.error(e)
      EventBus.$emit('snackbar:error', e.response.data.message)
      this.loading = false
    }
  }

  handleSubmitSuccess(): void {
    this.loading = false
    sidebar.pop()
    EventBus.$emit(
      'snackbar:success',
      `Charge ${this.reduceCharge ? 'reduced' : 'added'} successfully!`
    )
    EventBus.$emit('refresh-reservation')
    this.$emit('charges-sidebar:submit-success')
  }

  @Watch('contacts')
  onContactsChange(): void {
    this.formData.sendEmail = !!this.contacts.length
  }

  async mounted(): Promise<void> {
    if (this.prefilledAmount) {
      this.formData.amount = this.prefilledAmount
    }
    if (this.prefilledChargeType) {
      this.formData.chargeType = this.prefilledChargeType
    }
    await Promise.all([this.loadChargeTypes(), this.loadContacts()])
  }
}
