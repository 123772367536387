import { Type } from '@/models/dto/Type'
import { TableViewResult } from '@/models/TableView'
import { Customer } from '@/models/dto/Customer'
import { CustomerAccount } from '@/models/dto/CustomerAccount'
import { TableViewEvent } from '@/models/dto/Event'
import { Industry } from '@/models/dto/Industry'
import { RateType } from '@/models/dto/Rate'
import { Vehicle, VehicleType } from '@/models/dto/Vehicle'
import { TripMarket } from '@/models/dto/Trip'
import { ZonedDate } from '@/models/dto/Date'

export interface MarkupType extends Type {
  id: never
  markupTypeId: number
}

export interface Markup {
  markupId: number
  active: boolean
  companyId: number
  companyMarkup: CompanyMarkup
  createdOn: string
  customerAccountId: number
  customerId: number
  endDate: string
  eventId: number
  industryId: number
  marketId: number
  marketRateTypeKey: string
  marketplace: boolean
  markupDays: MarkupDay[]
  markupPercentage: number
  markupType: MarkupType
  markupTypeId: number
  name: string
  startDate: string
  updatedOn: string
  vehicleTypeKey: string
  tripId: number
}

// eslint-disable-next-line no-redeclare
export class Markup implements Markup {
  constructor(markup?: Partial<Markup>) {
    this.active = true
    Object.assign(this, markup)
  }
}

export interface TripMarkup {
  id: number
  isActive: boolean
  tripId: number
  markup: Markup
}

export interface MarkupDay {
  markupDayId: number
  markupId: number
  day: number
}

export interface MarkupDetail {
  markupId: number
  markupTypeId: number
  name: string
  markupPercentage: number
  markupType: MarkupType
}

export interface CompanyMarkup {
  customerId: number
  companyId: number
  customer: Customer
  markups: Markup[]
  markupTypes: MarkupType[]
  datesAndZones: ZonedDate[]
  dates: string[]
  marketRateTypeKey: string
  firstTripDate: string
  lastTripDate: string
  tripVehicles: Vehicle[]
  ada: boolean
  spab: boolean
  mountain: boolean
  eligibleMarkups: MarkupDetail[]
  appliedMarkups: MarkupDetail[]
  percentage: number
  amount: number
}

export interface TableViewMarkup {
  markupId: number
  active: boolean
  companyId: number
  createdOn: string
  updatedOn: string
  customer: Customer
  customerAccount: CustomerAccount
  customerAccountId: number
  customerId: number
  startDate: string
  endDate: string
  event: TableViewEvent
  eventId: number
  industry: Industry
  industryId: number
  market?: TripMarket
  markupDays: MarkupDay[]
  markupPercentage: number
  markupType: MarkupType
  markupTypeId: number
  name: string
  rateType?: RateType
  vehicleType?: VehicleType
}

export type MarkupTableViewResult = TableViewResult<TableViewMarkup>
