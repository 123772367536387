
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Contact } from '@/models/Contact'
import RecipientList from '@/components/RecipientList.vue'
import { validateRules } from '@/utils/rules'
import { EventBus } from '@/utils/eventBus'

@Component({
  components: { RecipientList },
})
export default class SendDriverInfoSidebar extends Vue {
  @Prop({ required: false, default: () => [] }) readonly contacts!: Contact[]
  @Prop({ required: false, default: true })
  readonly driversFullyAssigned!: boolean
  recipients: Contact[] = []

  // POST LAUNCH
  // preview(): void {
  //   this.$emit('preview', auth.getUser)
  // }

  cancel(): void {
    this.$emit('cancel')
  }

  submit(): void {
    if (!this.driversFullyAssigned) {
      EventBus.$emit(
        'snackbar:error',
        'Drivers not fully assigned. Email not sent.'
      )
      return
    }

    validateRules(this).then((isValid) => {
      if (isValid) {
        this.$emit('notify', this.recipients)
      }
    })
  }

  mounted(): void {
    this.recipients = [...this.contacts]
  }
}
