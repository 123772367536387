
import { BillingOverrideVehicleView } from '@/models/BillingOverride'
import {
  formatMinutesToTimeLabel,
  getInclusiveDayDifferenceLabel,
  formatMilesToLabel,
} from '@/utils/posttrip'
import dayjs from 'dayjs'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class ReservationPostTripVehicleHeader extends Vue {
  @Prop({ required: true }) vehicle!: BillingOverrideVehicleView
  @Prop({ required: true }) index!: number
  @Prop({ required: false, default: null }) timeZone!: string

  get trackingLabel(): string {
    let dayLabel = null
    const totalMinutes = this.vehicle.trackedMinutes
    const totalMiles = this.vehicle.trackedMiles
    const startDatetime = this.vehicle.trackedStartDatetime
    const endDatetime = this.vehicle.trackedEndDatetime

    const timeLabel = totalMinutes ? formatMinutesToTimeLabel(totalMinutes) : ''

    if (startDatetime && endDatetime) {
      dayLabel = getInclusiveDayDifferenceLabel(
        startDatetime,
        endDatetime,
        this.timeZone
      )
    }

    const mileageLabel = totalMiles ? formatMilesToLabel(totalMiles) : ''

    const labels = [timeLabel, dayLabel, mileageLabel].filter(
      (label) => !!label
    )

    if (labels.length) {
      return labels.join(' | ')
    } else {
      return '--'
    }
  }

  get billableLabel(): string {
    let dayLabel = null
    let endDatetime = null

    const totalMinutes = this.vehicle.billableMinutes
    const totalMiles = this.vehicle.billableMiles
    const startDatetime = dayjs
      .utc(this.vehicle.startDatetime)
      .format('YYYY-MM-DDTHH:mm:ss')
    if (startDatetime) {
      endDatetime = dayjs(startDatetime)
        .add(totalMinutes, 'minutes')
        .format('YYYY-MM-DDTHH:mm:ss')
    }

    const timeLabel = totalMinutes ? formatMinutesToTimeLabel(totalMinutes) : ''

    if (startDatetime && endDatetime) {
      dayLabel = getInclusiveDayDifferenceLabel(
        startDatetime,
        endDatetime,
        this.timeZone
      )
    }

    const mileageLabel = totalMiles ? formatMilesToLabel(totalMiles) : ''

    return [timeLabel, dayLabel, mileageLabel]
      .filter((label) => !!label)
      .join(' | ')
  }

  get differenceLabel(): string {
    const totalMinutes = this.vehicle.billableMinuteDifference
    const totalMiles = this.vehicle.billableMileDifference

    if (!totalMinutes && !totalMiles) {
      return '--'
    }

    const timeLabel = totalMinutes
      ? formatMinutesToTimeLabel(totalMinutes, false)
      : ''

    const mileageLabel = totalMiles ? formatMilesToLabel(totalMiles, false) : ''
    return [timeLabel, mileageLabel].filter((label) => !!label).join(' | ')
  }
}
