
import { Component, Prop, Vue } from 'vue-property-decorator'
import CUCheckbox from '@/components/CUCheckbox.vue'
import { LineItemChargeTypes, LineItemSectionTypes } from '@/utils/enum'
import { Trip, TripEstimation } from '@/models/dto'
import CUCurrency from '@/components/CUCurrency.vue'
import { getTripBaseFare, getTripSubtotal } from '@/utils/trip'
import { validateRules, validationRules } from '@/utils/rules'
import { lineItemChargeAmount, lineItemChargeBaseAmount } from '@/utils/charge'
import {
  CompanyChargeOption,
  LineItemCharge,
  LineItemChargeType,
  LineItemSectionType,
} from '@/models/dto/LineItemCharge'
import tclient from '@/services/type'
import client from '@/services/companyChargeOption'
import sidebar from '@/store/modules/sidebar'

@Component({
  components: { CUCurrency, CUCheckbox },
})
export default class TripPricingChargeForm extends Vue {
  @Prop({ required: true }) readonly trip!: Trip
  @Prop({ required: true }) readonly tripEstimation!: TripEstimation
  @Prop({ required: false }) readonly section: LineItemSectionType
  @Prop({ required: false }) readonly lineItemCharge: LineItemCharge
  @Prop({ required: false }) readonly lineItemChargeIdx: number
  @Prop({ required: false }) readonly name: string
  @Prop({ type: Boolean, required: false }) readonly isEditTax: boolean
  @Prop({ type: Boolean, default: false }) readonly persist!: boolean
  @Prop({ type: Boolean, default: false })
  readonly hideDefaultSelector!: boolean
  validation = validationRules

  isDefault = false
  isSubmitting = false
  form: LineItemCharge = new LineItemCharge()

  lineItemCharges: LineItemCharge[] = []
  lineItemChargeTypes: LineItemChargeType[] = []
  lineItemSectionTypes: LineItemSectionType[] = []

  isChargeType(type: LineItemChargeTypes): boolean {
    return this.form?.lineItemChargeType?.key === type
  }

  isSectionType(type: LineItemSectionTypes): boolean {
    return this.form?.lineItemSectionType?.key === type
  }

  get isTax(): boolean {
    return this.name === 'Tax'
  }

  get rate(): number {
    if (this.isChargeType(LineItemChargeTypes.PERCENTAGE)) {
      return this.form?.percentage || null
    }
    return this.form?.rate || null
  }

  get rateInputSign(): string {
    if (!this.rate || !this.form?.lineItemChargeType) {
      return ''
    }
    return this.isChargeType(LineItemChargeTypes.PERCENTAGE) ? '%' : '$'
  }

  get isVariableQuantity(): boolean {
    const isPercentage = this.isChargeType(LineItemChargeTypes.PERCENTAGE)
    const isFlatRate = this.isChargeType(LineItemChargeTypes.FLAT_RATE)
    return !isPercentage && !isFlatRate && !!this.form?.lineItemChargeType
  }

  get percentageBase(): number {
    const isSubtotal = this.isSectionType(LineItemSectionTypes.SUBTOTAL)
    return isSubtotal ? getTripSubtotal(this.trip) : getTripBaseFare(this.trip)
  }

  get quantity(): number {
    switch (this.form?.lineItemChargeType?.key) {
      case LineItemChargeTypes.FLAT_RATE:
        return 1
      case LineItemChargeTypes.PERCENTAGE:
        return 1
      case LineItemChargeTypes.PER_QUANTITY:
        return 1
      default:
        return 0
    }
  }

  get chargeAmount(): number {
    return lineItemChargeAmount(
      this.form,
      lineItemChargeBaseAmount(this.form, this.trip)
    )
  }

  get companyChargeOption(): CompanyChargeOption {
    const type = this.form?.lineItemChargeType?.key
    return new CompanyChargeOption({
      isDefault: this.isDefault,
      name: this.form?.name,
      lineItemChargeTypeId: this.form?.lineItemChargeTypeId,
      lineItemSectionTypeId: this.form?.lineItemSectionTypeId,
      percentage: this.form?.percentage,
      rate: this.form?.rate,
      isPerDriver: type === LineItemChargeTypes.PER_DRIVER,
      isPerPassenger: type === LineItemChargeTypes.PER_PASSENGER,
      isPerVehicle: type === LineItemChargeTypes.PER_VEHICLE,
    })
  }

  handleLineItemChargeTypeChange(lineItemChargeType: LineItemChargeType): void {
    const lineItemChargeTypeId = lineItemChargeType?.id
    this.form = { ...this.form, lineItemChargeType, lineItemChargeTypeId }

    if (
      this.isChargeType(LineItemChargeTypes.FLAT_RATE) ||
      this.isChargeType(LineItemChargeTypes.PERCENTAGE)
    ) {
      this.form = { ...this.form, quantity: 1 }
    } else {
      this.form = { ...this.form, quantity: this.quantity }
    }

    if (this.isChargeType(LineItemChargeTypes.PERCENTAGE) && this.form.rate) {
      this.form = {
        ...this.form,
        percentage: this.form.rate,
        rate: 0,
      }
    } else if (this.form.percentage) {
      this.form = {
        ...this.form,
        rate: this.form.percentage,
        percentage: 0,
      }
    }
  }

  handleLineItemSectionTypeChange(
    lineItemSectionType: LineItemSectionType
  ): void {
    const lineItemSectionTypeId = lineItemSectionType?.id
    this.form = { ...this.form, lineItemSectionType, lineItemSectionTypeId }
  }

  handleRateChange(rate: number): void {
    if (this.isChargeType(LineItemChargeTypes.PERCENTAGE)) {
      this.form = {
        ...this.form,
        percentage: rate,
        rate: 0,
      }
    } else {
      this.form = {
        ...this.form,
        percentage: 0,
        rate,
      }
    }
  }

  submitCompanyChargeOption(): Promise<CompanyChargeOption> {
    if (!this.persist) {
      return Promise.resolve(null)
    }
    this.isSubmitting = true
    return client
      .create(this.companyChargeOption)
      .then((res) => res.data.data)
      .finally(() => (this.isSubmitting = false))
  }

  submitForm(): void {
    if (!this.lineItemCharge) {
      this.$emit('submit', this.form)
    } else {
      this.$emit('update', this.form, this.lineItemChargeIdx)
    }
  }

  async submit(): Promise<void> {
    try {
      const isValid = await validateRules(this)
      if (!isValid) {
        return
      }
      await this.submitCompanyChargeOption()
    } catch (err) {
      return
    } finally {
      this.submitForm()
    }

    sidebar.close()
  }

  cancel(): void {
    this.$emit('cancel')
  }

  mounted(): void {
    if (this.lineItemCharge) {
      this.form = { ...this.lineItemCharge }
    } else {
      this.form = new LineItemCharge()
    }

    if (this.section) {
      this.form = {
        ...this.form,
        lineItemSectionType: this.section,
        lineItemSectionTypeId: this.section?.id,
      }
    }

    tclient
      .lineItemChargeTypes()
      .then((res) => res.data)
      .then(
        (types) =>
          (this.lineItemChargeTypes = types.filter(
            (type) =>
              ![
                'per_passenger',
                'per_driver',
                'per_vehicle',
                'per_total_mile',
                'per_live_mile',
                'per_dead_mile',
                'per_hour',
                'per_day',
              ].includes(type.key)
          ))
      )
      .then((chargeTypes) => {
        if (this.isTax) {
          const percentageType = chargeTypes.find((type) => {
            return type.key === 'percentage'
          })
          this.handleLineItemChargeTypeChange(percentageType)
        }
      })

    tclient
      .lineItemSectionTypes()
      .then((res) => res.data)
      .then(
        (types) =>
          (this.lineItemSectionTypes = types.filter(
            (type) => !['subtotal'].includes(type.key) || this.isEditTax
          ))
      )
  }
}
