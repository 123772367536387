import { Markup, TripMarkup } from '@/models/dto/Markup'
import { Trip } from '@/models/dto'
import { MarkupTypes } from '@/utils/enum'
import dayjs from 'dayjs'
import { isInDateRange } from '@/utils/date'
import client from '@/services/markup'
import { getTripTotal } from '@/utils/trip'

export const isApplicable = (markup: Markup, trip: Trip): boolean => {
  if (!markup || !markup?.active || !trip) {
    return false
  }
  if (
    markup?.markupDays?.length &&
    !markup.markupDays.map((md) => md.day).includes(dayjs().day())
  ) {
    return false
  }
  switch (markup?.markupType?.key) {
    case MarkupTypes.CUSTOMER:
      return (
        !!markup?.customerId &&
        !!trip?.customerId &&
        markup.customerId === trip.customerId
      )
    case MarkupTypes.CUSTOMER_ACCOUNT:
      return (
        !!markup?.customerAccountId &&
        !!trip?.customer?.customerAccountId &&
        markup.customerAccountId === trip.customer.customerAccountId
      )
    case MarkupTypes.INDUSTRY: // TODO: Add Industry Support to Trips
      return !!markup?.industryId && markup.industryId > 0
    case MarkupTypes.DATE_RANGE:
      return (
        !!markup?.startDate &&
        !!markup?.endDate &&
        isInDateRange(markup.startDate, markup.endDate)
      )
    case MarkupTypes.DAYS:
      return (markup?.markupDays || [])
        .map((md) => md.day)
        .includes(dayjs().day())
    case MarkupTypes.EVENT: // TODO: Awaiting Event Specs
      return !!markup?.eventId && markup.eventId > 0
    case MarkupTypes.VEHICLE_TYPE:
      return (trip?.vehicles || [])
        .map((v) => v.vehicleTypeKey)
        .includes(markup?.vehicleTypeKey)
    default:
      return false
  }
}

export const toTripMarkup = (markup: Markup): TripMarkup => {
  return { id: null, isActive: true, tripId: null, markup }
}

export const markupAmount = (markup: Markup, baseAmount = 0): number => {
  // TODO: Include vehicle rates, if applicable.
  const percentage = markup.markupPercentage || 0
  return (baseAmount * percentage) / 100
}
