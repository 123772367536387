
import { Prop } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import CUCollectionTable from '@/components/CUCollectionTable.vue'
import { TableAction } from '@/models/TableAction'
import TicketDetail from '@/components/TicketDetail.vue'
import { DataTableColumn } from '@/models/DataTableColumn'
import { EventBus } from '@/utils/eventBus'
import sidebar from '@/store/modules/sidebar'
import client from '@/services/ticket'
import { Ticket, TicketDetailEntity } from '@/models/dto/Ticket'
import CUDataTableLink from './CUDataTableLink.vue'
import UserSidebarDetail from './UserSidebarDetail.vue'
import Placeholder from '@/views/Placeholder.vue'
import { AxiosResponse } from 'axios'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import auth from '@/store/modules/auth'

@Component({
  components: {
    TicketDetail,
    CUCollectionTable,
  },
})
export default class TicketsList extends mixins(DateMixin) {
  @Prop({ type: Boolean, default: false }) readonly hideHeaderActions!: boolean
  @Prop({ type: Boolean, default: false }) readonly hideFilters!: boolean
  @Prop({ required: false }) readonly params: TableViewParameters

  get tableView(): (
    _: TableViewParameters
  ) => Promise<AxiosResponse<TableViewResult<Ticket>>> {
    return (params: TableViewParameters) =>
      client.tableView(this.params || params)
  }

  refresh(): void {
    sidebar.pop()
    EventBus.$emit('refresh-tableview')
  }

  error(): void {
    // TODO: Provide user feedback (e.g. toaster) once designs are finalized.
  }

  openTicketDetail(row: TicketDetailEntity): void {
    sidebar.popAllAndPush({
      component: TicketDetail,
      props: {
        activeTicketDetailId: row.ticketId,
      },
      title: `Ticket ${row.ticketId}`,
    })
  }

  // Add Ticket //
  handleAddTicket(): void {
    sidebar.push({
      component: TicketDetail,
      props: {
        activeTicketDetailId: null,
      },
      title: 'Create Ticket',
    })
  }

  handleUpdateCount(count: number): void {
    this.count = count
    this.$emit('update:count', count)
  }

  // Table //
  count = 0
  search = ''
  dropdown = ['Placeholder_01', 'Placeholder_02']

  actions: TableAction[] = [
    {
      displayText: 'Set Status',
      key: 'setStatus',
      icon: 'edit',
    },
    {
      displayText: 'Set Priority',
      key: 'setPriority',
      icon: 'edit',
    },
    {
      displayText: 'Set Ticket Owner',
      key: 'setTicketOwner',
      icon: 'edit',
    },
    {
      displayText: 'Delete',
      key: 'delete',
      icon: 'delete',
    },
  ]

  get columns(): DataTableColumn[] {
    return [
      {
        _t_id: 'd25b2128-4e89-4876-8f52-2f41ec054e8c',
        text: 'Ticket ID',
        value: 'ticketId',
        filterable: true,
        filterBySearch: !this.hideFilters,
        filterPropertyType: 'number',
        filterProp: 'ticketId',
        filterType: 'contains',
        sortProp: 'ticketId',
        type: 'slot',
        clickEvent: 'tickets:open-ticket-detail',
        width: 120,
        columnSlotComponent: CUDataTableLink,
        hidden: false,
      },
      {
        _t_id: '74697ab0-19de-495f-8ee3-10d5b128f4f9',
        text: 'Title',
        value: 'title',
        filterable: true,
        filterBySearch: !this.hideFilters,
        filterProp: 'title',
        filterType: 'contains',
        sortProp: 'title',
        type: 'text',
        sortable: false,
        width: 120,
      },
      {
        _t_id: '49ab2065-222c-4fd0-8462-f91f2af97cdf',
        text: 'Status',
        value: 'status',
        filterable: true,
        filterProp: 'status',
        filterType: 'contains',
        type: 'text',
        width: 100,
      },
      {
        _t_id: '6bc7cf70-4c2b-46d7-b70a-8717b0f71234',
        text: 'Type',
        value: 'ticketType',
        filterable: true,
        sortable: true,
        filterProp: 'ticketType',
        filterType: 'contains',
        sortProp: 'ticketType',
        type: 'text',
        width: 120,
      },
      {
        _t_id: '7dccc9ac-1ea1-4335-a641-9447111f3c1a',
        text: 'Priority',
        value: 'severity',
        filterable: true,
        filterProp: 'status',
        filterType: 'contains',
        sortable: false,
        type: 'text',
        width: 120,
      },
      {
        _t_id: '148250b2-c359-4507-a4d6-3ff88786b909',
        text: 'Res ID',
        value: 'managedId',
        filterable: true,
        sortable: true,
        filterProp: 'managedId',
        filterType: 'contains',
        sortProp: 'reservation/managedId',
        type: 'text',
        width: 120,
      },
      {
        _t_id: '0268b283-6a03-44a0-a5a2-81b4aac376ad',
        text: 'Pickup Date',
        value: 'reservationPickupDate',
        filterable: true,
        sortable: true,
        filterProp: 'reservationPickupDate',
        filterType: 'contains',
        type: 'text',
        sortProp: 'reservationPickupDate',
        computedText: (row): string =>
          this.formatMediumDate(row.reservationPickupDate, {
            tz: row.reservationPickupTimeZone,
          }),
        width: 190,
      },
      {
        _t_id: '6f4b1f90-09e4-4d93-a8b7-910e824271c3',
        text: 'Created By',
        value: 'creatorFirstName',
        filterable: true,
        sortable: true,
        filterBySearch: !this.hideFilters,
        filterAsIs: false,
        filterProp: ['creatorFirstName', 'creatorLastName'],
        computedText: (row): string =>
          `${row.creatorFirstName || ''} ${row.creatorLastName || ''}`,
        filterType: 'contains',
        type: 'text',
        sortProp: 'creatorFirstName',
        width: 160,
      },
      {
        _t_id: '4eb9cdb1-887f-45eb-9ccd-410f972b95a1',
        text: 'Assignee',
        value: 'assigneeFirstName',
        filterable: true,
        sortable: true,
        filterProp: 'assigneeFirstName',
        filterType: 'contains',
        type: 'text',
        sortProp: 'assigneeFirstName',
        width: 160,
      },
      {
        _t_id: '508f02cf-6441-4247-a4d9-bc9010faa17b',
        text: 'Created On',
        value: 'createdAt',
        filterable: true,
        sortable: true,
        filterProp: 'createdAt',
        filterType: 'contains',
        type: 'text',
        sortProp: 'createdAt',
        computedText: (row): string =>
          this.formatMediumDate(row.createdAt, { tz: auth.getUserTimeZone }),
        width: 160,
      },
    ]
  }

  mounted(): void {
    EventBus.$on('tickets:refresh', this.refresh)
    EventBus.$on('tickets:error', this.error)
    EventBus.$on('tickets:open-ticket-detail', this.openTicketDetail)
  }

  beforeDestroy(): void {
    EventBus.$off('tickets:refresh', this.refresh)
    EventBus.$off('tickets:error', this.error)
    EventBus.$off('tickets:open-ticket-detail', this.openTicketDetail)
  }
}
